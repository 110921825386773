import {
	CircularProgress,
	Icon,
	IconButton,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
	tableContainer: {
		overflowX: 'auto',
	},
	backdrop: {
		position: 'relative',
		display: 'flex',
		'& .backdropContainer': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			background: 'rgba(0,0,0,0.1)',
			width: '100%',
			height: '100%',
			zIndex: '999',
		},
	},
}));

const PaginationAction = ({ onChangePage, page, lastPage }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				marginLeft: 20,
			}}
		>
			<IconButton color="default" onClick={() => onChangePage(1)} disabled={1 === page || false} size="small">
				<Icon>skip_previous</Icon>
			</IconButton>
			<IconButton color="default" onClick={() => onChangePage(page - 1)} disabled={1 === page || false} size="small">
				<Icon>keyboard_arrow_left</Icon>
			</IconButton>
			<IconButton
				color="default"
				size="small"
				disabled={lastPage === page || false}
				onClick={() => onChangePage(page + 1)}
			>
				<Icon>keyboard_arrow_right</Icon>
			</IconButton>
			<IconButton
				color="default"
				size="small"
				disabled={lastPage === page || false}
				onClick={() => onChangePage(lastPage)}
			>
				<Icon>skip_next</Icon>
			</IconButton>
		</div>
	);
};

function TableData({ meta, columns, loading, onHandleChangePage, onHandleChangeRowsPerPage, children }) {
	const classes = useStyles();
	const { to, current_page, total, per_page, from, last_page } = meta;
	const handlChangePage = newPage => {
		onHandleChangePage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		onHandleChangeRowsPerPage(event.target.value);
	};

	return (
		<div className={classes.backdrop}>
			{loading && (
				<div className="backdropContainer">
					<CircularProgress size={25} color="inherit" />
				</div>
			)}
			<Paper
				style={{
					width: '100%',
				}}
			>
				<div className={classes.tableContainer}>
					<Table stickyHeader size="small" aria-label="sticky header">
						<TableHead>
							<TableRow>
								{columns.map(column => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{
											minWidth: column.minWidth,
										}}
									>
										{column.label}
									</TableCell>
								))}
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{children}</TableBody>
					</Table>
				</div>
				<TablePagination
					ActionsComponent={subProps => <PaginationAction {...subProps} lastPage={last_page} />}
					rowsPerPageOptions={[10, 25, 50, 100]}
					component="div"
					count={total || 0}
					rowsPerPage={parseInt(per_page) || 10}
					page={current_page || 0}
					last_page={last_page}
					onChangePage={page => handlChangePage(page)}
					labelDisplayedRows={() => `Menampilkan ${from} - ${to} dari ${total}`}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}

export default TableData;
