import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetail } from '../../../../config/redux';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import FormEditUser from '../../../components/Forms/FormEditUser';

const Edit = ({ open, handleClose, id }) => {
	const updateBtnRef = React.createRef();
	const [onLoad, setOnLoad] = React.useState(true);
	const { user, fetchDetailLoading: userLoading, fetchUserEditLoading: loading } = useSelector(state => state.userData);
	const dispatch = useDispatch();

	useEffect(() => {
		if (onLoad) {
			setOnLoad(false);
			dispatch(fetchUserDetail(id));
		}
	}, [onLoad, setOnLoad, id, open, handleClose, id]);

	const handleUpdate = () => {
		updateBtnRef.current.click();
	};

	return (
		<Dialog fullWidth maxWidth="sm" open={open} scroll="body">
			<DialogTitle onClose={true} onHandleClose={handleClose}>
				Edit Data Pengguna
			</DialogTitle>
			<DialogContent>
				{userLoading ? (
					<CircularProgress
						style={{
							alignItems: 'center',
						}}
					/>
				) : (
					<FormEditUser updateBtnRef={updateBtnRef} userEdit={user} />
				)}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleUpdate}
					variant="contained"
					style={{
						width: 120,
					}}
					disabled={loading}
					color="primary"
				>
					{loading ? <CircularProgress size={25} color="secondary" /> : `Update`}
				</Button>
				<Button onClick={handleClose} variant="contained">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Edit;
