import {
    FETCH_USER_LOGOUT,
    FETCH_USER_LOGOUT_SUCCESS,
    FETCH_USER_LOGOUT_FAILURE,
    CLOSE_LOGOUT_SNACKBAR,
}  from './userLogoutType';

import { LogoutUser } from '../../services';
import history from '../../history';

const fetchUserLogout = () => {
    return {
        type: FETCH_USER_LOGOUT
    }
}

const fetchUserLogoutSuccess = (message) => {
    return {
        type: FETCH_USER_LOGOUT_SUCCESS,
        payload: message
    }
}

const fetchUserLogoutFailure = (error) => {
    return {
        type: FETCH_USER_LOGOUT_FAILURE,
        payload: error
    }
}

const closeLogoutSnackbarAction = () => {
    return {
        type: CLOSE_LOGOUT_SNACKBAR
    }
}

// const clearLoginUser = () => {
//     return {
//         type: CLEAR_LOGIN_USER
//     }
// }

export const attempUserLogout = () => {
    return dispatch => {
        dispatch(fetchUserLogout());
        LogoutUser().then( res => {
            const message = res.data.message;
            dispatch(fetchUserLogoutSuccess(message));
        }, err => {
            dispatch(fetchUserLogoutFailure(err.response.data));
        }).finally( () => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            // dispatch(clearLoginUser());
            history.push('/');
        })
    }
}

export const closeLogoutSnackbar = () => {
    return dispatch => {
        dispatch(closeLogoutSnackbarAction());
    }
}