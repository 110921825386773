import React, { Fragment } from 'react';
import TextField from '../../components/TextField';
import { fetchRoleLists, fetchUserDetail, fetchUpdateUser } from '../../../config/redux';
import { connect } from 'react-redux';
import Snackbar from '../../components/Snackbar';
import { withStyles, Grid, InputBase, Card, CardHeader, CardContent, Input, MenuItem } from '@material-ui/core';
import { compose } from 'redux';
import { fileType } from '../../../utils/Validation';
import ImageProcessing from '../Images/ImageProcessing';
import CropingImage from '../Images/CropingImage';
import theme from '../../../config/theme';

class FormEditUser extends React.Component {
    
    constructor(props) {
        super(props);
        this.imagePreviewCanvasRef = React.createRef()
        this.state = {
            userEdit: {},
            snackbar: {},
            profilePicture: null,
            cropingImageOpen: false,
            imagePreview: null,
            imgEdited: false,
            currentProfilePicture: null
        }
    }

    handleChange = (event) => {
        const newUser = {...this.state.userEdit};
        newUser[event.target.name] = event.target.value;
        this.setState({
            userEdit: newUser
        });
    }

    componentDidMount() {
        this.props.getRoleLists();
        this.setUserEdit();
    }

    setUserEdit = () => {
        const {user} = this.props;
        this.setState({
            userEdit: {
                id: user.id,
                username: user.username,
                email: user.email,
                role: user.role?.name,
                full_name: user.profile?.full_name,
                mobile: user.profile?.mobile,
                address: user.profile?.address,
                _method: 'put'
            },
            currentProfilePicture: user.profile?.profile_picture || null
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        const {userEdit} = this.state;
        Object.keys(userEdit).map( item => {
            return formData.append(item, userEdit[item]);
        });
        this.props.updateUser(formData).then( res => {
            this.setState({
                snackbar: {
                    open: true,
                    message: res.data.message,
                    variant: 'success'
                }
            });
        }, err => {
            this.setState({
                snackbar: {
                    open: true,
                    variant: 'error',
                    message: err.message
                }
            });
        });
    }

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {}
        });
    }

    selectProfilePicture = () => {
        document.getElementById('file_profile_picture').click()
    }

    fileChange = (event) => {
        if(event.target.files && event.target.files.length > 0) {
            const validateFile = fileType(event.target.value, ['.jpg', '.jpeg', '.png']);
            if(validateFile) {
                const file = event.target.files[0];
                const newUserEdit = {...this.state.userEdit};
                newUserEdit['profile_picture'] = event.target.files[0];
                this.setState({
                    profilePicture: file,
                    userEdit: newUserEdit,
                    cropingImageOpen: true,
                    imgEdited: true
                });
            }
            else {
                alert('file tidak valid');
            }
        }
    }

    closeCropingImage = (cropedFile) => {
        const newUserEdit = {...this.state.userEdit};
        newUserEdit['profile_picture'] = cropedFile;
        this.setState({
            cropingImageOpen: false,
            profilePicture: cropedFile,
            userEdit: newUserEdit
        });
    }

    render () {

        const { error, classes, updateBtnRef, roleLists } = this.props;
        const { userEdit, snackbar, profilePicture, cropingImageOpen, imgEdited, currentProfilePicture } = this.state;
        const imgProfile = profilePicture ? URL.createObjectURL(profilePicture) : null;
        const imgProfileUrl = imgEdited ? imgProfile : currentProfilePicture;
        
        return (
            <Fragment>
                <Snackbar 
                    open={ snackbar?.open }
                    onClose={this.handleCloseSnackbar}
                    message={snackbar?.message || ''}
                    variant={snackbar.variant || 'info'}
                />
                <form onSubmit={this.handleSubmit} method="post" encType='multipart/form-data'>
                    <Grid 
                        justify="center"
                        container
                    >
                        <Grid item
                            style={{
                                padding: theme.spacing(2, 0, 2, 0)
                            }}
                        >
                            <ImageProcessing 
                                onClick={ this.selectProfilePicture }
                                src={imgProfileUrl}
                            />
                            <CropingImage 
                                open={cropingImageOpen}
                                src={imgProfileUrl}
                                file={profilePicture}
                                onClose={ (croped) => this.closeCropingImage(croped)}
                                canvasRef={this.imagePreviewCanvasRef}
                            />
                        </Grid>
                    </Grid>
                    
                        <Card>
                            <CardHeader
                                title="Info Akun"
                                classes={{
                                    root: classes.cardHeader
                                }}
                                titleTypographyProps={{
                                    variant: 'h6',
                                    style: {
                                        color: '#444'
                                    }
                                }}
                            />
                            <CardContent
                                classes={{
                                    root: classes.cardContent
                                }}
                            >
                                <Grid 
                                    container
                                    direction="column"
                                >
                                    <Grid item>
                                        <InputBase 
                                        multiple={false}
                                        name="profile_picture"
                                        onChange={this.fileChange}
                                        type="file"
                                        id="file_profile_picture"
                                        style={{ display: 'none' }}
                                    />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            name="username"
                                            type="text"
                                            value={userEdit.username ?? ''}
                                            error={ error.errors?.username }
                                            helperText={ error.errors?.username }
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            type="email"
                                            value={userEdit.email ?? ''}
                                            error={ error.errors?.email }
                                            helperText={ error.errors?.email }
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Role"
                                            value={userEdit.role ?? ''}
                                            name="role"
                                            onChange={this.handleChange}
                                        >
                                            { roleLists.map( role => (
                                                <MenuItem key={role.value} value={role.value}>
                                                    {role.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        
                        <Card
                            style={{
                                marginTop: theme.spacing(2)
                            }}
                        >
                            <CardHeader 
                                title="Personal Info"
                                classes={{
                                    root: classes.cardHeader
                                }}
                                titleTypographyProps={{
                                    variant: 'h6',
                                    style: {
                                        color: '#444'
                                    }
                                }}
                            />
                            <CardContent
                                classes={{
                                    root: classes.cardContent
                                }}
                            >
                                <Grid 
                                    container
                                    className={classes.formSection}
                                    direction="column"
                                >
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Nama Lengkap"
                                            type="text"
                                            name="full_name"
                                            error={ error.errors?.full_name }
                                            helperText={ error.errors?.full_name }
                                            value={userEdit.full_name ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="No Handphone"
                                            type="text"
                                            name="mobile"
                                            error={ error.errors?.mobile }
                                            helperText={ error.errors?.mobile }
                                            value={userEdit.mobile ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Alamat"
                                            multiline={true}
                                            rows={2}
                                            type="text"
                                            name="address"
                                            value={userEdit.address ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Input
                            type="submit"
                            style={{
                                display: 'none'
                            }}
                            onClick={this.handleSubmit}
                            ref={updateBtnRef}
                        />

                </form>
            </Fragment>
        );
    }

};

const mapStateToProps = state => ({
    fetchDetailLoading: state.userData.fetchDetailLoading,
    user: state.userData.user,
    fetchUserEditLoading: state.userData.fetchUserEditLoading,
    error: state.userData.userEditError,
    roleLists: state.roleLists.roleLists,
});
const mapDispatchToProps = dispatch => ({
    getRoleLists: () => dispatch(fetchRoleLists()),
    fetchUser: (id) => dispatch(fetchUserDetail(id)),
    updateUser: (userInfo) => dispatch(fetchUpdateUser(userInfo))
});

const styles = () => ({
    ...theme.styles.cardForm,
    formTitle: {
        backgroundColor: '#ddd',
        padding: theme.spacing(1),
        fontWeight: 'normal',
        fontSize: 14
    }
})


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(FormEditUser);
