import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { createRef } from 'react';
import { useSelector } from 'react-redux';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import FormCreateUser from '../../../components/Forms/FormCreateUser';

function Create({ open, handleClose }) {
	const { loading } = useSelector(state => state.userData);
	const registerBtnRef = createRef();

	const handleRegister = () => {
		registerBtnRef.current.click();
	};

	return (
		<Dialog fullWidth maxWidth="sm" open={open} scroll="body">
			<DialogTitle onClose={true} onHandleClose={handleClose}>
				Buat Pengguna Baru
			</DialogTitle>
			<DialogContent>
				<FormCreateUser registerBtnRef={registerBtnRef} />
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleRegister}
					variant="contained"
					style={{
						width: 120,
					}}
					disabled={loading}
					color="primary"
				>
					{loading ? <CircularProgress size={25} color="secondary" /> : `Create`}
				</Button>
				<Button onClick={handleClose} variant="contained">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default Create;
