import { FETCH_ROLE_LISTS, FETCH_ROLE_LISTS_SUCCESS, FETCH_ROLE_LISTS_FAILURE } from "./roleType"

const initialState = {
    loading: false,
    roleLists: [],
    error: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_ROLE_LISTS:
            return {
                ...state,
                loading: true,
                error: false
            }
        case FETCH_ROLE_LISTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                roleLists: action.payload
            }
        case FETCH_ROLE_LISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                roleLists: {}
            }
        default: return state;
    }
}

export default reducer;