import React from 'react';
import { CardHeader, makeStyles, Card, CardContent } from '@material-ui/core';

const styles = makeStyles( theme => ({
    root: {
        background: theme.palette.primary.main
    },
    rootContent: {
        background: theme.palette.primary.light
    },
    rootFooter: {
        background: "transparent",
        marginTop: 20,
    }
}));

export const AuthHeader = (props) => {
    const classes = styles();
    return (
        <CardHeader
            title={props.title}
            titleTypographyProps={{
                style: {
                    color: 'white'
                }
            }}
            className={classes.root}
        />
    );
};

export const AuthContent = (props) => {
    const classes = styles();
    return (
        <CardContent
            className={classes.rootContent}
        >
            { props.children }
        </CardContent>
    )
}

export const AuthFooter = (props) => {
    const classes = styles();
    return (
        <Card
            className={classes.rootFooter}
            elevation={0}
        >
            <CardContent
                style={{
                    padding: 0
                }}
            >
                {props.children}
            </CardContent>
        </Card>
    )
}