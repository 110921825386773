import React, { Component } from 'react';
import { Typography, Container, Grid, Card, CardContent } from '@material-ui/core';

class Public extends Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default Public;
