import {
    PAGE_LOADING
} from './pageLoadingType';

const pageLoading = (value) => {
    return {
        type: PAGE_LOADING,
        payload: value
    }
}

export const setPageLoading = (value) => {
    return dispatch => {
        dispatch(pageLoading(value));
    }
}