import React, { Component } from 'react';
import { 
    Typography, Card } from '@material-ui/core';
import { AuthFooter, AuthHeader } from '../../../components/Auth';
import { TextUri } from '../../../components/Uri';
import FormResetPassword from '../../../components/Forms/FormResetPassword';


class Registration extends Component {

    componentDidMount() {
        document.title = 'Reset Password';
    }

    render() {

        return (
            <div>
                
                <Card>
                    <AuthHeader
                        title="Form Reset Password"
                    />
                    <FormResetPassword />
                </Card>
                
                <AuthFooter>
                    <Typography variant="body1">
                        <TextUri
                            linkProps={{
                                to: '/'
                            }}
                            typographyProps={{
                                variant: 'body1'
                            }}
                            appendText="kehalaman "
                        >
                            login
                        </TextUri>
                    </Typography>
                </AuthFooter>
                
            </div>
        );
    }
}

export default Registration;