import React from 'react';
import { Route } from 'react-router-dom';
import Public from '../../templates/Public';

const index = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Public>
          <Component {...matchProps} />
        </Public>
      )}
    />
  );
};

export default index;
