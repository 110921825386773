import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Grid, Input, MenuItem, makeStyles } from '@material-ui/core';
import TextField from '../../../components/TextField';
import '../../../../config/app';
import MySnackbar from '../../../components/Snackbar';
import { fetchPosts } from '../../../../config/redux';
import { connect } from 'react-redux';
import SelectImage from '../../../components/Forms/SelectImage';
import MomentUtils from '@date-io/moment';
import { fileType } from '../../../../utils/Validation';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
// import {stateFromHTML} from 'draft-js-import-html';
import htmlToDraft from 'html-to-draftjs';
import { dateTimeInput } from '../../../../config/datetime';

const styles = makeStyles( () => ({
    toolbarBody: {
        marginBottom: 0,
        border: 'none',
        background: '#e8e8e8'
    },
    editorBody: {
        background: 'white',
        padding: '10px 20px',
        minHeight: 500
    }
}));

const FormCreate = (props) => {
    const classes = styles();
    const {error, onSubmit, onUpdate, registerBtnRef, lists, listsLoading} = props;
    const [post, setPost] = useState('');
    const [publishedData, setPublishedDate] = useState('');
    const [stateEditor, setStateEditor] = useState('');

    // Register Featured Image
    const featuredImageEdit = props.postEdit ? props.postEdit?.featured_image && props.postEdit.featured_image : '';
    const [featuredImage, setFeaturedImage] = useState( props.postEdit ? featuredImageEdit : '');
    const fImageCreate = featuredImage || global.no_image;

    const status = [
        {
            text: 'Draft',
            value: 'draft'
        },
        {
            text: 'Pending',
            value: 'pending'
        },
        {
            text: 'Publish',
            value: 'publish'
        }
    ];

    useEffect( () => {
        initPost();
    }, []);

    const initPost = () => {
        if(!props.postEdit) {
            setPost({
                published_at: new Date(), 
                status: 'draft'
            });
            setStateEditor('');
            setFeaturedImage('');
            setPublishedDate(new Date());
        }
        else {
            const post = {...props.postEdit};
            post['published_at'] = dateTimeInput(post.published_at);
            setPost(post);
            const blocksFromHtml = htmlToDraft(post.body);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setStateEditor(editorState);
            setPublishedDate(new Date(dateTimeInput(post.published_at)));
        }
    }

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        variant: 'info'
    });

    const handleDateChange = (event) => {
        setPublishedDate(event);
        const newPost = {...post};
        newPost['published_at'] = event.format('DD-MM-YYYY H:mm');
        setPost(newPost);
    }

    const handleSubmit = () => {
        let formData = new FormData();
        Object.keys(post).map( item => {
            return formData.append(item, post[item]);
        });
        onSubmit(formData).then(res => {
            initPost();
            props.fetchPosts();
            setSnackbar({
                open: true,
                message: res.data.message,
                variant: 'success'
            });
        }, err => {
            setSnackbar({
                open: true,
                message: err.message,
                variant: 'error'
            });
        })
    }

    const handleUpdate = () => {
        let formData = new FormData();
        formData.append('_method', 'put');
        Object.keys(post).map( item => {
            return formData.append(item, post[item]);
        });
        onUpdate(formData).then(res => {
            props.fetchPosts();
            setSnackbar({
                open: true,
                message: res.data.message,
                variant: 'success'
            });
        }, err => {
            setSnackbar({
                open: true,
                message: err.message,
                variant: 'error'
            });        
        })
    }

    const handleChange = (event) => {
        const newPost = {...post};
        newPost[event.target.name] = event.target.value;
        setPost(newPost);
    }

    const editorChange = (event) => {
        setStateEditor(event);
        const newPost = {...post};
        newPost['body'] = stateToHTML(event.getCurrentContent());
        setPost(newPost);
    }

    const handleCloseSnackbar = () => {
        setSnackbar({
            open: false,
            variant: 'info',
            message: ''
        });
    }

    const fileChange = (event) => {
        if(event.target.files && event.target.files.length > 0) {
            const validateFile = fileType(event.target.value, ['.jpg', '.jpeg', '.png']);
            if(validateFile) {
                const file = event.target.files[0];
                const newPost = {...post};
                newPost['featured_image'] = file;
                setPost(newPost);
                setFeaturedImage(URL.createObjectURL(file));
            }
            else {
                alert('file tidak valid');
            }
        }
    }

    return (
        <Fragment>
            <MySnackbar
                open={ snackbar?.open }
                onClose={handleCloseSnackbar}
                message={snackbar?.message || ''}
                variant={snackbar.variant || 'info'}
            />
            <form 
                onSubmit={ props.postEdit ? handleUpdate : handleSubmit } 
                method="post" 
                encType='multipart/form-data'
                style={{
                    paddingTop: 20
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <Grid 
                            container
                            direction="column"asd
                        >
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Judul Berita"
                                    name="title"
                                    type="text"
                                    value={post.title ?? ''}
                                    error={ error?.errors?.title }
                                    helperText={ error?.errors?.title }
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item>
                                <Editor
                                    editorState={stateEditor}
                                    name="body"
                                    toolbarClassName={classes.toolbarBody}
                                    wrapperClassName="wrapperClassName"
                                    editorClassName={classes.editorBody}
                                    onEditorStateChange={editorChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item cs={12} md={3}>
                        <Grid 
                            container
                            direction="column"    
                        >
                            <Grid item>
                                <TextField
                                    fullWidth
                                    label="Status"
                                    name="status"
                                    type="text"
                                    select
                                    value={post.status || 'draft'}
                                    error={ error?.errors?.status }
                                    helperText={ error?.errors?.status }
                                    onChange={handleChange}
                                >
                                    {
                                        status.map( stat => (
                                            <MenuItem key={stat.value} value={stat.value}>
                                                {stat.text}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        label="Tanggal Publish"
                                        style={{marginBottom: '10px'}}
                                        fullWidth
                                        format="DD / MMMM / YYYY H:m"
                                        value={publishedData}
                                        error={ error?.errors?.published_at }
                                        helperText={ error?.errors?.published_at }
                                        onChange={handleDateChange}
                                        animateYearScrolling
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <SelectImage
                                    src={fImageCreate}
                                    label="Featured Image"
                                    name="featured_image"
                                    onFileChange={fileChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Input
                    type="submit"
                    style={{
                        display: 'none'
                    }}
                    onClick={props.postEdit ? handleUpdate : handleSubmit}
                    ref={registerBtnRef}
                />
            </form>
        </Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    fetchPosts: () => dispatch(fetchPosts())
});

export default connect(
    null,
    mapDispatchToProps
)(FormCreate);