import React, { Component } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Icon, DialogTitle } from '@material-ui/core';
// import DialogTitle from '../../../components/molecules/Dialog/DialogTitle';
import Croppper from 'react-easy-crop';
import { base64StringtoFile, image64toCanvasRef } from '../../../utils/ImageUtils';

class CropingImage extends Component {

    state = {
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropCompletes = (croppedArea, croppedAreaPixels) => {
        const imgSrc = this.props.file ? URL.createObjectURL(this.props.file) : null;
        const canvasRef = this.props.canvasRef.current;
        image64toCanvasRef(canvasRef, imgSrc, croppedAreaPixels);
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    finishCorpingFile = () => {
        const canvasRef = this.props.canvasRef.current;
        const imgSrc = this.props.file;
        const imgData64 = canvasRef.toDataURL(imgSrc.type);
        const fileName = imgSrc.name;

        const resultCropedFile = base64StringtoFile(imgData64, fileName);
        
        this.props.onClose(resultCropedFile);
    }

    render() {
        const { open, src, canvasRef } = this.props;
        return (
            <Dialog
                open={open}
                fullWidth
            >
                <DialogTitle>Crop Image</DialogTitle>
                <DialogContent>
                    <div style={{
                        position: 'relative',
                        height: 250
                    }}>
                        <Croppper
                            image={ src }
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            onCropChange={ this.onCropChange }
                            onCropComplete={this.onCropCompletes}
                            onZoomChange={this.onZoomChange}
                            cropShape="round"
                            showGrid={false}
                        />
                    </div>
                    <canvas ref={ canvasRef }
                        style={{
                            display: 'none'
                        }}
                    ></canvas>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={ this.finishCorpingFile }>
                        <Icon>
                            check
                        </Icon>
                        Crop
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default CropingImage;