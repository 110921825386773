import { Dialog, DialogContent, List } from '@material-ui/core';
import React from 'react';
import DialogTitle from '../../../../components/Dialog/DialogTitle';
import ListItemCard from '../../../../components/Panel/ListItemCard';

function DialogDetail({ openDialogDetail, currentDetails, setOpenDialogDetail, setCurrentDetails }) {
	const handleClose = () => {
		setOpenDialogDetail(false);
		setCurrentDetails([]);
	};
	return (
		<Dialog
			open={openDialogDetail}
			fullWidth={true}
			maxWidth={'sm'}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle onClose={true} onHandleClose={handleClose}>
				Detail
			</DialogTitle>
			<DialogContent>
				<List>
					{currentDetails.map(detail => (
						<ListItemCard text={detail.facility} value={detail.total} />
					))}
				</List>
			</DialogContent>
		</Dialog>
	);
}

export default DialogDetail;
