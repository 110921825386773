import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Dashboard from '../../templates/Dashboard';

const index = ({ component: Component, ...rest}) => {
    return (
        <Route {...rest} render={ matchProps => 
            localStorage.getItem('token') ? (
                <Dashboard>
                    <Component {...matchProps} />
                </Dashboard>
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {
                            from: matchProps.location
                        }
                    }}
                />
            )
        } />
    );
};

export default index;