import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Switch,
  TableCell,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { utils, writeFileXLSX } from "xlsx";
import { dateDef } from "../../../../config/datetime";
import { setPageLoading, setPanelTitle } from "../../../../config/redux";
import { GetReviews, UpdateStatusReview } from "../../../../config/services";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import TableData from "../../../components/Panel/Table";

const useStyles = makeStyles((theme) => ({
  grow: {
    flex: 1,
  },
  cardRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  ...theme.styles.panelCard,
}));

const columns = [
  {
    id: "user_id",
    label: "User ID",
  },
  {
    id: "name",
    label: "Nama",
    minWidth: 200,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 200,
  },
  {
    id: "message",
    label: "Pesan",
    minWidth: 250,
    maxWidth: 600,
  },
  {
    id: "is_active",
    label: "Status",
    minWidth: 100,
  },
  {
    id: "created_at",
    label: "Dibuat",
    minWidth: 200,
  },
];

function Reviews() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const fetchReviews = async (params) => {
    setLoading(true);
    try {
      const response = await GetReviews(params);
      setData(response.data.reviews);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (value, id) => {
    setLoading(true);
    try {
      const response = await UpdateStatusReview({ is_active: value }, id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      fetchReviews({ q: search });
    }
  };

  const clearSearchInput = () => {
    if (search) {
      setSearch("");
      fetchReviews();
    }
  };

  const handleChangePage = (page) => {
    fetchReviews({ page: page, limit: data.meta.per_page });
  };

  const handleChangeRowsPerPage = (limit) => {
    fetchReviews({ limit: limit });
  };

  useEffect(() => {
    dispatch(setPanelTitle("Testimoni Pengguna"));
    fetchReviews();
  }, [dispatch]);

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetReviews({
        paginate: false,
      });
      const result = response.data.reviews.map((review) => ({
        ID: review.id,
        Nama: review.name,
        Email: review.email,
        Pesan: review.message,
        Status: review.is_active == 1 ? "Aktif" : "Mati",
        Dibuat: moment(review.created_at).format("HH:mm:ss - dddd, DD-MM-YYYY"),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-Review.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Testimoni Reviews</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item className={classes.grow}></Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={handleChangeSearch}
            onKeyPress={onSubmitSearch}
            clearSearchInput={clearSearchInput}
          />
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <CardHeader
          title="Data Tarif Toll"
          titleTypographyProps={{
            className: classes.cardTitle,
          }}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={loadingExport}
            >
              Export Data
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <TableData
            columns={columns}
            meta={{ ...data.meta, ...data.links }}
            loading={loading > 0}
            onHandleChangePage={(page) => handleChangePage(page)}
            onHandleChangeRowsPerPage={(limit) =>
              handleChangeRowsPerPage(limit)
            }
          >
            {data.data?.map((review) => (
              <TableRow key={review.id} hover>
                <TableCell>{review.user_id ?? "-"}</TableCell>
                <TableCell>{review.name}</TableCell>
                <TableCell>{review.email}</TableCell>
                <TableCell>{review.message}</TableCell>
                <TableCell>
                  <Switch
                    onChange={(e) =>
                      handleChangeStatus(e.target.checked, review.id)
                    }
                    defaultChecked={review.is_active}
                    color="primary"
                  />
                </TableCell>
                <TableCell>{dateDef(review.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableData>
        </CardContent>
      </Card>
    </div>
  );
}

export default Reviews;
