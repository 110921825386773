import {
    FETCH_USER_AUTHORIZATION,
    FETCH_USER_AUTHORIZATION_SUCCESS,
    FETCH_USER_AUTHORIZATION_FAILURE,
}  from './userAuthorizationType';

const initalState = {
    loading: false,
    status: 200,
}

const reducer = (state = initalState, action) => {
    switch(action.type) {
        case FETCH_USER_AUTHORIZATION:
            return {
                ...state,
                loading: true,
            }
        case FETCH_USER_AUTHORIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                status: 200
            }
        case FETCH_USER_AUTHORIZATION_FAILURE:
            return {
                ...state,
                loading: false,
                status: action.payload,
            }
        default: return state;
    }
}

export default reducer;