/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import {
  GetSegmentGatesList,
  StoreTrackData,
} from "../../../../config/services";
import DialogTitle from "../../../components/Dialog/DialogTitle";
import MySnackbar from "../../../components/Snackbar";
import TextField from "../../../components/TextField";

const initialState = {
  description: "",
  gate_in_id: "",
  gate_out_id: "",
  rates: [
    {
      name: "Golongan 1",
      price: 0,
    },
    {
      name: "Golongan 2",
      price: 0,
    },
    {
      name: "Golongan 3",
      price: 0,
    },
    {
      name: "Golongan 4",
      price: 0,
    },
    {
      name: "Golongan 5",
      price: 0,
    },
  ],
};

function Create({ createDialogOpen, setCreateDialogOpen, fetchTracks }) {
  const [loading, setLoading] = useState(false);
  const [loadingGates, setLoadingGates] = useState(false);
  const [search, setSearch] = useState("");
  const [gates, setGates] = useState([]);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({
    description: "",
    gate_in_id: "",
    gate_out_id: "",
    rates: [
      {
        name: "Golongan 1",
        price: 0,
      },
      {
        name: "Golongan 2",
        price: 0,
      },
      {
        name: "Golongan 3",
        price: 0,
      },
      {
        name: "Golongan 4",
        price: 0,
      },
      {
        name: "Golongan 5",
        price: 0,
      },
    ],
  });
  const [snackbar, setSnackbar] = useState({});

  const fetchGates = async () => {
    setLoadingGates(true);
    try {
      const response = await GetSegmentGatesList({ q: search });
      setGates(response.data);
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        variant: "error",
      });
    } finally {
      setLoadingGates(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await StoreTrackData(input);
      setSnackbar({
        open: true,
        message: response.data?.info ?? "Berhasil Membuat Jalur",
        variant: "success",
      });
      setInput({
        description: "",
        gate_in_id: "",
        gate_out_id: "",
        rates: [
          {
            name: "Golongan 1",
            price: 0,
          },
          {
            name: "Golongan 2",
            price: 0,
          },
          {
            name: "Golongan 3",
            price: 0,
          },
          {
            name: "Golongan 4",
            price: 0,
          },
          {
            name: "Golongan 5",
            price: 0,
          },
        ],
      });
      setErrors({});
      setCreateDialogOpen(false);
      fetchTracks();
    } catch (error) {
      console.log(error);
      setErrors(error.response.data);
      setSnackbar({
        open: true,
        message: error.response.data.message,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({});
  };

  useEffect(() => {
    fetchGates();
  }, [search]);

  const handleRateChange = (event, index) => {
    const newRate = { ...input };
    newRate["rates"][index][event.target.name] = event.target.value;
    setInput(newRate);
  };

  return (
    <>
      <MySnackbar
        open={snackbar?.open}
        onClose={handleCloseSnackbar}
        message={snackbar?.message || ""}
        variant={snackbar.variant || "info"}
      />
      <Dialog fullWidth maxWidth="sm" open={createDialogOpen} scroll="body">
        <DialogTitle>Buat Jalur Toll</DialogTitle>
        <DialogContent>
          <div
            style={{
              paddingTop: 20,
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <TextField
                  fullWidth
                  label="Deskripsi"
                  name="description"
                  type="text"
                  value={input.description ?? ""}
                  error={errors?.errors?.description}
                  helperText={errors?.errors?.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <FormControl error={errors?.errors?.gate_in_id} fullWidth>
                  <Autocomplete
                    fullWidth
                    loading={loadingGates}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option.name}
                    options={gates}
                    onChange={(e, newVal) => {
                      setInput((prevState) => ({
                        ...prevState,
                        gate_in_id: newVal.id,
                      }));
                      setSearch("");
                    }}
                    onInputChange={(event, newVal) => setSearch(newVal)}
                    name="gate_in_id"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gerbang Masuk"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors?.errors?.gate_in_id && (
                    <FormHelperText>
                      {errors?.errors?.gate_in_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl error={errors?.errors?.gate_out_id} fullWidth>
                  <Autocomplete
                    fullWidth
                    loading={loadingGates}
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => option.name}
                    options={gates}
                    onChange={(e, newVal) => {
                      setInput((prevState) => ({
                        ...prevState,
                        gate_out_id: newVal.id,
                      }));
                      setSearch("");
                    }}
                    onInputChange={(event, newVal) => setSearch(newVal)}
                    name="gate_out_id"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gerbang Keluar"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors?.errors?.gate_out_id && (
                    <FormHelperText>
                      {errors?.errors?.gate_out_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {/* Tarif Toll */}
            <Card
              style={{
                marginBottom: "2rem",
                marginTop: "2rem",
              }}
            >
              <CardHeader title="Tarif Toll" />
              <Divider />
              <CardContent>
                {input?.rates === undefined ? (
                  <Typography variant="body" component="p">
                    Data Tarif Kosong
                  </Typography>
                ) : (
                  input.rates.map((rate, index) => (
                    <Fragment key={`${rate}~${index}`}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              label="Tipe"
                              name="name"
                              type="text"
                              value={rate.name}
                              onChange={(event) =>
                                handleRateChange(event, index)
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Harga"
                              name="price"
                              type="text"
                              value={rate.price}
                              onChange={(event) =>
                                handleRateChange(event, index)
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="small"
                              color="primary"
                              onClick={() => {
                                const newRate = { ...input };
                                newRate.rates.splice(index, 1);
                                setInput(newRate);
                              }}
                              style={{
                                color: "red",
                                marginTop: 20,
                              }}
                            >
                              Hapus
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Fragment>
                  ))
                )}
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    const newRate = { ...input };
                    newRate.rates.push({});
                    setInput(newRate);
                  }}
                >
                  Tambah Tarif Toll
                </Button>
              </CardActions>
            </Card>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{
              width: 120,
            }}
            disabled={loading}
            color="primary"
            type="button"
          >
            {loading ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              `Create`
            )}
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Create;
