import {
    FETCH_USER_LOGOUT,
    FETCH_USER_LOGOUT_SUCCESS,
    FETCH_USER_LOGOUT_FAILURE,
    CLOSE_LOGOUT_SNACKBAR
}  from './userLogoutType';

const initalState = {
    loading: false,
    message: '',
    error: {},
    snackbar: false
}

const reducer = (state = initalState, action) => {
    switch(action.type) {
        case FETCH_USER_LOGOUT:
            return {
                ...state,
                loading: true,
                error: {},
                message: ''
            }
        case FETCH_USER_LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                message: action.payload,
                snackbar: true
            }
        case FETCH_USER_LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                message: ''
            }
        case CLOSE_LOGOUT_SNACKBAR:
            return {
                ...state,
                snackbar: false
            }
        default: return state;
    }
}

export default reducer;