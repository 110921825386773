import React, { Fragment, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TextField from '../../../components/TextField';
import { useState } from 'react';
import ChooseImage from '../../../components/Forms/ChooseImage';
import { StorePlaceRecomendationsData, UpdatePlaceRecomendationsData } from '../../../../config/services';
import MySnackbar from '../../../components/Snackbar';


const FormContent = (props) => {
    const {isEdit, submitRef, onLoading, onSubmitSuccess} = props;
    const [data, setData] = useState({});
    const [frmErr, setFrmErr] = useState([]);
    const [snackBar, setSnackBar] = useState({});

    useEffect(() => {
        initData();
    }, [])

    const initData = () => {
        console.log(props);
        if(isEdit) {
            setData({
                id: props.id,
                title: props.title,
                open: props.open,
                closed: props.closed,
                lat: props.lat,
                lng: props.lng,
                address: props.address
            })
        }
    }

    const handleChange = (event) => {
        const newData = {...data};
        if(event.target.name === 'image') {
            newData[event.target.name] = event.target.files[0];
        }
        else {
            newData[event.target.name] = event.target.value;
        }
        setData(newData);
    }

    const handleCloseSnackBar = () => {
        setSnackBar({
            open: false,
            variant: 'info',
            message: ''
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        Object.keys(data).map( item => {
            return formData.append(item, data[item]);
        });
        onLoading(true);
        if(!isEdit) {
            StorePlaceRecomendationsData(formData).then(res => {
                setSnackBar({
                    open: true,
                    variant: 'success',
                    message: res.data.message
                });
                setData({});
                document.getElementById('image-place-recomendation').src = global.no_image;
                onSubmitSuccess();
            }, err => {
                setSnackBar({
                    open: true,
                    variant: 'error',
                    message: err.response.data.message
                });
                setFrmErr(err.response.data);
            }).finally(()=>{
                onLoading(false);
            });
        }
        else {
            formData.append('_method', 'put');
            UpdatePlaceRecomendationsData(formData).then(res => {
                setSnackBar({
                    open: true,
                    variant: 'success',
                    message: res.data.message
                });
                onSubmitSuccess();
            }, err => {
                setSnackBar({
                    open: true,
                    variant: 'error',
                    message: err.response.data.message
                });
                setFrmErr(err.response.data);
            }).finally(()=>{
                onLoading(false);
            });
        }
    }

    return (
        <Fragment>
            <MySnackbar
                open={snackBar.open}
                variant={snackBar.variant}
                message={snackBar.message}
                onClose={handleCloseSnackBar}
            />
            <form onSubmit={handleSubmit}>
                <div
                    style={{
                        width: '70%',
                        margin: '0 auto',
                        marginBottom: '2rem'
                    }}
                >
                    <ChooseImage
                        name="image"
                        label="Image Rekomendasi Tempat"
                        value=""
                        id="image-place-recomendation"
                        src={props?.image || undefined}
                        onChange={handleChange}
                    />
                </div>
                <Grid container>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Nama Tempat Rekomendasi"
                            name="title"
                            type="text"
                            value={data?.title ?? ''}
                            error={ frmErr?.errors?.title }
                            helperText={ frmErr?.errors?.title }
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Alamat"
                            name="address"
                            type="text"
                            value={data?.address ?? ''}
                            error={ frmErr?.errors?.address }
                            helperText={ frmErr?.errors?.address }
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Buka"
                            name="open"
                            type="text"
                            value={data?.open ?? ''}
                            error={ frmErr?.errors?.open }
                            helperText={ frmErr?.errors?.open }
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Tutup"
                            name="closed"
                            type="text"
                            value={data?.closed ?? ''}
                            error={ frmErr?.errors?.closed }
                            helperText={ frmErr?.errors?.closed }
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Latitude"
                            name="lat"
                            type="text"
                            value={data?.lat ?? ''}
                            error={ frmErr?.errors?.lat }
                            helperText={ frmErr?.errors?.lat }
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            fullWidth
                            label="Longitude"
                            name="lng"
                            type="text"
                            value={data?.lng ?? ''}
                            error={ frmErr?.errors?.lng }
                            helperText={ frmErr?.errors?.lng }
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <input type="submit" ref={submitRef} style={{display: 'none'}} />
            </form>
        </Fragment>
    );
};

export default FormContent;