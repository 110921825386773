import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import DialogTitle from '../Dialog/DialogTitle';

const Confirm = props => {
	const { content, open, onAction, loading } = props;

	const handleAction = res => {
		onAction(res);
	};

	return (
		<Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xs" aria-labelledby="confirmation-title" open={open}>
			<DialogTitle id="confirmation-title">Konfirmasi</DialogTitle>
			<DialogContent dividers>{content}</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => handleAction(false)}>
					Cancel
				</Button>
				<Button disabled={loading} onClick={() => handleAction(true)} color="primary">
					{loading ? <CircularProgress size={20} /> : `Ok`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Confirm;
