import { Typography } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import history from '../../config/history';
import { checkAuthorization } from '../../config/redux';


const GuestAuthorization = WrappedComponent => {
	class Guest extends Component {
		componentDidMount() {
			this.props.checkAuthorization().then(
				res => {
					history.push('/panel/dashboard');
                    
				},
				err => {
					console.log('result code error', err);
				}
			);
		}

		Forbidden = () => {
			return <Typography variant="body2">Anda tidak berhak mengakses halaman ini</Typography>;
		};

		render() {
			return (
				<Fragment>
					{this.props.loading ? '' : this.props.status === 403 ? <this.Forbidden /> : <WrappedComponent />}
				</Fragment>
			);
		}
	}

	const mapDispatchToProps = dispatch => ({
		checkAuthorization: () => dispatch(checkAuthorization()),
	});

	const mapStateToProps = state => ({
		loading: state.userAuthorization.loading,
		status: state.userAuthorization.status,
	});

	return connect(mapStateToProps, mapDispatchToProps)(Guest);
};

export default GuestAuthorization;
