import React, { Component } from 'react';
import { compose } from 'redux';
import withPanelInfo from '../../../../utils/PanelInfo';
import {fetchPosts} from '../../../../config/redux';
import { connect } from 'react-redux';
import { Grid, withStyles, Button, Card, CardHeader, Divider, CardContent, Box, TableRow, TableCell, IconButton, Icon, Menu, MenuItem } from '@material-ui/core';
import FormSearchPanel from '../../../components/Forms/FormSearchPanel';
import TableData from '../../../components/Panel/Table';
import { dateDef } from '../../../../config/datetime';
import theme from '../../../../config/theme';
import Create from './Create';
import Confirm from '../../../components/Confirm/Confirm';
import { DeletePostsData } from '../../../../config/services';



class index extends Component {

    state = {
        search: '',
        columns: [
            {
                id: 'title',
                label: 'Judul Berita',
                minWidth: 225
            },
            {
                id: 'published_at',
                label: 'Tanggal Publish',
                minWidth: 170
            },
            {
                id: 'created_at',
                label: 'Dibuat',
                minWidth: 200
            }
        ],
        openDialogCreate: false,
        openDialogEdit: false,
        openConfirmDelete: false,
        deleteLoading: false,
        postDelete: null,
        postEdit: null,
        moreItems: [
            'Edit',
            'Delete'
        ],
        anchorEl: null,
        moreOpen: false,
    }

    componentDidMount()
    {
        this.props.fetchData({});
    }

    onSubmitSearch = (event) => {
        if(event.nativeEvent.key === 'Enter') {
            this.props.fetchData({q: this.state.search});
        }
    }

    handleChangePage = (page) => {
        this.props.fetchData({page: page, limit: this.props.posts.meta.per_page});
    }

    handleChangeRowsPerPage = (limit) => {
        this.props.fetchData({limit: limit});
    }

    handleCloseMore = () => {
        this.setState({
            anchorEl: null,
            moreOpen: false
        })
    }

    handleMoreOpen = (event, id) => {
        this.setState({
            anchorEl: event.currentTarget
        }, () => {
            this.setState({
                moreOpen: id
            });
        });
    }

    handleDelete = (res) => {
        if(res) {
            console.log(this.state.postDelete);
            DeletePostsData(this.state.postDelete).then(res => {
                this.props.fetchData();
            }, err => {}).finally( () => {
                this.closeDeleteConfirm();
            });
        }
        else {
            this.closeDeleteConfirm();
        }
    }

    closeDeleteConfirm = () => {
        this.setState({
            openConfirmDelete: false,
            userDelete: null
        });
    }

    clickMoreItem = (item, post) => {
        if(item === 'Edit') {
            this.setState({
                openDialogEdit: true,
                postEdit: post
            });
        }
        else {
            this.setState({
                openConfirmDelete: true,
                postDelete: post.id
            });
        }
        this.handleCloseMore();
    }

    render() {
        const {classes, posts, loading, error} = this.props;
        const {search, columns, moreItems, anchorEl, moreOpen} = this.state;
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item className={classes.grow}></Grid>
                    <Grid item>
                        <FormSearchPanel
                            value={search}
                            onChange={(event) => this.setState({search: event.target.value}, () => {
                                if(this.state.search === '') {
                                    this.props.fetchData()
                                }
                            })}
                            onKeyPress={ this.onSubmitSearch }
                            clearSearchInput={ () => {
                                this.setState({search: ''}, () => this.props.fetchData())
                            } }
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={ () => this.setState({
                                openDialogCreate: true
                            })}
                        >
                            Tambah Berita
                        </Button>
                    </Grid>
                </Grid>
                <Box
                    className={classes.boxContainer}
                >
                    <Card>
                        <CardHeader
                            title="Data Berita"
                            titleTypographyProps={{
                                className: classes.cardTitle
                            }}
                        />
                        <Divider />
                        <CardContent
                            className={classes.cardContent}
                        >

                                <TableData 
                                    columns={columns}
                                    meta={posts.meta || {}}
                                    loading={loading}
                                    error={error}
                                    onHandleChangePage={ (page) => this.handleChangePage(page) }
                                    onHandleChangeRowsPerPage={ (limit) => this.handleChangeRowsPerPage(limit)}
                                >
                                    { posts.data.map( post => (
                                        <TableRow
                                            key={post.id}
                                            hover>
                                            <TableCell>{post.title}</TableCell>
                                            <TableCell>{dateDef(post.published_at)}</TableCell>
                                            <TableCell>{dateDef(post.created_at)}</TableCell>
                                            <TableCell>
                                                <IconButton 
                                                    size="small"
                                                    onClick={ (event) => this.handleMoreOpen(event, post.id) }
                                                >
                                                    <Icon>
                                                        more_vert
                                                    </Icon>
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={moreOpen === post.id ? true : false}
                                                    onClose={this.handleCloseMore}
                                                >
                                                    { moreItems.map( (item) => (
                                                        <MenuItem key={item} onClick={() => this.clickMoreItem(item, post)}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableData>
                        </CardContent>
                    </Card>
                </Box>
                { this.state.openDialogCreate &&
                    <Create
                        open={this.state.openDialogCreate}
                        handleClose={ () => this.setState({
                            openDialogCreate: false
                        })}
                        loading={false}
                    />
                }
                { this.state.openDialogEdit && 
                    <Create
                        open={this.state.openDialogEdit}
                        postEdit={this.state.postEdit}
                        handleClose={ () => this.setState({
                            openDialogEdit: false,
                            postEdit: null
                        })}
                        loading={false}
                    />
                }
                { this.state.openConfirmDelete && 
                    <Confirm
                        loading={this.state.deleteLoading} 
                        open={this.state.openConfirmDelete} 
                        content="Anda yakin ingin menghapus data ?" 
                        onAction={ (res) => this.handleDelete(res) } 
                    />
                }
            </div>
        );
    }
}

const panelInfo = {
    title: 'Manage Berita'
}

const styles = () => ({
    grow: {
        flex: 1
    },
    boxContainer: {
        margin: '2rem 0'
    },
    ...theme.styles.panelCard
});

const mapStateToProps = state => ({
    posts: state.posts.posts,
    loading: state.posts.postsLoading,
    error: state.posts.postsError,
});

const mapDispatchToProps = dispatch => ({
   fetchData: (params) => dispatch(fetchPosts(params))
});

export default compose(
    withPanelInfo(panelInfo),
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(index);