import { DRAWER_MOBILE_OPEN } from './drawerType';

const drawerMobileOpen = () => {
    return {
        type: DRAWER_MOBILE_OPEN
    }
}

export const onDrawerMobileOpen = () => {
    return dispatch => {
        dispatch(drawerMobileOpen());
    }
}