import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';

const styles = makeStyles({
    root: {
        color: blue[700],
        textDecoration: "none"
    },
    typography: {
        color: grey[700]
    }
});

export const TextUri = (props) => {
    const classes = styles();
    return (

        <Typography
            {...props.typographyProps}
            className={classes.typography}
        >
            { props.appendText }
            <Link
                className={classes.root}
                {...props.linkProps}
            >
                {props.children}
            </Link>
        </Typography>
    );
};
