export const FETCH_REST_AREA = 'FETCH_REST_AREA';
export const FETCH_REST_AREA_SUCCESS = 'FETCH_REST_AREA_SUCCESS';
export const FETCH_REST_AREA_FAILURE = 'FETCH_REST_AREA_FAILURE';

// export const STORE_GATE = 'STORE_GATE';
// export const STORE_GATE_SUCCESS = 'STORE_GATE_SUCCESS';
// export const STORE_GATE_FAILURE = 'STORE_GATE_FAILURE';

// export const UNSET_ERROR = 'UNSET_ERROR';

// export const UPDATE_GATE = 'UPDATE_GATE';
// export const UPDATE_GATE_SUCCESS = 'UPDATE_GATE_SUCCESS';
// export const UPDATE_GATE_FAILURE = 'UPDATE_GATE_FAILURE';