import React, { PureComponent } from 'react';
import TextField from '../../../components/TextField';
import { AuthContent } from '../../../components/Auth';
import Button from '../../../components/Button';
import { tryResetPassword } from '../../../../config/redux';
import { connect } from 'react-redux';
import MySnackbar from '../../../components/Snackbar';

class index extends PureComponent {

    state = {
        email: '',
        messageSuccess: '',
        snackbar: false,
        snackbarSuccess: false
    }

    componentDidMount = () => {
        document.title = 'Reset Password';
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.tryResetPassword(this.state.email).then( res => {
            this.setState({
                email: {},
                messageSuccess: res.message,
                snackbarSuccess: true
            })
        }, err => {
            this.setState({
                snackbar: true
            })
        });
    }

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: false,
            snackbarSuccess: false
        })
    }

    render() {
        const { error } = this.props;
        return (
            <AuthContent>
                <MySnackbar
                    open={ this.state.snackbar }
                    message={ this.props.error?.message}
                    variant="error"
                    onClose={this.handleCloseSnackbar}
                />
                <MySnackbar
                    open={ this.state.snackbarSuccess }
                    message={ this.state.messageSuccess }
                    variant="success"
                    onClose={this.handleCloseSnackbar}
                />
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        name="email"
                        value={this.state.email.email}
                        onChange={ (event) => {
                            this.setState({
                                email: {
                                    email: event.target.value
                                }
                            })
                        }}
                        label="Email Anda"
                        error={ error.errors?.email ? true : false }
                        helperText={ error.errors?.email }
                        type="email"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={ this.props.loading }
                        onClick={ this.handleSubmit }
                        style={{
                            minWidth: 120
                        }}
                        fullWidth
                    >
                        Kirim Email Reset Password
                    </Button>
                </form>
            </AuthContent>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.resetPassword.loading,
    currentUser: state.resetPassword.currentUser,
    error: state.resetPassword.error,
});

const mapDispatchToProps = dispatch => ({
    tryResetPassword: (email) => dispatch(tryResetPassword(email))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(index);