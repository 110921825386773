import React, { PureComponent } from 'react';
import TextField from '../../../components/TextField';
import { AuthContent } from '../../../components/Auth';
import Button from '../../../components/Button';
import { tryDoResetPassword } from '../../../../config/redux';
import { connect } from 'react-redux';
import MySnackbar from '../../../components/Snackbar';
import { withRouter } from 'react-router';
import { compose } from 'redux';

class FormResetPassword extends PureComponent {

    state = {
        userInfo: '',
        snackbar: false,
        snackbarSuccess: false
    }

    componentDidMount() {
        this.setToken();
    }

    setToken = () => {
        // console.log(this.props)
        this.setState({
            userInfo: {
                token: this.props.match.params.token
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.tryDoResetPassword(this.state.userInfo).then( res => {
            this.setState({
                userInfo: {},
                snackbarSuccess: true
            })
        }, err => {
            this.setState({
                snackbar: true
            })
            // nothing to do
        });
    }

    handleChange = (event) => {
        const newUserInfo = {...this.state.userInfo};
        newUserInfo[event.target.name] = event.target.value;
        this.setState({
            userInfo: newUserInfo
        });
    }

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: false,
            snackbarSuccess: false
        })
    }

    render() {
        const { error } = this.props;
        const { userInfo } = this.state;
        return (
            <AuthContent>
                <MySnackbar
                    open={ this.state.snackbar }
                    message={ this.props.error?.message}
                    variant="error"
                    onClose={this.handleCloseSnackbar}
                />
                <MySnackbar
                    open={ this.state.snackbarSuccess }
                    message={ this.props.message }
                    variant="success"
                    onClose={this.handleCloseSnackbar}
                />
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        name="email"
                        value={userInfo.email || ''}
                        onChange={ this.handleChange }
                        label="Email Anda"
                        error={ error.errors?.email ? true : false }
                        helperText={ error.errors?.email }
                        type="email"
                        fullWidth
                    />
                    <TextField
                        name="password"
                        value={userInfo.password || ''}
                        onChange={ this.handleChange }
                        label="Password Baru"
                        error={ error.errors?.password ? true : false }
                        helperText={ error.errors?.password }
                        type="password"
                        fullWidth
                    />
                    <TextField
                        name="password_confirmation"
                        value={userInfo.password_confirmation || ''}
                        onChange={ this.handleChange }
                        label="Ulangi Password Baru"
                        type="password"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={ this.props.loading }
                        onClick={ this.handleSubmit }
                        style={{
                            minWidth: 120
                        }}
                        fullWidth
                    >
                        Kirim Email Reset Password
                    </Button>
                </form>
            </AuthContent>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.doResetPassword.loading,
    message: state.doResetPassword.message,
    error: state.doResetPassword.error,
});

const mapDispatchToProps = dispatch => ({
    tryDoResetPassword: (email) => dispatch(tryDoResetPassword(email))
})

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(FormResetPassword);