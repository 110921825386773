import React, { Component } from 'react';
import { Container, Grid, withStyles, CssBaseline, ThemeProvider } from '@material-ui/core';
import logo from '../../../assets/img/logo/logo.png'
import theme from '../../../config/theme';


class index extends Component {
render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Container 
                    maxWidth="lg"
                    className={ classes.authContainer }
                >
                    <CssBaseline />
                    <Grid 
                        container 
                        direction="column"
                        alignContent="center"
                    >
                        <Grid 
                            item 
                            className={classes.container}
                        >
                            <div className={classes.mainContent}>
                                <div className={classes.header}>
                                    <img alt="logo" src={logo} className={ `logo` } /> 
                                </div>
                                <div className={classes.content}>
                                    { this.props.children }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        );
    }
}

const styles = () => ({
    authContainer: {
        minHeight: '100vh',
        paddingTop: 20,
        paddingBottom: 40
    },
    header: {
        padding: '20px 0',
        textAlign: 'center',
        '& .logo': {
            width: 200,
            [theme.breakpoints.down('md')]: {
                width: 80
            }
        }
    },
    footer: {
        marginTop: 20
    },
    mainContent: {
        width: '100%'
    },
    container: {
        width: '35vw',
        [theme.breakpoints.down('md')]: {
            width: '90vw'
        }
    },
    cardHeader: {
        background: theme.palette.primary.main,
    }
})

export default withStyles(styles)(index);