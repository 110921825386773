import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Typography, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = makeStyles( theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const DialogTitle = (props) => {
    const classes = styles();
    const { children, onClose, onHandleClose } = props;
    return (
        <MuiDialogTitle>
            <Typography component="div" variant="h6">{ children }</Typography>
            { onClose &&
                <IconButton aria-label="close" className={classes.closeButton} onClick={onHandleClose}>
                    <CloseIcon />
              </IconButton>
            }
        </MuiDialogTitle>
    );
};

export default DialogTitle;