import React from 'react';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import theme from '../../../config/theme';

const styles = makeStyles({
    root: {
        marginTop: 20,
        color: 'white'
    },
    progress: {
        color: 'white'
    }
});

const index = (props) => {
    const classes = styles();
    return (
        <Button
            className={classes.root}
            style={{
                backgroundColor: props.disabled && theme.palette.primary.main,
            }}
            {...props}
        >
            { props.disabled ?
                <CircularProgress 
                    size={24} 
                    className="classes.progress"
                    classes={{
                        colorPrimary: classes.progress
                    }}    
                />
                :
                props.children
            }
        </Button>
    );
};

export default index;