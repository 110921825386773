import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  Menu,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { utils, writeFileXLSX } from "xlsx";
import { setPageLoading } from "../../../../config/redux";
import {
  ChangeStatusFeulsData,
  DeleteFeulssData,
  GetFeulssData,
} from "../../../../config/services";
import theme from "../../../../config/theme";
import withPanelInfo from "../../../../utils/PanelInfo";
import Confirm from "../../../components/Confirm/Confirm";
import DialogTitle from "../../../components/Dialog/DialogTitle";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import ListItemCard from "../../../components/Panel/ListItemCard";
import TableData from "../../../components/Panel/Table";
import FormContent from "./FormContent";

class index extends Component {
  submitButtonRef = createRef();

  state = {
    openFormDialog: false,
    openConfirmDelete: false,
    datas: [],
    meta: {},
    search: "",
    fetchLoading: false,
    currentEdit: {},
    deleteLoading: false,
    currentDelete: null,
    loadingExport: false,
    columns: [
      {
        id: "title",
        label: "Judul Berita",
        minWidth: 225,
      },
      {
        id: "direction",
        label: "Arah",
        minWidth: 170,
      },
      {
        id: "lat",
        label: "Latitude",
        minWidth: 170,
      },
      {
        id: "lng",
        label: "Longitude",
        minWidth: 170,
      },
      {
        id: "status",
        label: "Didalam Toll",
        minWidth: 120,
      },
      {
        id: "created_at",
        label: "Dibuat",
        minWidth: 200,
      },
    ],
    moreItems: ["Edit", "Delete"],
    anchorEl: null,
    moreOpen: false,
  };

  componentDidMount() {
    this.fetchData({});
  }

  fetchData = (params = {}) => {
    this.setState({
      fetchLoading: true,
    });
    GetFeulssData(params)
      .then(
        (res) => {
          this.setState({
            datas: res.data,
            meta: res.meta,
          });
        },
        (err) => {
          console.log(err.response);
        }
      )
      .finally(() => {
        this.setState({
          fetchLoading: false,
        });
      });
  };

  Item = (props) => {
    const { id, name, image, lat, lng, address } = props.data;
    return (
      <Card>
        <CardMedia
          className={this.props.classes.media}
          image={image || global.no_image}
        />
        <CardContent>
          <List>
            <ListItemCard text="Nama SPBU" value={name} />
            <ListItemCard text="Alamat" value={address} />
            <ListItemCard text="Latitude" value={lat} />
            <ListItemCard text="Longitude" value={lng} />
          </List>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => {
              this.setState({
                openFormDialog: true,
                currentEdit: props.data,
              });
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            disabled={this.state.deleteLoading}
            onClick={() => {
              this.setState({
                openConfirmDelete: true,
                currentDelete: id,
              });
            }}
          >
            {this.state.deleteLoading ? (
              <CircularProgress size="20" />
            ) : (
              "Delete"
            )}
          </Button>
        </CardActions>
      </Card>
    );
  };

  onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      this.fetchData({ q: this.state.search });
    }
  };

  handleDelete = (res, id) => {
    if (res) {
      this.setState({
        deleteLoading: true,
      });
      DeleteFeulssData(this.state.currentDelete)
        .then(
          (res) => {
            this.fetchData();
          },
          (err) => {}
        )
        .finally(() => {
          this.setState({
            deleteLoading: false,
            openConfirmDelete: false,
            currentDelete: null,
          });
        });
    } else {
      this.setState({
        openConfirmDelete: false,
      });
    }
  };

  formDialog = () => {
    const { currentEdit } = this.state;
    const isEdit = Object.keys(currentEdit).length ? true : false;
    return (
      <Dialog
        open={this.state.openFormDialog}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          onClose={true}
          onHandleClose={() => {
            this.setState({
              openFormDialog: false,
              currentEdit: {},
            });
          }}
        >
          {isEdit ? "Edit SPBU" : "Tambah SPBU"}
        </DialogTitle>
        <DialogContent>
          <FormContent
            {...this.state.currentEdit}
            isEdit={isEdit}
            submitRef={this.submitButtonRef}
            onLoading={(loading) => {
              this.setState({
                submitLoading: loading,
              });
            }}
            onSubmitSuccess={() => {
              this.fetchData();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              this.submitButtonRef.current.click();
            }}
            disabled={this.state.submitLoading}
          >
            {this.state.submitLoading ? (
              <CircularProgress size={25} />
            ) : isEdit ? (
              "Update"
            ) : (
              "Simpan"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleChangePage = (page) => {
    this.fetchData({
      q: this.state.search,
      page: page,
      limit: this.state.meta.per_page,
    });
  };

  handleChangeRowsPerPage = (limit) => {
    this.fetchData({ limit: limit });
  };

  handleCloseMore = () => {
    this.setState({
      anchorEl: null,
      moreOpen: false,
    });
  };

  clickMoreItem = (item, data) => {
    if (item === "Edit") {
      this.setState({
        openFormDialog: true,
        currentEdit: data,
      });
    } else {
      this.setState({
        openConfirmDelete: true,
        currentDelete: data.id,
      });
    }
    this.handleCloseMore();
  };

  handleMoreOpen = (event, id) => {
    this.setState(
      {
        anchorEl: event.currentTarget,
      },
      () => {
        this.setState({
          moreOpen: id,
        });
      }
    );
  };

  handleChangeStatus = (value, id) => {
    ChangeStatusFeulsData({ id: id, inside_toll: value }).then(
      (res) => {
        this.fetchData();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  handleExport = async () => {
    try {
      this.setState({ loadingExport: true });
      this.props.setLoadingPage(true);
      const response = await GetFeulssData({
        paginate: false,
      });
      const result = response.data.map((fuel) => ({
        ID: fuel.id,
        "Nama SPBU": fuel.title,
        Direction: fuel.direction,
        "Estimasi Waktu": fuel.estimated_time,
        latitude: fuel.lat,
        longitude: fuel.lng,
        Toll: fuel.inside_toll == 1 ? "Di dalam Toll" : "Di luar Toll",
        Dibuat: moment(fuel.created_at).format("HH:mm:ss - dddd, DD-MM-YYYY"),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-SPBU.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loadingExport: false });
      this.props.setLoadingPage(false);
    }
  };

  render() {
    const { classes } = this.props;
    const {
      search,
      fetchLoading,
      datas,
      columns,
      meta,
      moreItems,
      moreOpen,
      anchorEl,
    } = this.state;
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item className={classes.grow}></Grid>
          <Grid item>
            <FormSearchPanel
              value={search}
              onChange={(event) =>
                this.setState({ search: event.target.value }, () => {
                  if (this.state.search === "") {
                    this.fetchData();
                  }
                })
              }
              onKeyPress={this.onSubmitSearch}
              clearSearchInput={() => {
                this.setState({ search: "" }, () => this.fetchData());
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => this.setState({ openFormDialog: true })}
            >
              Tambah SPBU
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.boxContainer}>
          <Card>
            <CardHeader
              title="Data SPBU"
              titleTypographyProps={{
                className: classes.cardTitle,
              }}
              action={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleExport}
                  disabled={this.state.loadingExport}
                >
                  Export Data
                </Button>
              }
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <TableData
                columns={columns}
                meta={meta || {}}
                loading={fetchLoading}
                // error={error}
                onHandleChangePage={(page) => this.handleChangePage(page)}
                onHandleChangeRowsPerPage={(limit) =>
                  this.handleChangeRowsPerPage(limit)
                }
              >
                {datas.map((data) => (
                  <TableRow key={data.id} hover>
                    <TableCell>{data.title}</TableCell>
                    <TableCell>{data.direction}</TableCell>
                    <TableCell>{data.lat}</TableCell>
                    <TableCell>{data.lng}</TableCell>
                    <TableCell>
                      <Switch
                        onChange={(e) =>
                          this.handleChangeStatus(e.target.checked, data.id)
                        }
                        checked={data.inside_toll}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={(event) => this.handleMoreOpen(event, data.id)}
                      >
                        <Icon>more_vert</Icon>
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={moreOpen === data.id ? true : false}
                        onClose={this.handleCloseMore}
                      >
                        {moreItems.map((item) => (
                          <MenuItem
                            key={item}
                            onClick={() => this.clickMoreItem(item, data)}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableData>
            </CardContent>
          </Card>
        </Box>
        {this.state.openFormDialog && <this.formDialog />}
        {this.state.openConfirmDelete && (
          <Confirm
            loading={this.state.deleteLoading}
            open={this.state.openConfirmDelete}
            content="Anda yakin ingin menghapus data ?"
            onAction={(res) => this.handleDelete(res)}
          />
        )}
      </div>
    );
  }
}

const useStyles = () => ({
  cardContent: {
    maxHeight: 340,
    overflow: "scroll",
  },
  cctvs: {
    display: "flex",
    flexWrap: "wrap",
  },
  itemCctv: {
    marginRight: 10,
    marginBottom: 10,
    "&:last-child": {
      marginRight: 0,
    },
  },
  pagination: {
    margin: "2rem 0",
  },
  facilities: {
    display: "flex",
    flexWrap: "wrap",
  },
  iconFacility: {
    "&:last-child": {
      marginRight: 0,
    },
    width: 25,
    height: 25,
    marginRight: 8,
    marginBottom: 8,
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
  cardRoot: {
    minHeight: 300,
    overflow: "scroll",
  },
  cardTitleRoot: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  cardSubTitleRoot: {
    fontSize: ".9rem",
    color: theme.palette.secondary.main,
    marginTop: 10,
  },
  grow: {
    flex: 1,
  },
  media: {
    height: 170,
    width: "100%",
    borderTop: "1px solid " + theme.palette.primary.main,
    borderBottom: "2px solid red",
  },
  root: {
    margin: "2rem 0",
  },
  boxContainer: {
    margin: "2rem 0",
  },
  ...theme.styles.panelCard,
});

const panelInfo = {
  title: "Manage SPBU",
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setLoadingPage: (params) => dispatch(setPageLoading(params)),
});

export default compose(
  withPanelInfo(panelInfo),
  withStyles(useStyles()),
  connect(mapStateToProps, mapDispatchToProps)
)(index);
