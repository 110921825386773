import { Icon, InputBase, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../../config/theme";
import { fileType } from "../../../utils/Validation";

const styles = makeStyles(() => ({
  root: {
    border: "1px solid #ddd",
  },
  label: {
    padding: "1rem .8rem",
    background: theme.palette.primary.main,
    color: "white",
    fontSize: ".9rem",
    fontWeight: "bold",
  },
  imageContainer: {
    "& figure": {
      margin: 0,
      padding: 0,
      cursor: "pointer",
      position: "relative",
      minHeight: 200,
      "& img": {
        maxWidth: "100%",
      },
      "&:hover": {
        "& .overlay": {
          height: "100%",
          transition: "height .25s ease",
        },
      },
      "& .overlay": {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "rgba(0, 0, 0, .5)",
        display: "flex",
        height: 0,
        transition: "height .25s ease-out",
        justifyContent: "center",
        overflow: "hidden",
        "& p": {
          color: "white",
          textAlign: "center",
          fontSize: "1rem",
          alignSelf: "center",
        },
      },
    },
  },
}));

const ChooseImage = (props) => {
  const { label, name, onChange, id, value, src } = props;
  const classes = styles();

  const handleClick = () => {
    document.getElementById(id).click();
  };

  const onChangeImage = (event) => {
    const validateFile = fileType(event.target.value, [
      ".jpg",
      ".jpeg",
      ".png",
    ]);
    if (validateFile) {
      const img = document.getElementById(id + "-preview");
      const file = event.target.files[0];
      onChange(event);
      img.src = URL.createObjectURL(file);
    } else {
      alert("File Tidak Valid");
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        classes={{
          root: classes.label,
        }}
        variant="title"
        component="div"
      >
        {label || "Pilih Gambar"}
      </Typography>
      <div className={classes.imageContainer}>
        <figure onClick={handleClick}>
          <img
            src={src !== undefined ? src : global.no_image}
            id={id + "-preview"}
          />
          <div className="overlay">
            <p>
              <Icon>camera</Icon>
              <br />
              Pilih Gambar
            </p>
          </div>
          <InputBase
            type="file"
            name={name}
            onChange={onChangeImage}
            value={value}
            id={id}
            style={{ display: "none" }}
          />
        </figure>
      </div>
    </div>
  );
};

export default ChooseImage;
