import {
    FETCH_DO_RESET_PASSWORD,
    FETCH_DO_RESET_PASSWORD_SUCCESS,
    FETCH_DO_RESET_PASSWORD_FAILURE,
}  from './doResetPasswordType';

const initalState = {
    loading: false,
    message: '',
    error: {}
}

const reducer = (state = initalState, action) => {
    switch(action.type) {
        case FETCH_DO_RESET_PASSWORD:
            return {
                ...state,
                loading: true,
                error: {}
            }
        case FETCH_DO_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                message: action.payload
            }
        case FETCH_DO_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                message: ''
            }
        default: return state;
    }
}

export default reducer;