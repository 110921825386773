import { FETCH_REST_AREA, FETCH_REST_AREA_SUCCESS, FETCH_REST_AREA_FAILURE } from "./restAreasType"


const initialState = {
    restAreas: [],
    restAreasError: {},
    restAreasLoading: true,
    // storeGate: {},
    // storeGateLoading: false,
    // storeGateError: {},
    // gatesUpdate: {},
    // gatesUpdateLoading: false,
    // gatesUpdateError: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_REST_AREA:
            return {
                ...state,
                restAreasLoading: true
            }
        case FETCH_REST_AREA_SUCCESS:
            return {
                ...state,
                restAreasLoading: false,
                restAreas: action.payload
            }
        case FETCH_REST_AREA_FAILURE:
            return {
                ...state,
                restAreasLoading: false,
                restAreasError: action.payload
            }
        // case STORE_GATE: 
        //     return {
        //         ...state,
        //         storeGateLoading: true
        //     }
        // case STORE_GATE_SUCCESS: 
        //     return {
        //         ...state,
        //         storeGateLoading: false,
        //         storeGate: action.payload
        //     }
        // case STORE_GATE_FAILURE: 
        //     return {
        //         ...state,
        //         storeGateLoading: false,
        //         storeGateError: action.payload
        //     }
        // case UNSET_ERROR:
        //     return {
        //         ...state,
        //         storeGateError: {}
        //     }
        // case UPDATE_GATE:
        //     return {
        //         ...state,
        //         gatesUpdateLoading: true
        //     }
        // case UPDATE_GATE_SUCCESS:
        //     return {
        //         ...state,
        //         gatesUpdateLoading: false,
        //         gatesUpdate: action.payload
        //     }
        // case UPDATE_GATE_FAILURE:
        //     return {
        //         ...state,
        //         gatesUpdateLoading: false,
        //         storeGateError: action.payload
        //     }
        default: return state;
    }
}

export default reducer;