import { createMuiTheme } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1361a7',
            light: 'rgb(243, 255, 253)',
        },
        red: {
            main: red[500]
        },
        secondary: {
            main: '#d01f43'
        },
        background: {
            default: grey[50] //'rgb(203, 255, 246)'
        },
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: '#1361a7',
                '& div': {
                    color: 'white'
                },
                '& button': {
                    color: 'white'
                }
            }
        },
        MuiDialogContent: {
            root: {
                backgroundColor: '#fafafa',
            }
        },
        MuiDialogActions: {
            root: {
                backgroundColor: grey[200],
                paddingTop: 15,
                paddingBottom: 15,
            }
        },
        MuiBadge: {
            colorSecondary: {
                backgroundColor: red[500]
            }
        },
        MuiButton: {
            containedPrimary: {
                color: 'white'
            }
        }
    },
    custom: {
        drawerWidth: 240
    },
    styles: {
        panelCard: {
            cardTitle: {
                fontSize: 18,
                fontWeight: 'bold'
            },
            cardContent: {
                padding: 0 + ' !important'
            }
        },
        cardForm: {
            cardHeader: {
                paddingTop: 15,
                paddingBottom: 5,
                fontSize: 18
            },
            cardContent: {
                paddingTop: 0,
                paddingBottom: 10,
            },
        }
    }
});

export default theme;