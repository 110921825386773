import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';

const styles = makeStyles({
    root: {
        marginTop: 5,
        marginBottom: 10,
    }
})

const index = (props) => {
    const classes = styles();
    return (
        <TextField
            className={classes.root}
            {...props}
        />
    );
};

export default index;