import { Icon, InputAdornment, InputBase, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
	searchAdornment: {
		cursor: 'pointer',
	},
	outlined: {
		border: '1px solid #ddd',
		padding: '.1em .5em',
		borderRadius: 5,
		color: '#555',
	},
}));

const FormSearchPanel = ({ value, placeholder, onChange, onKeyPress, clearSearchInput }) => {
	const classes = useStyles();
	return (
		<InputBase
			className={classes.outlined}
			placeholder={placeholder || 'Pencarian...'}
			value={value}
			onChange={onChange}
			onKeyPress={onKeyPress}
			startAdornment={
				<InputAdornment className={classes.searchAdornment} onClick={clearSearchInput} position="start">
					{value ? <Icon>close</Icon> : <Icon>search</Icon>}
				</InputAdornment>
			}
		/>
	);
};

export default FormSearchPanel;
