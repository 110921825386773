import React, { Component } from 'react';
import { AuthHeader, AuthFooter } from '../../../components/Auth';
import { Card, Typography } from '@material-ui/core';
import FormSendLinkResetPassword from '../../../components/Forms/FormSendLinkResetPassword';
import { TextUri } from '../../../components/Uri';

class index extends Component {
    
    render() {
        return (
            <div>
                <Card>
                    <AuthHeader
                        title="Reset Password"
                    />
                    <FormSendLinkResetPassword />
                    
                </Card>

                <AuthFooter>
                    
                    <Typography variant="body1">
                        <TextUri
                            linkProps={{
                                to: '/'
                            }}
                            typographyProps={{
                                variant: 'body1'
                            }}
                            appendText="kehalaman "
                        >
                            login
                        </TextUri>
                    </Typography>
                </AuthFooter>
            </div>
        );
    }
}

export default index;