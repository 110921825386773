import React, { Component } from "react";
import { Provider } from "react-redux";
import { Router, Switch } from "react-router-dom";
import "../../../config/app";
import history from "../../../config/history";
import store from "../../../config/redux/store";
import Authorization from "../../../utils/Authorization";
import ForgotPassword from "../../pages/Auth/ForgotPassword";
import ResetPassword from "../../pages/Auth/ResetPassword";
import Auth from "../../RouteLayout/Auth";
import Dashboard from "../../RouteLayout/Dashboard";
import Public from "../../RouteLayout/Public";

// Pages
import Login from "../../pages/Auth/Login";
import Logout from "../../pages/Auth/Logout";
// import Registration from '../../pages/Auth/Registration';
// import RegistrationSuccess from '../../pages/Auth/Registration/success';
import moment from "moment";
import "moment/locale/id";
import GuestAuthorization from "../../../utils/WithoutAuthorization";
import DashboardPage from "../../pages/Admin/Dashboard";
import FeulsPage from "../../pages/Admin/Feuls/index";
import GatesPage from "../../pages/Admin/Gates/index.jsx";
import HotelsPage from "../../pages/Admin/Hotels/index";
import NewsPage from "../../pages/Admin/News/index";
import PlaceRecomendationsPage from "../../pages/Admin/PlaceRecomendations/index";
import RestAreaPage from "../../pages/Admin/RestAreas/index.jsx";
import SliderPage from "../../pages/Admin/Sliders/index.jsx";
import StreamsPage from "../../pages/Admin/Streams/index";
import CallCenter from "../Admin/CallCenter";
import Facility from "../Admin/Facility/Facility";
import Gerbang from "../Admin/Gerbang";
import Logs from "../Admin/Logs";
import Reviews from "../Admin/Reviews/Reviews";
import RuasToll from "../Admin/RuasToll";
import Tracks from "../Admin/Tracks";
import UserPage from "../Admin/Users/UserPage";
import Stream from "../Front/Streams";

moment.locale("id");
moment.updateLocale("id", {
  week: {
    dow: 1,
    doy: 7,
  },
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Auth exact path="/" component={GuestAuthorization(Login)} />
            <Auth
              exact
              path="/forgot-password"
              component={GuestAuthorization(ForgotPassword)}
            />
            <Auth
              exact
              path="/reset-password/:token"
              component={GuestAuthorization(ResetPassword)}
            />
            <Public exact path="/stream" component={Stream} />
            <Dashboard
              exact
              path="/panel/dashboard"
              component={Authorization(DashboardPage)}
            />
            <Dashboard
              exact
              path="/panel/sliders"
              component={Authorization(SliderPage)}
            />
            <Dashboard
              exact
              path="/panel/gates"
              component={Authorization(GatesPage)}
            />
            <Dashboard
              exact
              path="/panel/rest-areas"
              component={Authorization(RestAreaPage)}
            />
            <Dashboard
              exact
              path="/panel/news"
              component={Authorization(NewsPage)}
            />
            <Dashboard
              exact
              path="/panel/cctv"
              component={Authorization(StreamsPage)}
            />
            <Dashboard
              exact
              path="/panel/users"
              component={Authorization(UserPage)}
            />
            <Dashboard
              exact
              path="/panel/hotels"
              component={Authorization(HotelsPage)}
            />
            <Dashboard
              exact
              path="/panel/place-recomendations"
              component={Authorization(PlaceRecomendationsPage)}
            />
            <Dashboard
              exact
              path="/panel/feuls"
              component={Authorization(FeulsPage)}
            />
            <Dashboard
              exact
              path="/panel/log"
              component={Authorization(Logs)}
            />
            <Dashboard
              exact
              path="/panel/ruas-toll"
              component={Authorization(RuasToll)}
            />
            <Dashboard
              exact
              path="/panel/call-centers"
              component={Authorization(CallCenter)}
            />
            <Dashboard
              exact
              path="/panel/gerbang-toll"
              component={Authorization(Gerbang)}
            />
            <Dashboard
              exact
              path="/panel/jalur-toll"
              component={Authorization(Tracks)}
            />
            <Dashboard
              exact
              path="/panel/testimoni"
              component={Authorization(Reviews)}
            />
            <Dashboard
              exact
              path="/panel/fasilitas"
              component={Authorization(Facility)}
            />
            <Dashboard exact path="/logout" component={Authorization(Logout)} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
