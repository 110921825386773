import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { utils, writeFileXLSX } from "xlsx";
import { dateDef } from "../../../../config/datetime";
import { setPageLoading, setPanelTitle } from "../../../../config/redux";
import {
  DeleteSegmentGateData,
  GetSegmentGates,
  GetSegmentLists,
} from "../../../../config/services";
import Confirm from "../../../components/Confirm/Confirm";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import TableData from "../../../components/Panel/Table";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View";

const useStyles = makeStyles((theme) => ({
  grow: {
    flex: 1,
  },
  cardRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  select: {
    minWidth: 250,
  },
  ...theme.styles.panelCard,
}));

const columns = [
  {
    id: "id",
    label: "ID",
    minWidth: 120,
  },
  {
    id: "name",
    label: "Nama Gerbang",
    minWidth: 225,
  },
  {
    id: "segment",
    label: "Ruas Toll",
    minWidth: 225,
  },
  {
    id: "description",
    label: "Deskripsi",
    minWidth: 225,
  },
  {
    id: "created_at",
    label: "Dibuat",
    minWidth: 200,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
  },
];

const moreItems = ["Edit", "View", "Delete"];

function Gerbang() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [segmentGateDelete, setSegmentGateDelete] = useState(null);
  const [segmentGateDeleteLoading, setSegmentGateDeleteLoading] =
    useState(false);
  const [editSegmentGate, setEditSegmentGate] = useState({});
  const [viewSegmentGate, setViewSegmentGate] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);
  const [segments, setSegments] = useState([]);
  const [filter, setFilter] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchRuasToll = async (params) => {
    setLoading((prev) => prev + 1);
    try {
      const response = await GetSegmentLists();
      setSegments(response.data.segments);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => prev - 1);
    }
  };

  const fetchGerbangToll = async (params) => {
    setLoading((prev) => prev + 1);
    try {
      const response = await GetSegmentGates(params);
      setData(response.data.segment_gates);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => prev - 1);
    }
  };

  const handleDelete = async (type) => {
    if (type) {
      setSegmentGateDeleteLoading(true);
      try {
        const response = await DeleteSegmentGateData(segmentGateDelete);
        fetchGerbangToll();
        setSegmentGateDelete(null);
        setOpenConfirmDelete(false);
      } catch (error) {
        console.log(error);
      } finally {
        setSegmentGateDeleteLoading(false);
      }
    } else {
      setOpenConfirmDelete(false);
    }
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      fetchGerbangToll({ q: search });
    }
  };

  const clearSearchInput = () => {
    if (search) {
      setSearch("");
      fetchGerbangToll();
    }
  };

  const dialogCreateOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleChangePage = (page) => {
    fetchGerbangToll({ page: page, limit: data.meta.per_page });
  };

  const handleChangeRowsPerPage = (limit) => {
    fetchGerbangToll({ limit: limit });
  };

  const handleMoreOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMoreOpen(id);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setMoreOpen(false);
  };

  const clickMoreItem = (item, segmentGate) => {
    if (item === "Edit") {
      setEditSegmentGate(segmentGate);
    } else if (item === "Delete") {
      setOpenConfirmDelete(true);
      setSegmentGateDelete(segmentGate.id);
    } else {
      setViewSegmentGate(segmentGate);
    }
    handleCloseMore();
  };

  const handleCloseView = () => {
    setViewSegmentGate({});
  };

  useEffect(() => {
    fetchRuasToll();
    fetchGerbangToll();

    dispatch(setPanelTitle("Gerbang Toll"));
  }, []);

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetSegmentGates({
        paginate: false,
      });
      const result = response.data.segment_gates.map((segmentGate) => ({
        ID: segmentGate.id,
        "Nama Gerbang": segmentGate.name,
        "Ruas Toll": segmentGate.segment.name,
        Deskripsi: segmentGate.description,
        Dibuat: moment(segmentGate.created_at).format(
          "HH:mm:ss - dddd, DD-MM-YYYY"
        ),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-Gerbang-Toll.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    fetchGerbangToll({ segment: event.target.value, q: search });
  };

  return (
    <div>
      <Helmet>
        <title>Gerbang Toll</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item className={classes.grow}></Grid>
        <Grid item className={classes.select}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Filter Ruas Toll</InputLabel>
            <Select
              label="Ruas Toll"
              name="segment_id"
              value={filter}
              onChange={handleChangeFilter}
              placeholder="Pilih Ruas Toll"
            >
              <MenuItem value={null}>Semua Ruas</MenuItem>
              {segments.map((segment) => (
                <MenuItem value={segment.id}>{segment.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={handleChangeSearch}
            onKeyPress={onSubmitSearch}
            clearSearchInput={clearSearchInput}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={dialogCreateOpen}
          >
            Buat Gerbang Toll
          </Button>
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <CardHeader
          title="Data Gerbang Toll"
          titleTypographyProps={{
            className: classes.cardTitle,
          }}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={loadingExport}
            >
              Export Data
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <TableData
            columns={columns}
            meta={{ ...data.meta, ...data.links }}
            loading={loading > 0}
            onHandleChangePage={(page) => handleChangePage(page)}
            onHandleChangeRowsPerPage={(limit) =>
              handleChangeRowsPerPage(limit)
            }
          >
            {data.data?.map((segmentGate) => (
              <TableRow key={segmentGate.id} hover>
                <TableCell>{segmentGate.id}</TableCell>
                <TableCell>{segmentGate.name}</TableCell>
                <TableCell>{segmentGate.segment.name}</TableCell>
                <TableCell>{segmentGate.description}</TableCell>
                <TableCell>{dateDef(segmentGate.created_at)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMoreOpen(event, segmentGate.id)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={moreOpen === segmentGate.id ? true : false}
                    onClose={handleCloseMore}
                  >
                    {moreItems.map((item) => (
                      <MenuItem
                        key={item}
                        onClick={() => clickMoreItem(item, segmentGate)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableData>
        </CardContent>
      </Card>
      {createDialogOpen && (
        <Create
          fetchGerbangToll={fetchGerbangToll}
          segments={segments}
          createDialogOpen={createDialogOpen}
          setCreateDialogOpen={setCreateDialogOpen}
        />
      )}
      {!isEmpty(editSegmentGate) && (
        <Edit
          fetchGerbangToll={fetchGerbangToll}
          open={!isEmpty(editSegmentGate)}
          segmentGate={editSegmentGate}
          segments={segments}
          setEditSegmentGate={setEditSegmentGate}
        />
      )}

      {!isEmpty(viewSegmentGate) && (
        <View
          open={!isEmpty(viewSegmentGate)}
          viewSegmentGate={viewSegmentGate}
          setViewSegmentGate={setViewSegmentGate}
        />
      )}
      {openConfirmDelete && (
        <Confirm
          loading={segmentGateDeleteLoading}
          open={openConfirmDelete}
          content="Anda yakin ingin menghapus data ?"
          onAction={(type) => handleDelete(type)}
        />
      )}
    </div>
  );
}

export default Gerbang;
