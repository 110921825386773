import { combineReducers } from 'redux';
import messageReducer from './messages/messageReducer';
import userRegisterReducer from './userRegister/userRegisterReducer';
import userLoginReducer from './userLogin/userLoginReducer';
import userLogoutReducer from './userLogout/userLogoutReducer';
import userAuthorizationReducer from './userAuthorization/userAuthorizationReducer';
import resetPasswordReducer from './resetPassword/resetPasswordReducer';
import doResetPasswordReducer from './doResetPassword/doResetPasswordReducer';
import drawerReducer from './drawer/drawerReducer';
import panelMenuReducer from './panelMenu/panelMenuReducer';
import pageLoadingReducer from './pageLoading/pageLoadingReducer';
import panelInfoReducer from './panelInfo/panelInfoReducer';
import userDataReducer from './userData/userDataReducer';
import createUserReducer from './createUser/createUserReducer';
import roleReducer from './roles/roleReducer';
import gateReducer from './Gates/gateReducer';
import postsReducer from './Posts/postReducer';
import streamReducer from './Streams/streamReducer';
import segmentReducer from './Segments/segmentReducer';
import restAreaReducer from './RestAreas/restAreaReducer';


const rootReducer = combineReducers({
    messages: messageReducer,
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    userLogout: userLogoutReducer,
    userAuthorization: userAuthorizationReducer,
    resetPassword: resetPasswordReducer,
    doResetPassword: doResetPasswordReducer,
    drawer: drawerReducer,
    panelMenu: panelMenuReducer,
    pageLoading: pageLoadingReducer,
    panelInfo: panelInfoReducer,
    userData: userDataReducer,
    createUser: createUserReducer,
    roleLists: roleReducer,
    gates: gateReducer,
    posts: postsReducer,
    streams: streamReducer,
    segments: segmentReducer,
    restAreas: restAreaReducer
});

export default rootReducer;