import React, { Fragment } from 'react';
import { Avatar, Icon, makeStyles } from '@material-ui/core';
import theme from '../../../config/theme';

const styles = makeStyles( () => ({
    avatar: {
        cursor: 'pointer',
        border: '1px solid ' + theme.palette.primary.main,
        borderRadius: '50%',
        position: 'relative',
        '& .editFotoOverlay': {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
        },
        '& .hoverContainer': {
            position: 'absolute',
            top: 0,
            width: '100%',
            bottom: 0,
            background: 'rgba(0,0,0, 0.5)',
            color: 'white',
            justifyContent: 'center',
            borderRadius: '50%',
            display: 'none'
        },
        '&:hover': {
            '& .hoverContainer': {
                display: 'flex'
            }
        }
    },
    profilePicture: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
    hoverContainerText: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    iconAvatar: {
        fontSize: '7rem',
        color: theme.palette.primary.main,
        position: 'relative',
    }
}));

const ImageProcessing = (props) => {
    const classes = styles();
    const { onClick, src } = props;
    return (
        <div
            className={classes.avatar}
        >
            { src ?
                <Fragment>
                    <Avatar 
                        className={classes.profilePicture}
                        alt="Profile Picture" 
                        src={ src } />
                </Fragment>
                :
                <Icon
                    className={classes.iconAvatar}
                >
                    account_circle
                </Icon>
            }
            <div
                className="hoverContainer"
                onClick={ onClick }
            >
                <p className={classes.hoverContainerText}>
                    <Icon>
                        camera
                    </Icon><br/>
                    Edit Photo
                </p>
            </div>
        </div>
    );
};

export default ImageProcessing;