import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  Switch,
  TableCell,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Player } from "video-react";
import { utils, writeFileXLSX } from "xlsx";
import { dateDef } from "../../../../config/datetime";
import { setPageLoading, setPanelTitle } from "../../../../config/redux";
import {
  ChangeStatusStreamData,
  DeleteStreamData,
  GetSegmentLists,
  GetStreamsData,
} from "../../../../config/services";
import theme from "../../../../config/theme";
import Confirm from "../../../components/Confirm/Confirm";
import DialogTitle from "../../../components/Dialog/DialogTitle";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import HLSSource from "../../../components/HLSSource";
import TableData from "../../../components/Panel/Table";
import Create from "./Create";

const columns = [
  {
    id: "name",
    label: "Nama CCTV",
    minWidth: 225,
  },
  {
    id: "segment",
    label: "Ruas",
    minWidth: 170,
  },
  {
    id: "direction",
    label: "Arah",
    minWidth: 170,
  },
  {
    id: "video",
    label: "Video Streaming",
    minWidth: 170,
  },
  {
    id: "video",
    label: "Live Streaming",
    minWidth: 170,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
  },
  {
    id: "created_at",
    label: "Dibuat",
    minWidth: 200,
  },
  {
    id: "updated_at",
    label: "Diupdate",
    minWidth: 200,
  },
];

function VidStreamDialog({
  vidStreamOpen,
  currentVidStream,
  handleCloseVidStreamDialog,
}) {
  return (
    <Dialog
      open={vidStreamOpen}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle onClose={true} onHandleClose={handleCloseVidStreamDialog}>
        CCTV - {currentVidStream.name}
      </DialogTitle>
      <DialogContent>
        <Player
          poster="http://wtrgo.loc/img/logo.png"
          src={currentVidStream.url}
        />
      </DialogContent>
    </Dialog>
  );
}

function LiveStreamDialog({
  liveStreamOpen,
  currentLiveStream,
  useVideo,
  handleCloseLiveStreamDialog,
}) {
  return (
    <Dialog
      open={liveStreamOpen}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle onClose={true} onHandleClose={handleCloseLiveStreamDialog}>
        CCTV - {currentLiveStream.name}
      </DialogTitle>
      <DialogContent>
        {useVideo ? (
          <Player>
            <HLSSource isVideoChild src={currentLiveStream.url} />
          </Player>
        ) : (
          <div style={{ height: 400, position: "relative" }}>
            <iframe
              title="video"
              src={currentLiveStream.url}
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
              }}
            ></iframe>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles({
  grow: {
    flex: 1,
  },
  boxContainer: {
    margin: "2rem 0",
  },
  select: {
    minWidth: 250,
  },
  ...theme.styles.panelCard,
});

const moreItems = ["Edit", "Delete"];

function Streams() {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [vidStreamOpen, setVidStreamOpen] = useState(false);
  const [currentVidStream, setCurrentVidStream] = useState({});
  const [liveStreamOpen, setLiveStreamOpen] = useState(false);
  const [currentLiveStream, setCurrentLiveStream] = useState({});
  const [openDialogCreate, setOpenDialogCreate] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [streamDelete, setStreamDelete] = useState(null);
  const [streamEdit, setStreamEdit] = useState(null);
  const [useVideo, setUseVideo] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [segments, setSegments] = useState([]);
  const [streams, setStreams] = useState({});
  const [loading, setLoading] = useState(0);
  const [filter, setFilter] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchStream({});
    dispatch(setPanelTitle("Manage CCTV"));
    fetchRuasToll();
  }, [dispatch]);

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    fetchStream({ segment_id: event.target.value, q: search });
  };

  const fetchRuasToll = async (params) => {
    setLoading((prev) => prev + 1);
    try {
      const response = await GetSegmentLists();
      setSegments(response.data.segments);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => prev - 1);
    }
  };

  const fetchStream = async (params) => {
    setLoading((prev) => prev + 1);
    try {
      const response = await GetStreamsData(params);
      setStreams(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => prev - 1);
    }
  };
  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      fetchStream({ q: search, segment_id: filter });
    }
  };

  const handleChangePage = (page) => {
    fetchStream({
      page: page,
      limit: streams.meta.per_page,
      segment_id: filter,
    });
  };

  const handleChangeRowsPerPage = (limit) => {
    fetchStream({ limit: limit, segment_id: filter });
  };

  const handleCloseVidStreamDialog = () => {
    setVidStreamOpen(false);
  };

  const handleCloseLiveStreamDialog = () => {
    setLiveStreamOpen(false);
    setCurrentLiveStream({});
  };

  const handleOpenVidStream = (stream_url, name) => {
    setVidStreamOpen(true);
    setCurrentVidStream({ url: stream_url, name: name });
  };

  const handleOpenLiveStream = (url, name) => {
    if (
      (url != null && !url.includes("rtsp.me")) ||
      url.includes("sn.rtsp.me")
    ) {
      setUseVideo(true);
    } else {
      setUseVideo(false);
    }
    setLiveStreamOpen(true);
    setCurrentLiveStream({
      url: url,
      name: name,
    });
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setMoreOpen(false);
  };

  const handleMoreOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMoreOpen(id);
  };

  const clickMoreItem = (item, stream) => {
    if (item === "Edit") {
      handleEdit(stream);
    } else if (item === "Delete") {
      setOpenConfirmDelete(true);
      setStreamDelete(stream.id);
    } else {
    }
    handleCloseMore();
  };

  const handleEdit = (stream) => {
    setOpenDialogEdit(true);
    setStreamEdit(stream);
  };

  const handleDelete = async (res) => {
    if (res) {
      try {
        const response = await DeleteStreamData(streamDelete);
        fetchStream();
      } catch (error) {
        console.log(error);
      } finally {
        closeDeleteConfirm();
      }
    } else {
      closeDeleteConfirm();
    }
  };

  const closeDeleteConfirm = () => {
    setOpenConfirmDelete(false);
    setStreamDelete(null);
  };

  const handleChangeStatus = async (value, id) => {
    try {
      const response = await ChangeStatusStreamData({
        id: id,
        is_active: value,
      });
      fetchStream();
    } catch (error) {
      console.log(error);
    }
  };

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetStreamsData({
        paginate: false,
      });
      console.log(response.data);
      const result = response.data.map((stream) => ({
        ID: stream?.id,
        Nama: stream?.name,
        "Ruas Toll": stream?.segment,
        Url: stream?.url,
        Direction: stream?.direction,
        Video: stream?.video,
        Live: stream?.live,
        "Tipe Video": stream?.videoType,
        Dibuat: moment(stream?.created_at).format(
          "HH:mm:ss - dddd, DD-MM-YYYY"
        ),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-CCTV.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item className={classes.grow}></Grid>
        <Grid item className={classes.select}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Filter Ruas Toll</InputLabel>
            <Select
              label="Ruas Toll"
              name="segment_id"
              value={filter}
              onChange={handleChangeFilter}
              placeholder="Pilih Ruas Toll"
            >
              <MenuItem value={null}>Semua Ruas</MenuItem>
              {segments.map((segment) => (
                <MenuItem value={segment.id}>{segment.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
              if (search === "") {
                fetchStream();
              }
            }}
            onKeyPress={onSubmitSearch}
            clearSearchInput={() => {
              setSearch("");
              fetchStream();
            }}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => setOpenDialogCreate(true)}>
            Tambah CCTV
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.boxContainer}>
        <Card>
          <CardHeader
            title="Data CCTV"
            titleTypographyProps={{
              className: classes.cardTitle,
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleExport}
                disabled={loadingExport}
              >
                Export Data
              </Button>
            }
          />
          <Divider />
          <CardContent className={classes.cardContent}>
            <TableData
              columns={columns}
              meta={streams.meta || {}}
              loading={loading > 0}
              onHandleChangePage={(page) => handleChangePage(page)}
              onHandleChangeRowsPerPage={(limit) =>
                handleChangeRowsPerPage(limit)
              }
            >
              {streams.data?.map((stream) => (
                <TableRow key={stream?.id} hover>
                  <TableCell>{stream?.name}</TableCell>
                  <TableCell>{stream?.segment}</TableCell>
                  <TableCell>{stream?.direction}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      color={stream?.video === null ? "secondary" : "primary"}
                      onClick={() =>
                        handleOpenVidStream(stream?.video, stream?.name)
                      }
                    >
                      <Icon>
                        {stream?.video !== null ? "videocam" : "videocamOff"}
                      </Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      color={stream?.video === null ? "secondary" : "primary"}
                      onClick={() =>
                        handleOpenLiveStream(stream?.live, stream?.name)
                      }
                    >
                      <Icon>
                        {stream?.video !== null ? "videocam" : "videocamOff"}
                      </Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Switch
                      onChange={(e) =>
                        handleChangeStatus(e.target.checked, stream?.id)
                      }
                      defaultChecked={stream?.is_active}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{dateDef(stream?.created_at)}</TableCell>
                  <TableCell>{dateDef(stream?.updated_at)}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={(event) => handleMoreOpen(event, stream?.id)}
                    >
                      <Icon>more_vert</Icon>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={moreOpen === stream?.id ? true : false}
                      onClose={handleCloseMore}
                    >
                      {moreItems.map((item) => (
                        <MenuItem
                          key={item}
                          onClick={() => clickMoreItem(item, stream)}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableData>
          </CardContent>
        </Card>
        {vidStreamOpen && (
          <VidStreamDialog
            vidStreamOpen={vidStreamOpen}
            currentVidStream={currentVidStream}
            handleCloseVidStreamDialog={handleCloseVidStreamDialog}
          />
        )}
        {liveStreamOpen && (
          <LiveStreamDialog
            handleCloseLiveStreamDialog={handleCloseLiveStreamDialog}
            liveStreamOpen={liveStreamOpen}
            currentLiveStream={currentLiveStream}
            useVideo={useVideo}
          />
        )}
        {openDialogCreate && (
          <Create
            open={openDialogCreate}
            handleClose={() => setOpenDialogCreate(false)}
            loading={false}
          />
        )}
        {openDialogEdit && (
          <Create
            open={openDialogEdit}
            streamEdit={streamEdit}
            handleClose={() => {
              setOpenDialogEdit(false);
              setStreamEdit(null);
            }}
            loading={false}
          />
        )}
        {openConfirmDelete && (
          <Confirm
            loading={deleteLoading}
            open={openConfirmDelete}
            content="Anda yakin ingin menghapus data ?"
            onAction={(res) => handleDelete(res)}
          />
        )}
      </Box>
    </div>
  );
}

export default Streams;
