import { FETCH_CREATE_USER, FETCH_CREATE_USER_SUCCESS, FETCH_CREATE_USER_FAILURE, FETCH_CREATE_USER_RESET } from "./createUserType"
import { StoreUserData } from "../../services"

const fetchCreateUserAction = () => {
    return {
        type: FETCH_CREATE_USER
    }
}

const fetchCreateUserSuccessAction = (user) => {
    return {
        type: FETCH_CREATE_USER_SUCCESS,
        payload: user
    }
}

const fetchCreateUserFailureAction = (error) => {
    return {
        type: FETCH_CREATE_USER_FAILURE,
        payload: error
    }
}

const fetchCreateUserResetAction = () => {
    return {
        type: FETCH_CREATE_USER_RESET
    }
}


export const resetCreateUser = () => {
    return dispatch => {
        dispatch(fetchCreateUserResetAction());
    }
}


export const fetchCreateUser = (userInfo) => {
    return dispatch => {
        dispatch(fetchCreateUserAction());
        const storeUser = new Promise( (resolve, reject) => {
            StoreUserData(userInfo).then( res => {
                const user = res.data;
                dispatch(fetchCreateUserSuccessAction(user));
                resolve(user);
            }, err => {
                const error = err.response.data;
                dispatch(fetchCreateUserFailureAction(error));
                reject(error);
            })
        });
        return storeUser;
    }
}