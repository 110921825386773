/**
 * 
 * Project Waskita Toll Road (WTR GO)
 * 
 * @author Biworo Manunggal
 * 
 */

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Icon, Typography, makeStyles, Menu, MenuItem, Divider, ListItemIcon, Avatar, ListItem, Button } from '@material-ui/core';
import theme from '../../../../config/theme';
import { onDrawerMobileOpen } from '../../../../config/redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import history from '../../../../config/history';

const styles = makeStyles({
    grow: {
        flex: 1
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${theme.custom.drawerWidth}px)`,
          marginLeft: theme.custom.drawerWidth,
        },
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
    },    
    rightMenu: {
        marginTop: 50,
        overflow: 'unset',
        minWidth: 'inherit',
        '&::before': {
            content: '"-"',
            clipPath: 'polygon(50% 60%, 0% 100%, 100% 100%)',
            heght: 20,
            right: 8,
            width: 20,
            position: 'absolute',
            top: -19,
            backgroundColor: 'white'
        }
    },
    profileMenu: {
        minWidth: 170
    },
    userProfileItem: {
        height: 70,
        backgroundColor: '#eee'
    },
    profileAction: {
        paddingTop: 15,
        paddingBottom: 15
    }
});

const MyAppBar = (props) => {

    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);


    const menuId = 'primary-search-account-menu';

    useEffect( () => {

    }, []);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        props.onDrawerMobileOpen()
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    return (
        <div>
            <AppBar 
                position="fixed" 
                className={classes.appBar}
                >
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <Icon>
                            menu
                        </Icon>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        { props.panelTitle }
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktor}>
                    {/* <IconButton aria-label="show 17 new notifications" color="inherit">
                        <Badge badgeContent={17} color="secondary">
                            <Icon>notifications</Icon>
                        </Badge>
                    </IconButton> */}
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        variant="contained"
                        >
                        { props.user.profile?.profile_picture ?
                            <Avatar
                                src={props.user.profile.profile_picture} />
                            :
                            <Icon>
                                account_circle
                            </Icon>
                        }
                    </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {/* Desktop Menu */}
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                className={classes.profileMenu}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                classes={{
                    paper: classes.rightMenu
                }}
            >
                <MenuItem 
                    onClick={handleMenuClose}
                    className={ classes.userProfileItem }
                >
                    <ListItemIcon>
                        <Avatar
                            src={props.user.profile.profile_picture || null}
                        >
                            <Icon>
                                account_circle
                            </Icon>
                        </Avatar>
                    </ListItemIcon>
                    <Typography 
                        variant="body2"
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        {props.user?.profile.full_name || ''}
                    </Typography>
                </MenuItem>
                <Divider/>
                <ListItem 
                    className={classes.profileAction}
                >
                    <div className={classes.grow} />
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={ () => history.push('/logout') }
                        startIcon={
                            <Icon>
                                power_settings_new
                            </Icon>
                        }
                    >
                        <Typography 
                            variant="body2"
                            style={{
                                textAlign: 'right',
                                width: '100%'
                            }}
                        >
                            Logout
                        </Typography>
                    </Button>
                </ListItem>
            </Menu>
        </div>
    );
};

const mapStateToProps = state => ({
    drawerOpen: state.drawer.drawerOpen,
    user: state.userLogin.currentUser,
    panelTitle: state.panelInfo.title
});

const mapDispatchToProps = dispatch => ({
    onDrawerMobileOpen: () => dispatch(onDrawerMobileOpen())
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MyAppBar);