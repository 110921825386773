/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { utils, writeFileXLSX } from "xlsx";
import { setPageLoading } from "../../../../config/redux";
import { GetLogList } from "../../../../config/services";

const useStyles = makeStyles((theme) => ({
  grow: {
    flex: 1,
  },
  select: {
    minWidth: 250,
  },
  cardRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  gridTitle: {
    marginBottom: 25,
  },
  ...theme.styles.panelCard,
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <Grid container>
      <Grid item xs={4}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
}

function Logs() {
  const classes = useStyles();
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    document.title = "Dashboard";
    fetchData({ type: filter });
  }, [filter, page, rowsPerPage]);

  const fetchData = async (params) => {
    dispatch(setPageLoading(true));

    try {
      const response = await GetLogList({
        page: page + 1,
        limit: rowsPerPage,
        ...params,
      });
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    setPage(0);
    setRowsPerPage(10);
    fetchData({ type: event.target.value });
  };

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetLogList({
        paginate: false,
      });
      const result = response.data.log_lists.map((log) => ({
        Tipe: log.user_id ? "Member" : "Guest",
        "User ID": log.user_id ?? "-",
        "IP Address": log.ip_address,
        Halaman: log.content,
        Diakses: moment(log.accessed_at, "YYYY-MM-DD HH:mm:ss").format(
          "HH:mm:ss - dddd, DD-MM-YYYY"
        ),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Log-Pengunjung.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Log Pengunjung</title>
      </Helmet>
      <Grid
        container
        spacing={3}
        className={classes.gridTitle}
        alignItems="flex-end"
      >
        <Grid className={classes.grow}>
          <Typography
            variant="h5"
            style={{ color: "#707070", margin: "10px 0px" }}
          >
            <b>Persentase pengunjung</b>
          </Typography>
        </Grid>
        <Grid item className={classes.select}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Filter Tipe</InputLabel>
            <Select
              label="Tipe"
              value={filter}
              onChange={handleChangeFilter}
              placeholder="Pilih Tipe"
            >
              <MenuItem value={null}>Semua Tipe</MenuItem>
              <MenuItem value={"guest"}>Guest</MenuItem>
              <MenuItem value={"member"}>Member</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "20px" }}
          onClick={handleExport}
          disabled={loadingExport}
        >
          Export Data
        </Button>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} r aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tipe</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Halaman</TableCell>
              <TableCell>Diakses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.log_lists?.data?.map((log) => (
              <TableRow
                key={log.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{log.user_id ? "Member" : "Guest"}</TableCell>
                <TableCell>{log.user_id ?? "-"}</TableCell>
                <TableCell>{log.ip_address}</TableCell>
                <TableCell>{log.content}</TableCell>
                <TableCell>
                  {moment(log.accessed_at, "YYYY-MM-DD HH:mm:ss").format(
                    "HH:mm:ss - dddd, DD-MM-YYYY"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={3}
                count={data.log_lists?.total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Logs;
