import history from '../../history';
import { LoginUser } from '../../services';
import {
	CLEAR_ERROR_MESSAGE,
	CLEAR_LOGIN_USER,
	FETCH_USER_LOGIN,
	FETCH_USER_LOGIN_FAILURE,
	FETCH_USER_LOGIN_SUCCESS,
} from './userLoginType';

const fetchUserLogin = () => {
	return {
		type: FETCH_USER_LOGIN,
	};
};

const fetchUserLoginSuccess = user => {
	return {
		type: FETCH_USER_LOGIN_SUCCESS,
		payload: user,
	};
};

const fetchUserLoginFailure = error => {
	return {
		type: FETCH_USER_LOGIN_FAILURE,
		payload: error,
	};
};

const clearLoginUser = () => {
	return {
		type: CLEAR_LOGIN_USER,
	};
};

const clearErrorMessageAction = () => {
	return {
		type: CLEAR_ERROR_MESSAGE,
	};
};

export const attempUserLogin = userInfo => {
	return dispatch => {
		dispatch(fetchUserLogin());
		LoginUser(userInfo).then(
			res => {
				const currentUser = res.data.user;
				const token = res.data.token;
				dispatch(fetchUserLoginSuccess({ user: currentUser, token: token }));
				localStorage.setItem('token', token);
				localStorage.setItem('user', JSON.stringify(currentUser));
				if (res.data.user.role.name === 'superadmin') {
					history.push('/panel/dashboard');
				} else if (res.data.user.role.name === 'admin-manage') {
					history.push('/panel/gates');
				} else if (res.data.user.role.name === 'admin-non-manage') {
					history.push('/panel/sliders');
				}
			},
			err => {
				console.log(err);
				dispatch(fetchUserLoginFailure(err.response.data));
			}
		);
	};
};

export const clearErrorMessage = () => {
	return dispatch => {
		dispatch(clearErrorMessageAction());
	};
};

export const clearLoginUserData = () => {
	return dispatch => {
		dispatch(clearLoginUser());
	};
};
