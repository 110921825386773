import {
    FETCH_MESSAGE,
    FETCH_MESSAGE_SUCCESS,
    FETCH_MESSAGE_FAILURE
} from './messageType';

const initialState = {
    loading: false,
    messages: '',
    error: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MESSAGE:
            return {
                ...state,
                loading: true
            }
        case FETCH_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: action.payload
            }
        case FETCH_MESSAGE_FAILURE:
            return {
                ...state,
                loading: false,
                messages: action.payload
            }
        default: return state;
    }
}

export default reducer;