import React from 'react';
import { makeStyles, LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';


const useStyles = makeStyles( theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        position: 'fixed',
        zIndex: 999999
    }
}));

const PageLoading = (props) => {
    const classes = useStyles();
    const { loading } = props;
    return (
        <div 
            className={classes.root}
        >
            <LinearProgress 
                hidden={!loading}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    loading: state.pageLoading.loading
});

export default connect(
    mapStateToProps
)(PageLoading);