import { ChangeStatusStreamData, GetStreamsData, StoreStreamData, UpdateStreamData } from '../../../config/services';
import { setPageLoading } from '../pageLoading/pageLoadingAction';
import {
	CHANGE_STATUS_STREAM,
	FETCH_STREAM,
	FETCH_STREAM_FAILURE,
	FETCH_STREAM_SUCCESS,
	STORE_STREAM,
	STORE_STREAM_FAILURE,
	STORE_STREAM_SUCCESS,
	UPDATE_STREAM,
	UPDATE_STREAM_FAILURE,
	UPDATE_STREAM_SUCCESS,
} from './streamType';

const fetchStreamAction = () => {
	return {
		type: FETCH_STREAM,
	};
};

const fetchStreamSuccessAction = streams => {
	return {
		type: FETCH_STREAM_SUCCESS,
		payload: streams,
	};
};

const fetchStreamFailureAction = error => {
	return {
		type: FETCH_STREAM_FAILURE,
		payload: error,
	};
};

export const fetchStream = params => {
	return dispatch => {
		dispatch(fetchStreamAction());
		setPageLoading(true);
		GetStreamsData(params).then(
			res => {
				dispatch(fetchStreamSuccessAction(res));
				setPageLoading(false);
			},
			err => {
				dispatch(fetchStreamFailureAction(err.response.data));
			}
		);
	};
};

const streamStoreAction = () => {
	return {
		type: STORE_STREAM,
	};
};

const streamStoreSuccessAction = stream => {
	return {
		type: STORE_STREAM_SUCCESS,
		payload: stream,
	};
};

const streamStoreFailureAction = error => {
	return {
		type: STORE_STREAM_FAILURE,
		payload: error,
	};
};

export const streamStore = params => {
	return dispatch => {
		dispatch(streamStoreAction());
		const stream = new Promise((resolve, reject) => {
			StoreStreamData(params).then(
				res => {
					dispatch(streamStoreSuccessAction(res));
					resolve(res);
				},
				err => {
					dispatch(streamStoreFailureAction(err.response.data));
					reject(err.response.data);
				}
			);
		});
		return stream;
	};
};

const streamUpdateAction = () => {
	return {
		type: UPDATE_STREAM,
	};
};

const streamUpdateSuccessAction = stream => {
	return {
		type: UPDATE_STREAM_SUCCESS,
		payload: stream,
	};
};

const streamUpdateFailureAction = error => {
	return {
		type: UPDATE_STREAM_FAILURE,
		payload: error,
	};
};


export const updateStream = params => {
	return dispatch => {
		dispatch(streamUpdateAction());
		const stream = new Promise((resolve, reject) => {
			UpdateStreamData(params).then(
				res => {
					dispatch(streamUpdateSuccessAction(res));
					resolve(res);
				},
				err => {
					dispatch(streamUpdateFailureAction(err.response.data));
					resolve(err.response.data);
				}
			);
		});
		return stream;
	};
};
