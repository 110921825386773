import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StoreSlidersData, UpdateSlidersData } from '../../../../config/services';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import ChooseImage from '../../../components/Forms/ChooseImage';
import MySnackbar from '../../../components/Snackbar';

function FormDialog({ currentEdit, openFormDialog, fetchData, setOpenFormDialog, setCurrentEdit }) {
	const isEdit = Object.keys(currentEdit).length ? true : false;
	const [slide, setSlide] = useState({});
	const [frmErr, setFrmErr] = useState([]);
	const [snackBar, setSnackBar] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		initSlider();
	}, []);

	const initSlider = () => {
		if (isEdit) {
			setSlide({
				id: currentEdit.id,
				title: currentEdit.title,
				weather: currentEdit.weather,
				bmkg_filename: currentEdit.bmkg_filename,
				city: currentEdit.city,
			});
		}
	};

	const handleSubmit = async event => {
		const formData = new FormData();
		Object.keys(slide).map(item => {
			return formData.append(item, slide[item]);
		});
		setLoading(true);

		if (!isEdit) {
			try {
				const res = await StoreSlidersData(formData);
				setSnackBar({
					open: true,
					variant: 'success',
					message: res.data.message,
				});
				setSlide({});
				fetchData();
			} catch (err) {
				console.log(err);
				setSnackBar({
					open: true,
					variant: 'error',
					message: err.response.data.message,
				});
				setFrmErr(err.response.data);
			} finally {
				setLoading(false);
			}
		} else {
			try {
				formData.append('_method', 'put');

				const res = await UpdateSlidersData(formData);
				setSnackBar({
					open: true,
					variant: 'success',
					message: res.data.message,
				});
				fetchData();
			} catch (err) {
				setSnackBar({
					open: true,
					variant: 'error',
					message: err.response.data.message,
				});
				setFrmErr(err.response.data);
			} finally {
				setLoading(false);
			}
		}
	};

	const handleChange = event => {
		const newSlide = { ...slide };
		if (event.target.name === 'img') {
			newSlide[event.target.name] = event.target.files[0];
		} else {
			newSlide[event.target.name] = event.target.value;
		}
		setSlide(newSlide);
	};

	const handleCloseSnackBar = () => {
		setSnackBar({
			open: false,
			variant: 'info',
			message: '',
		});
	};

	return (
		<>
			<MySnackbar
				open={snackBar.open}
				variant={snackBar.variant}
				message={snackBar.message}
				onClose={handleCloseSnackBar}
			/>
			<Dialog
				open={openFormDialog}
				fullWidth={true}
				maxWidth={'sm'}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					onClose={true}
					onHandleClose={() => {
						setOpenFormDialog(false);
						setCurrentEdit({});
					}}
				>
					{isEdit ? 'Edit Sliders' : 'Tambah Sliders'}
				</DialogTitle>
				<DialogContent>
					<div>
						<div
							style={{
								width: '70%',
								margin: '0 auto',
							}}
						>
							<ChooseImage
								name="img"
								label="Image Slider"
								value=""
								id="image-slider"
								src={currentEdit?.img || undefined}
								onChange={handleChange}
							/>
						</div>
						<Grid container>
							<Grid xs={12} item>
								<TextField
									fullWidth
									label="Judul Slider"
									name="title"
									type="text"
									value={slide?.title ?? ''}
									error={frmErr?.errors?.title}
									helperText={frmErr?.errors?.title}
									onChange={handleChange}
								/>
							</Grid>
							<Grid xs={12} item style={{ marginTop: '20px' }}>
								<FormControlLabel
									control={
										<Switch
											defaultChecked={slide.weather}
											onChange={() => setSlide({ ...slide, weather: !slide.weather })}
											size="medium"
											color="primary"
										/>
									}
									label="Aktifkan Cuaca"
								/>
							</Grid>
							{slide.weather && (
								<>
									<Grid xs={12} item>
										<TextField
											fullWidth
											label="Nama File BMKG"
											name="bmkg_filename"
											type="text"
											value={slide?.bmkg_filename ?? ''}
											error={frmErr?.errors?.bmkg_filename}
											helperText={frmErr?.errors?.bmkg_filename}
											onChange={handleChange}
										/>
									</Grid>
									<Grid xs={12} item style={{ marginTop: '10px' }}>
										<TextField
											fullWidth
											label={'Nama Kota'}
											name="city"
											type="text"
											value={slide?.city ?? ''}
											error={frmErr?.errors?.city}
											helperText={
												<div>
													<div>{frmErr?.errors?.city}</div>
													<div>
														Nama Kota (berdasarkan deskripsi dari file pada{' '}
														<a href="https://data.bmkg.go.id/prakiraan-cuaca/">ini</a>)
													</div>
												</div>
											}
											onChange={handleChange}
										/>
									</Grid>
								</>
							)}
						</Grid>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="primary" size="small" onClick={handleSubmit} disabled={loading}>
						{loading ? <CircularProgress size={20} /> : isEdit ? 'Update' : 'Simpan'}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default FormDialog;
