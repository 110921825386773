import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { createRef } from 'react';
import DialogTitle from '../../../../components/Dialog/DialogTitle';
import FormRestArea from '../FormRestArea';

function DialogCreate({
	openDialogCreate,
	setOpenDialogCreate,
	currentEdit,
	setCurrentEdit,
	submitLoading,
	setSubmitLoading,
	fetchRestArea,
	segments,
}) {
	const isEdit = !isEmpty(currentEdit);
	const submitButtonRef = createRef();

	const handleClose = () => {
		setOpenDialogCreate(false);
		setCurrentEdit({});
	};

	return (
		<Dialog
			open={openDialogCreate}
			fullWidth={true}
			maxWidth={'md'}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle onClose={true} onHandleClose={handleClose}>
				{isEdit ? 'Edit Rest Area' : 'Tambah Rest Area'}
			</DialogTitle>
			<DialogContent>
				<FormRestArea
					restArea={currentEdit}
					segments={segments}
					isEdit={isEdit}
					submitRef={submitButtonRef}
					onLoading={loading => {
						setSubmitLoading(loading);
					}}
					onSubmitSuccess={() => {
						fetchRestArea();
					}}
					handleClose={handleClose}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => {
						submitButtonRef.current.click();
					}}
					disabled={submitLoading}
				>
					{submitLoading ? <CircularProgress size={20} /> : isEdit ? 'Update' : 'Simpan'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DialogCreate;
