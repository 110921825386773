export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';

export const STORE_POSTS = 'STORE_POSTS';
export const STORE_POSTS_SUCCESS = 'STORE_POSTS_SUCCESS';
export const STORE_POSTS_FAILURE = 'STORE_POSTS_FAILURE';

export const UPDATE_POSTS = 'UPDATE_POSTS';
export const UPDATE_POSTS_SUCCESS = 'UPDATE_POSTS_SUCCESS';
export const UPDATE_POSTS_FAILURE = 'UPDATE_POSTS_FAILURE';