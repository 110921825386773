import { FETCH_ROLE_LISTS, FETCH_ROLE_LISTS_SUCCESS } from "./roleType"
import {GetRoleLists} from '../../services/index';

const fetchRoleListsAction = () => {
    return {
        type: FETCH_ROLE_LISTS
    }
}

const fetchRoleListsSuccessAction = (roles) => {
    return {
        type: FETCH_ROLE_LISTS_SUCCESS,
        payload: roles
    }
}

const fetchRoleListsFailureAction = (error) => {
    return {
        type: FETCH_ROLE_LISTS,
        payload: error
    }
}

export const fetchRoleLists = () => {
    return dispatch => {
        dispatch(fetchRoleListsAction());
        GetRoleLists().then( res => {
            const roles = res.data;
            dispatch(fetchRoleListsSuccessAction(roles));
        }, err => {
            const error = err.response.data;
            dispatch(fetchRoleListsFailureAction(error));
        })
    }
}