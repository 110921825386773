import {
    SET_PANEL_TITLE
} from './panelInfoType';

const initialState = {
    title: ''
}

const reducer = (state = initialState, action) => {
    switch( action.type ) {
        case SET_PANEL_TITLE:
            return {
                ...state,
                title: action.payload
            }
        default: return state;
    }
}

export default reducer;