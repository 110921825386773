import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { dateDef } from "../../../../config/datetime";
import DialogTitle from "../../../components/Dialog/DialogTitle";

const useStyles = makeStyles({
  header: {
    backgroundColor: "#f1f1f1",
    "& .MuiListItemText-multiline": {
      alignSelf: "center",
    },
  },
  listItem: {
    display: "flex",
    "&:nth-child(odd)": {
      backgroundColor: "#f1f1f1",
    },
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7",
    },
    "& .MuiListItemText-root": {
      flex: "none",
    },
  },
  value: {
    flex: 1,
    fontSize: "15px",
    "& .MuiTypography-root": {
      fontSize: ".9em !important",
    },
  },
  label: {
    width: "29%",
    color: "#424242",
    fontWeight: "bold",
    "& .MuiTypography-root": {
      fontSize: ".9em !important",
    },
  },
  doted: {
    width: 10,
  },
  listItemText: {
    flex: 0,
  },
});

function View({ open, viewSegmentGate, setViewSegmentGate }) {
  const classes = useStyles();
  const handleClose = () => {
    setViewSegmentGate({});
  };
  return (
    <Dialog open={open} scroll="body" fullWidth maxWidth="md">
      <DialogTitle onHandleClose={handleClose}>Detail Gerbang Toll</DialogTitle>
      <DialogContent>
        <List>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>ID</ListItemText>
            <ListItemText className={classes.value}>
              {viewSegmentGate.id}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Nama Gerbang</ListItemText>
            <ListItemText className={classes.value}>
              {viewSegmentGate.name}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Deskripsi</ListItemText>
            <ListItemText className={classes.value}>
              {viewSegmentGate.description}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Ruas Toll</ListItemText>
            <ListItemText className={classes.value}>
              {viewSegmentGate.segment?.name}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Dibuat Oleh</ListItemText>
            <ListItemText className={classes.value}>
              {viewSegmentGate.created_by_user?.username}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Dibuat Pada</ListItemText>
            <ListItemText className={classes.value}>
              {dateDef(viewSegmentGate.created_at)}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>
              Perubahan Terakhir
            </ListItemText>
            <ListItemText className={classes.value}>
              {dateDef(viewSegmentGate.updated_at)}
            </ListItemText>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default View;
