import {
    SET_PANEL_TITLE
} from './panelInfoType';

const setPanelTitleAction = (title) => {
    return {
        type: SET_PANEL_TITLE,
        payload: title
    }
}

export const setPanelTitle = (title) => {
    return dispatch => {
        dispatch(setPanelTitleAction(title));
    }
}