import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { UpdateCallCenterData } from "../../../../config/services";
import DialogTitle from "../../../components/Dialog/DialogTitle";
import MySnackbar from "../../../components/Snackbar";
import TextField from "../../../components/TextField";

const initalState = {
  title: "",
  phone: "",
};

function Edit({ open, setCallCenter, callCenter, fetchCallCenter }) {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({});
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState(initalState);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const response = await UpdateCallCenterData(input, callCenter.id);
      fetchCallCenter();
      setSnackbar({
        open: true,
        message: response.data.info,
        variant: "success",
      });
      setErrors({});
    } catch (error) {
      console.log(error);
      setErrors(error.response.data);
      setSnackbar({
        open: true,
        message: error.response?.data.message ?? "Gagal Mengupdate data",
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({});
  };

  const handleChange = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setCallCenter({});
  };

  useEffect(() => {
    setInput({
      title: callCenter.title,
      phone: callCenter.phone,
    });
  }, [callCenter]);

  return (
    <>
      <MySnackbar
        open={snackbar?.open}
        onClose={handleCloseSnackbar}
        message={snackbar?.message || ""}
        variant={snackbar.variant || "info"}
      />
      <Dialog fullWidth maxWidth="sm" open={open} scroll="body">
        <DialogTitle onHandleClose={handleClose}>
          Edit Data Call Center
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              paddingTop: 20,
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <TextField
                  fullWidth
                  label="Nama Call Center"
                  name="title"
                  type="text"
                  value={input.title ?? ""}
                  error={errors?.errors?.title}
                  helperText={errors?.errors?.title}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Nomor Handphone"
                  name="phone"
                  type="text"
                  value={input.phone ?? ""}
                  error={errors?.errors?.phone}
                  helperText={errors?.errors?.phone}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpdate}
            variant="contained"
            style={{
              width: 120,
            }}
            disabled={loading}
            color="primary"
          >
            {loading ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              `Update`
            )}
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Edit;
