import axios from 'axios'
import {defaultRoot, axiosHeader} from './config'

export const Post = (path, data, rootPath = defaultRoot) => {
    
    const promise = new Promise((resolve, reject) => {
        axiosHeader()
        axios.post(`${rootPath}/${path}`, data).then(res => {
            return resolve(res)
        }, err => {
            return reject(err)
        })
    });

    return promise
}

export const Put = (path, data, rootPath = defaultRoot) => {
    
    const promise = new Promise((resolve, reject) => {
        axiosHeader()
        axios.put(`${rootPath}/${path}`, data).then(res => {
            return resolve(res)
        }, err => {
            return reject(err)
        })
    });

    return promise
}

export const Get = (path, params = {}, rootPath = defaultRoot) => {
    const promise = new Promise(( resolve, reject) => {
        axiosHeader()
        axios.get(`${rootPath}/${path}`, {
            params: params
        }).then(res => {
            return resolve(res.data);
        }, err => {
            return reject(err);
        });
    });
    return promise
}

export const Delete = (path, data, rootPath = defaultRoot) => {
    
    const promise = new Promise((resolve, reject) => {
        axiosHeader()
        axios.delete(`${rootPath}/${path}`, data).then(res => {
            return resolve(res)
        }, err => {
            return reject(err)
        })
    });

    return promise
}