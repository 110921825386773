import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	makeStyles,
	TableCell,
	TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { dateDef } from '../../../../config/datetime';
import { setPanelTitle } from '../../../../config/redux';
import { DeleteFacilitiesData, GetFacilitiesData } from '../../../../config/services';
import Confirm from '../../../components/Confirm/Confirm';
import FormSearchPanel from '../../../components/Forms/FormSearchPanel';
import TableData from '../../../components/Panel/Table';
import DialogForm from './DialogForm';

import '../../../../config/app/';
export const defaultRoot = global.api_url_i;

const useStyles = makeStyles(theme => ({
	grow: {
		flex: 1,
	},
	cardRoot: {
		marginTop: 25,
		marginBottom: 25,
	},
	...theme.styles.panelCard,
}));

const columns = [
	{
		id: 'id',
		label: 'ID',
	},
	{
		id: 'name',
		label: 'Nama',
		minWidth: 200,
	},
	{
		id: 'image',
		label: 'Gambar Icon',
		minWidth: 200,
	},
	{
		id: 'created_at',
		label: 'Dibuat',
		minWidth: 200,
	},
	{
		id: 'action',
		label: 'Action',
		minWidth: 200,
	},
];

function Facility() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [createDialog, setCreateDialog] = useState(false);
	const [search, setSearch] = useState('');
	const [data, setData] = useState({});
	const [edit, setEdit] = useState({});
	const [openConfirmDelete, setOpenConfirmDelete] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const handleChangeSearch = event => {
		setSearch(event.target.value);
	};

	const fetchFacility = async params => {
		setLoading(true);
		try {
			const response = await GetFacilitiesData(params);
			setData(response.data.facilities);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const onSubmitSearch = event => {
		if (event.nativeEvent.key === 'Enter') {
			fetchFacility({ q: search });
		}
	};

	const clearSearchInput = () => {
		if (search) {
			setSearch('');
			fetchFacility();
		}
	};

	const handleChangePage = page => {
		fetchFacility({ page: page, limit: data.meta.per_page });
	};

	const handleChangeRowsPerPage = limit => {
		fetchFacility({ limit: limit });
	};

	useEffect(() => {
		dispatch(setPanelTitle('Fasilitas'));
		fetchFacility();
	}, []);

	const handleDelete = async type => {
		if (type) {
			setDeleteLoading(true);
			try {
				const response = await DeleteFacilitiesData(openConfirmDelete);
				fetchFacility();
				setOpenConfirmDelete(null);
			} catch (error) {
				console.log(error);
			} finally {
				setDeleteLoading(false);
			}
		} else {
			setOpenConfirmDelete(null);
		}
	};

	const dialogCreateOpen = () => {
		setCreateDialog(true);
	};

	return (
		<div>
			<Helmet>
				<title>Fasilitas</title>
			</Helmet>
			<Grid container spacing={1}>
				<Grid item className={classes.grow}></Grid>
				<Grid item>
					<FormSearchPanel
						value={search}
						onChange={handleChangeSearch}
						onKeyPress={onSubmitSearch}
						clearSearchInput={clearSearchInput}
					/>
				</Grid>
				<Grid item>
					<Button variant="contained" color="default" onClick={dialogCreateOpen}>
						Buat Fasilitas Baru
					</Button>
				</Grid>
			</Grid>
			<Card className={classes.cardRoot}>
				<CardHeader
					title="Data Fasilitas"
					titleTypographyProps={{
						className: classes.cardTitle,
					}}
				/>
				<Divider />
				<CardContent className={classes.cardContent}>
					<TableData
						columns={columns}
						meta={{ ...data.meta, ...data.links }}
						loading={loading > 0}
						onHandleChangePage={page => handleChangePage(page)}
						onHandleChangeRowsPerPage={limit => handleChangeRowsPerPage(limit)}
					>
						{data.data?.map(facility => (
							<TableRow key={facility.id} hover>
								<TableCell>{facility.id}</TableCell>
								<TableCell>{facility.name}</TableCell>
								<TableCell>
									<img src={`${defaultRoot}${facility.icon}`} style={{ width: '100px' }} alt="" />
								</TableCell>
								<TableCell>{dateDef(facility.created_at)}</TableCell>
								<TableCell>
									<Button onClick={() => setEdit(facility)} variant="contained" color="primary">
										Edit
									</Button>
									<Button
										onClick={() => setOpenConfirmDelete(facility.id)}
										style={{ marginLeft: 10 }}
										variant="contained"
										color="secondary"
										startIcon={<DeleteIcon />}
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableData>
				</CardContent>
			</Card>
			{(!isEmpty(edit) || createDialog) && (
				<DialogForm
					edit={edit}
					setEdit={setEdit}
					createDialog={createDialog}
					setCreateDialog={setCreateDialog}
					fetchFacility={fetchFacility}
				/>
			)}

			{openConfirmDelete && (
				<Confirm
					loading={deleteLoading}
					open={openConfirmDelete}
					content="Anda yakin ingin menghapus data ?"
					onAction={type => handleDelete(type)}
				/>
			)}
		</div>
	);
}

export default Facility;
