import React, { Component } from 'react';
import { setPanelTitle } from '../../config/redux';
import { connect } from 'react-redux';

const withPanelInfo = (matchProps) => (WrappedComponent) => {

    class PanelInfo extends Component {

        componentDidMount() {
            this.props.setPanelTitle(matchProps.title);
            document.title = matchProps.title;
        }

        render() {
            return (
                <div>
                    <WrappedComponent />
                </div>
            );
        }
    }

    const mapDispatchToProps = dispatch => ({
        setPanelTitle: (value) => dispatch(setPanelTitle(value))
    })

    return connect(
        null,
        mapDispatchToProps
    )(PanelInfo);

}

export default withPanelInfo;