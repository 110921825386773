import {
    FETCH_PANEL_MENU,
    FETCH_PANEL_MENU_SUCCESS,
    FETCH_PANEL_MENU_FAILURE
} from './panelMenuType'
import { PanelMenus } from '../../services'

const fetchPanelMenu = () => {
    return {
        type: FETCH_PANEL_MENU
    }
}

const fetchPanelMenuSuccess = (menus) => {
    return {
        type: FETCH_PANEL_MENU_SUCCESS,
        payload: menus
    }
}

const fetchPanelMenuFailure = (error) => {
    return {
        type: FETCH_PANEL_MENU_FAILURE,
        payload: error
    }
}

export const fetchPanelMenuData = () => {
    return dispatch => {
        dispatch(fetchPanelMenu());
        PanelMenus().then( res => {
            const menus = res.data;
            dispatch(fetchPanelMenuSuccess(menus));
        }, err => {
            const error = err.response.data;
            dispatch(fetchPanelMenuFailure(error));
        })
    }
}