import React, { Component } from 'react';
import { 
    Typography, 
    Card } from '@material-ui/core';
import { AuthFooter, AuthHeader } from '../../../components/Auth';
import FormLogin from '../../../components/Forms/FormLogin'
import { TextUri } from '../../../components/Uri';

class Login extends Component {
    
    render() {

        return (
            <div>

                <Card>
                    <AuthHeader
                        title="Login"
                    />
                    <FormLogin />
                </Card>
                
                
                <AuthFooter>
                    <TextUri
                        linkProps={{
                            to: '/forgot-password'
                        }}
                        typographyProps={{
                            variant: 'body1'
                        }}
                    >
                        Lupa password ?
                    </TextUri>
                </AuthFooter>
                
            </div>
        );
    }
}

export default Login;