import React from 'react';
import { makeStyles, Box, Grid, Typography, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const styles = makeStyles( () => ({
    root: {
        margin: '2rem 0'
    }
}));

const index = (props) => {
    const classes = styles();
    const {meta, onChangePage, onChangeLimit} = props;
    return (
        <Box className={classes.root}>
            <Grid container spacing={2} justify="flex-end" alignItems="center">
                <Grid item>
                    <Typography variant="body2">
                        Baris Per Halaman :
                        <Select
                            native
                            value={meta.per_page  }
                            style={{
                                marginLeft: 10
                            }}
                            onChange={onChangeLimit}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </Select> 
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2">
                        Menampilkan {meta.from} - {meta.to} dari {meta.total} Data
                    </Typography>
                </Grid>
                <Grid item>
                    <Pagination 
                        count={meta.last_page} 
                        page={meta.current_page}
                        color="primary"
                        onChange={onChangePage}
                        size="small"
                    />
                </Grid>
            </Grid>
        </Box>    
    );
};

export default index;