import {
    FETCH_REGISTER,
    FETCH_REGISTER_SUCCESS,
    FETCH_REGISTER_FAILURE,
} from './userRegisterType';

const initialState = {
    loading: false,
    currentUser: {},
    error: [],
    registerSuccess: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_REGISTER:
            return {
                ...state,
                loading: true,
                error: {},
                registerSuccess: false
            }
        case FETCH_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                error: {},
                registerSuccess: true
            }
        case FETCH_REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                registerSuccess: false
            }
        default: return state
    }
}

export default reducer;