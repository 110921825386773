import { FETCH_GATES, FETCH_GATES_SUCCESS, FETCH_GATES_FAILURE, STORE_GATE, STORE_GATE_SUCCESS, STORE_GATE_FAILURE, UNSET_ERROR, UPDATE_GATE, UPDATE_GATE_SUCCESS, UPDATE_GATE_FAILURE } from "./gateType"

const initialState = {
    gates: [],
    gatesError: {},
    gatesLoading: true,
    storeGate: {},
    storeGateLoading: false,
    storeGateError: {},
    gatesUpdate: {},
    gatesUpdateLoading: false,
    gatesUpdateError: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_GATES:
            return {
                ...state,
                gatesLoading: true
            }
        case FETCH_GATES_SUCCESS:
            return {
                ...state,
                gatesLoading: false,
                gates: action.payload
            }
        case FETCH_GATES_FAILURE:
            return {
                ...state,
                gatesLoading: false,
                gatesError: action.payload
            }
        case STORE_GATE: 
            return {
                ...state,
                storeGateLoading: true
            }
        case STORE_GATE_SUCCESS: 
            return {
                ...state,
                storeGateLoading: false,
                storeGate: action.payload
            }
        case STORE_GATE_FAILURE: 
            return {
                ...state,
                storeGateLoading: false,
                storeGateError: action.payload
            }
        case UNSET_ERROR:
            return {
                ...state,
                storeGateError: {}
            }
        case UPDATE_GATE:
            return {
                ...state,
                gatesUpdateLoading: true
            }
        case UPDATE_GATE_SUCCESS:
            return {
                ...state,
                gatesUpdateLoading: false,
                gatesUpdate: action.payload
            }
        case UPDATE_GATE_FAILURE:
            return {
                ...state,
                gatesUpdateLoading: false,
                storeGateError: action.payload
            }
        default: return state;
    }
}

export default reducer;