import axios from 'axios';
import '../../config/app';
/**
 * 
 * Set default path and another path API
 * 
 */

export const defaultRoot = global.api_url;
export const axiosHeader = () => {
    axios.interceptors.request.use(function (requestConfig) {
        if (requestConfig.url.indexOf(defaultRoot) > -1) {
            const token = localStorage.getItem('token') ?? '';
            requestConfig.headers['Authorization'] = `Bearer ${token}`;
        }
        return requestConfig;
    }, function (error) {
        return Promise.reject(error);
    });
}