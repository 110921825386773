import { FETCH_CREATE_USER, FETCH_CREATE_USER_SUCCESS, FETCH_CREATE_USER_FAILURE, FETCH_CREATE_USER_RESET } from "./createUserType"

const initialState = {
    loading: false,
    user: {},
    error: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_CREATE_USER: 
            return {
                ...state,
                loading: true,
                error: false
            }
        case FETCH_CREATE_USER_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: false,
                user: action.payload
            }
        case FETCH_CREATE_USER_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case FETCH_CREATE_USER_RESET:
            return {
                ...state,
                loading: false,
                error: {},
                user: {}
            }
        default: return state;
    }
}

export default reducer;