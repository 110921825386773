import { Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import { Player } from 'video-react';
import DialogTitle from '../../../../components/Dialog/DialogTitle';

function CctvDialog({ openCctvDialog, currentCctv, setOpenCctvDialog, setCurrentCctv }) {
	return (
		<Dialog
			open={openCctvDialog}
			fullWidth={true}
			maxWidth={'sm'}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle
				onClose={true}
				onHandleClose={() => {
					setOpenCctvDialog(false);
					setCurrentCctv({});
				}}
			>
				CCTV {currentCctv.name ? ' - ' + currentCctv.name : ''}
			</DialogTitle>
			<DialogContent>
				<Player poster="http://wtrgo.loc/img/logo.png" src={currentCctv.stream} />
			</DialogContent>
		</Dialog>
	);
}

export default CctvDialog;
