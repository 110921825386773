import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import {
  GetFacilitiesLists,
  StoreRestAreaData,
  UpdateRestAreaData,
} from "../../../../config/services";
import ChooseImage from "../../../components/Forms/ChooseImage";
import MySnackbar from "../../../components/Snackbar";
import TextField from "../../../components/TextField";

const styles = makeStyles(() => ({
  chooseImage: {
    // maxWidth: '70%',
    margin: "0 auto",
  },
}));

const FormRestArea = ({
  restArea,
  isEdit,
  submitRef,
  onLoading,
  onSubmitSuccess,
  segments,
  handleClose,
}) => {
  const classes = styles();
  const [input, setInput] = useState({});
  const [facs, setFacs] = useState([]);
  const [errField, setErrField] = useState({});
  const [snackBar, setSnackBar] = useState({});
  useEffect(() => {
    setFacilities();
    initRestArea();
  }, []);

  const initRestArea = () => {
    let newFacility = [];
    let newCctvs = [];
    let newDetails = [];
    if (isEdit) {
      newFacility = restArea.facilities.map((facility) =>
        JSON.stringify({ id: facility.id, name: facility.facility })
      );
      newDetails = restArea.details.map((detail) => {
        let itemDetail = {
          facility: detail.facility,
          total: detail.total,
        };
        return itemDetail;
      });
      newCctvs = restArea.cctvs.map((cctv) => {
        let itemCctv = {
          name: cctv.name,
          stream: cctv.stream,
        };
        return itemCctv;
      });
      setInput({
        id: restArea.id,
        title: restArea.title,
        km: restArea.km,
        lat: restArea.lat,
        lng: restArea.lng,
        segment_id: restArea.segment_id,
        image: restArea.image,
        cctvs: newCctvs,
        facilities: newFacility,
        details: newDetails,
      });
    } else {
      setInput({
        cctvs: newCctvs,
        facilities: newFacility,
        details: newDetails,
      });
    }
  };
  const setFacilities = async () => {
    const response = await GetFacilitiesLists();
    setFacs(response.data.facilities);
  };

  const handleChange = (event) => {
    const newRestArea = { ...input };
    if (event.target.name === "image") {
      newRestArea[event.target.name] = event.target.files[0];
    } else {
      newRestArea[event.target.name] = event.target.value;
    }
    setInput(newRestArea);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onLoading(true);
    let formData = new FormData();
    Object.keys(input).map((item) => {
      if (item === "facilities") {
        const parsedFacilities = input.facilities.map((facility) => {
          const { id } = JSON.parse(facility);
          return id;
        });
        return formData.append(item, JSON.stringify(parsedFacilities));
      } else if (item == "details") {
        return formData.append(item, JSON.stringify(input[item]));
      } else if (item === "cctvs") {
        return formData.append(item, JSON.stringify(input[item]));
      } else {
        return formData.append(item, input[item]);
      }
    });
    if (isEdit) {
      formData.append("_method", "put");
      UpdateRestAreaData(formData)
        .then(
          (res) => {
            setSnackBar({
              open: true,
              message: res.data.message,
              variant: "success",
            });
            setErrField({});
            onSubmitSuccess();
            handleClose();
          },
          (err) => {
            setSnackBar({
              open: true,
              message: err.response.data.message,
              variant: "error",
            });
            setErrField(err.response.data);
          }
        )
        .finally(() => {
          onLoading(false);
        });
    } else {
      StoreRestAreaData(formData)
        .then(
          (res) => {
            setSnackBar({
              open: true,
              variant: "success",
              message: res.data.message,
            });
            setInput({
              details: [],
              cctvs: [],
              facilities: [],
            });
            setErrField({});
            onSubmitSuccess();
            handleClose();
          },
          (err) => {
            setSnackBar({
              open: true,
              message: err.response.data.message,
              variant: "error",
            });
            setErrField(err.response.data);
          }
        )
        .finally(() => {
          onLoading(false);
        });
    }
  };

  const handleCctvChange = (event, index) => {
    const newRestArea = { ...input };
    newRestArea["cctvs"][index][event.target.name] = event.target.value;
    setInput(newRestArea);
  };

  const handleDetailChange = (event, index) => {
    const newRestArea = { ...input };
    newRestArea["details"][index][event.target.name] = event.target.value;
    setInput(newRestArea);
  };

  const handleCloseSnackBar = () => {
    setSnackBar({
      open: false,
      message: "",
      variant: "info",
    });
  };

  return (
    !isEmpty(input) && (
      <Fragment>
        <MySnackbar
          open={snackBar?.open}
          onClose={handleCloseSnackBar}
          message={snackBar?.message || ""}
          variant={snackBar.variant || "info"}
        />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <div className={classes.chooseImage}>
                <ChooseImage
                  name="image"
                  label="Image Rest Area"
                  value=""
                  id="image-rest-area"
                  src={input.image}
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Judul Rest Area"
                        name="title"
                        type="text"
                        value={input?.title ?? ""}
                        error={errField?.errors?.title}
                        helperText={errField?.errors?.title}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Km"
                        name="km"
                        type="text"
                        value={input?.km ?? ""}
                        error={errField?.errors?.km}
                        helperText={errField?.errors?.km}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Latitude"
                        name="lat"
                        type="text"
                        value={input?.lat ?? ""}
                        error={errField?.errors?.lat}
                        helperText={errField?.errors?.lat}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Longitude"
                        name="lng"
                        type="text"
                        value={input.lng ?? ""}
                        error={errField?.errors?.lng}
                        helperText={errField?.errors?.lng}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-mutiple-chip-label">
                          Fasilitas
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          name="facilities"
                          onChange={handleChange}
                          input={<Input id="select-multiple-chip" />}
                          value={input?.facilities || []}
                          // error={ error?.errors?.name }
                          // helperText={ error?.errors?.name }
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value, index) => {
                                const { name } = JSON.parse(value);
                                return <Chip key={index} label={name} />;
                              })}
                            </div>
                          )}
                        >
                          {facs.map((fac, index) => (
                            <MenuItem
                              key={index}
                              value={JSON.stringify({
                                id: fac.id,
                                name: fac.name,
                              })}
                            >
                              {fac.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={errField?.errors?.segment_id}
                        fullWidth
                      >
                        <InputLabel>Ruas Toll</InputLabel>
                        <Select
                          fullWidth
                          label="Ruas Toll"
                          name="segment_id"
                          value={input.segment_id}
                          error={errField?.errors?.segment_id}
                          onChange={handleChange}
                          placeholder="Pilih Ruas Toll"
                        >
                          {segments.map((segment) => (
                            <MenuItem value={segment.id}>
                              {segment.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errField?.errors?.segment_id && (
                          <FormHelperText>
                            {errField?.errors?.segment_id}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Detail Rest Area */}
          <Card
            style={{
              marginBottom: "2rem",
            }}
          >
            <CardHeader title="Detail Rest Area" />
            <Divider />
            <CardContent>
              {input?.details === undefined ? (
                <Typography variant="body" component="p">
                  Data Detail Kosong
                </Typography>
              ) : (
                input.details.map((detail, index) => (
                  <Fragment key={`${detail}~${index}`}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Nama Fasilitas"
                            name="facility"
                            type="text"
                            value={detail?.facility ?? ""}
                            // error={ errField?.errors?.lng }
                            // helperText={ errField?.errors?.lng }
                            onChange={(event) =>
                              handleDetailChange(event, index)
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Total Tersedia"
                            name="total"
                            type="text"
                            value={detail?.total ?? ""}
                            // error={ errField?.errors?.lng }
                            // helperText={ errField?.errors?.lng }
                            onChange={(event) =>
                              handleDetailChange(event, index)
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="small"
                            color="primary"
                            onClick={() => {
                              const newRestArea = { ...input };
                              newRestArea.details.splice(index, 1);
                              setInput(newRestArea);
                            }}
                            style={{
                              color: "red",
                              marginTop: 20,
                            }}
                          >
                            Hapus
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Fragment>
                ))
              )}
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  const newRestArea = { ...input };
                  let itemDetail = {
                    name: "",
                    stream: "",
                  };
                  newRestArea.details.push(itemDetail);
                  setInput(newRestArea);
                }}
              >
                Tambah Detail
              </Button>
            </CardActions>
          </Card>

          {/* Form CCTV */}
          <Card>
            <CardHeader title="CCTV Rest Area" />
            <Divider />
            <CardContent>
              {input?.cctvs !== undefined ? (
                input.cctvs.map((cctv, index) => (
                  <Fragment key={`${cctv}~${index}`}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Nama CCTV"
                            name="name"
                            type="text"
                            value={cctv?.name ?? ""}
                            // error={ errField?.errors?.lng }
                            // helperText={ errField?.errors?.lng }
                            onChange={(event) => handleCctvChange(event, index)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="URL CCTV"
                            name="stream"
                            type="text"
                            value={cctv?.stream ?? ""}
                            // error={ errField?.errors?.lng }
                            // helperText={ errField?.errors?.lng }
                            onChange={(event) => handleCctvChange(event, index)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="small"
                            color="primary"
                            onClick={() => {
                              const newRestArea = { ...input };
                              newRestArea.cctvs.splice(index, 1);
                              setInput(newRestArea);
                            }}
                            style={{
                              color: "red",
                              marginTop: 20,
                            }}
                          >
                            Hapus
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Fragment>
                ))
              ) : (
                <Typography variant="body" component="p">
                  Data CCTV Kosong
                </Typography>
              )}
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  const newRestArea = { ...input };
                  let itemCctv = {
                    name: "",
                    stream: "",
                  };
                  newRestArea.cctvs.push(itemCctv);
                  setInput(newRestArea);
                }}
              >
                Tambah CCTV
              </Button>
            </CardActions>
          </Card>

          <input type="submit" ref={submitRef} style={{ display: "none" }} />
        </form>
      </Fragment>
    )
  );
};

export default FormRestArea;


