import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { StoreSegmentData } from '../../../../config/services';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import MySnackbar from '../../../components/Snackbar';
import TextField from '../../../components/TextField';

const initalState = {
	name: '',
	comp: '',
};

function Create({ createDialogOpen, setCreateDialogOpen, fetchRuasToll }) {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [input, setInput] = useState(initalState);
	const [snackbar, setSnackbar] = useState({});

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await StoreSegmentData(input);
			fetchRuasToll();
			setSnackbar({
				open: true,
				message: response.data.info,
				variant: 'success',
			});
			setInput(initalState);
		} catch (error) {
			console.log(error);
			setErrors(error.response.data);
			setSnackbar({
				open: true,
				message: error.response.data.message,
				variant: 'error',
			});
		} finally {
			setLoading(false);
		}
	};
	const handleChange = event => {
		setInput({
			...input,
			[event.target.name]: event.target.value,
		});
	};

	const handleClose = () => {
		setCreateDialogOpen(false);
	};

	const handleCloseSnackbar = () => {
		setSnackbar({});
	};

	return (
		<>
			<MySnackbar
				open={snackbar?.open}
				onClose={handleCloseSnackbar}
				message={snackbar?.message || ''}
				variant={snackbar.variant || 'info'}
			/>
			<Dialog fullWidth maxWidth="sm" open={createDialogOpen} scroll="body">
				<DialogTitle>Buat Ruas Toll</DialogTitle>
				<DialogContent>
					<div
						style={{
							paddingTop: 20,
						}}
					>
						<Grid container direction="column">
							<Grid item>
								<TextField
									fullWidth
									label="Nama Perusahaan"
									name="comp"
									type="text"
									value={input.comp ?? ''}
									error={errors?.errors?.comp}
									helperText={errors?.errors?.comp}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item>
								<TextField
									fullWidth
									label="Nama Ruas"
									name="name"
									type="text"
									value={input.name ?? ''}
									error={errors?.errors?.name}
									helperText={errors?.errors?.name}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleSubmit}
						variant="contained"
						style={{
							width: 120,
						}}
						disabled={loading}
						color="primary"
					>
						{loading ? <CircularProgress size={25} color="secondary" /> : `Create`}
					</Button>
					<Button onClick={handleClose} variant="contained">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Create;
