import React, { Component } from 'react';
import { connect } from 'react-redux';
import { attempUserLogin, clearErrorMessage, clearLoginUserData, closeLogoutSnackbar } from '../../../../config/redux';
import { AuthContent } from '../../../components/Auth';
import Button from '../../../components/Button';
import MySnackbar from '../../../components/Snackbar';
import TextField from '../../../components/TextField';

class FormLogin extends Component {
	state = {
		userInfo: {},
	};

	handleLogin = e => {
		e.preventDefault();
		this.props.attempLogin(this.state.userInfo);
	};

	componentDidMount() {
		document.title = 'Login Pengguna';
	}

	handleChange = event => {
		const newUserInfo = { ...this.state.userInfo };
		newUserInfo[event.target.name] = event.target.value;
		this.setState({
			userInfo: newUserInfo,
		});
	};

	onCloseSnackbar = () => {
		this.props.closeErrorSnackbar();
		this.props.closeLogoutSnackbar();
	};

	render() {
		const { userInfo } = this.state;
		const { error, logoutMessage } = this.props;
		return (
			<AuthContent>
				<MySnackbar
					open={this.props.snackbarOpen}
					message={error?.message}
					variant="error"
					onClose={this.onCloseSnackbar}
				/>
				<MySnackbar
					open={this.props.snackbarLogout}
					message={logoutMessage}
					variant="info"
					onClose={this.onCloseSnackbar}
				/>
				<form onSubmit={this.handleLogin}>
					<TextField
						fullWidth
						label="Username / Email"
						type="text"
						name="username"
						error={error.errors?.username ? true : false}
						helperText={error.errors?.username}
						value={userInfo.value}
						onChange={this.handleChange}
					/>
					<TextField
						fullWidth
						label="Password"
						type="password"
						name="password"
						error={error.errors?.password ? true : false}
						helperText={error.errors?.password}
						value={userInfo.password}
						onChange={this.handleChange}
					/>
					<Button variant="contained" color="secondary" type="submit" disabled={this.props.loading}>
						Login
					</Button>
				</form>
			</AuthContent>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.userLogin.loading,
	error: state.userLogin.error,
	currentUser: state.userLogin.currentUser,
	logoutMessage: state.userLogout.message,
	snackbarOpen: state.userLogin.snackbar,
	snackbarLogout: state.userLogout.snackbar,
});

const mapDispatchToProps = dispatch => ({
	attempLogin: userInfo => dispatch(attempUserLogin(userInfo)),
	clearLoginUser: () => dispatch(clearLoginUserData()),
	closeErrorSnackbar: () => dispatch(clearErrorMessage()),
	closeLogoutSnackbar: () => dispatch(closeLogoutSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormLogin);
