import {
    FETCH_USER_LOGIN,
    FETCH_USER_LOGIN_SUCCESS,
    FETCH_USER_LOGIN_FAILURE,
    CLEAR_LOGIN_USER,
    CLEAR_ERROR_MESSAGE,
}  from './userLoginType';

const initalState = {
    loading: false,
    currentUser: JSON.parse(localStorage.getItem('user')) || {},
    token: localStorage.getItem('token') || '',
    error: {},
    snackbar: false
}

const reducer = (state = initalState, action) => {
    switch(action.type) {
        case FETCH_USER_LOGIN:
            return {
                ...state,
                loading: true,
                error: {}
            }
        case FETCH_USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                currentUser: action.payload.user,
                token: action.payload.token,
            }
        case FETCH_USER_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                snackbar: true,
                currentUser: {}
            }
        case CLEAR_LOGIN_USER:
            return {
                ...state,
                loading: false,
                error: {},
                currentUser: {},
                token: ''
            }
        case CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                snackbar: false
            }
        default: return state;
    }
}

export default reducer;