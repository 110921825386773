import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import FormCreate from './FormCreate';
import {storeGate, fetchSegmentLists, unsetError, updateGate} from '../../../../config/redux';
import { connect } from 'react-redux';
import { useEffect } from 'react';

const Create = (props) => {

    const registerBtnRef = React.createRef();
    const { open, handleClose, loading, error, segments, loadingSegment } = props;

    const handleDialogClose = () => {
        props.unsetError();
        handleClose();
    }

    const handleStore = () => {
        registerBtnRef.current.click();
    }

    useEffect( () => {
        loadSegments();
    }, []);

    const loadSegments = () => {
        props.fetchSegments();
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            scroll='body'
        >
            <DialogTitle
                onClose={true}
                onHandleClose={handleDialogClose}
            >
                { props.userEdit ? `Edit Gerbang Tol` : `Tambah Gerbang Tol`}
            </DialogTitle>
            <DialogContent>
                <FormCreate 
                    error={error || {}}
                    lists={segments}
                    userEdit={props.userEdit || null}
                    listsLoading={loadingSegment}
                    onSubmit={ (params) => props.storeData(params)}
                    onUpdate={ (params) => props.updateData(params)}
                    registerBtnRef={registerBtnRef}
                />
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleStore}
                    variant="contained"
                    style={{
                        width: 120
                    }}
                    disabled={ loading }
                    color="primary">
                        { loading ?
                            <CircularProgress 
                                size={25} 
                            />
                            :
                            props.userEdit ? `Update` : `Simpan`
                        }
                </Button>
                <Button 
                    onClick={handleDialogClose}
                    variant="contained"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    loading: state.gates.storeGateLoading,
    gate: state.gates.storeGate,
    error: state.gates.storeGateError,
    loadingSegment: state.segments.listsLoading,
    segments: state.segments.lists
});

const mapDispatchToProps = dispatch => ({
    storeData: (params) => dispatch(storeGate(params)),
    updateData: (params) => dispatch(updateGate(params)),
    fetchSegments: () => dispatch(fetchSegmentLists()),
    unsetError: () => dispatch(unsetError())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Create);