import { 
    FETCH_PANEL_MENU, 
    FETCH_PANEL_MENU_FAILURE,
    FETCH_PANEL_MENU_SUCCESS
 } from "./panelMenuType"


const initialState = {
    loading: false,
    menus: [],
    error: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_PANEL_MENU:
            return {
                ...state,
                loading: true
            }
        case FETCH_PANEL_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                menus: action.payload
            }
        case FETCH_PANEL_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: return state;
    }
}

export default reducer;