import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  cctvs: {
    display: "flex",
    flexWrap: "wrap",
  },
  itemCctv: {
    marginRight: 10,
    marginBottom: 10,
    "&:last-child": {
      marginRight: 0,
    },
  },
});

function ItemCctv({ cctvs, setOpenCctvDialog, setCurrentCctv }) {
  const classes = useStyles();
  return cctvs.length ? (
    <div className={classes.cctvs}>
      {cctvs.map((cctv, index) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOpenCctvDialog(true);
            setCurrentCctv(cctv);
          }}
          className={classes.itemCctv}
          key={index}
        >
          {cctv.name || "CCTV"}
        </Button>
      ))}
    </div>
  ) : (
    <Typography
      variant="subtitle2"
      component="p"
      style={{
        color: "red",
      }}
    >
      CCTV Tidak Tersedia
    </Typography>
  );
}

export default ItemCctv;
