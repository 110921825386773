import { FETCH_SEGMENT_LIST, FETCH_SEGMENT_LIST_SUCCESS, FETCH_SEGMENT_LIST_FAILURE } from "./segmentTypes"

const initialState = {
    lists: [],
    listsLoading: true,
    listsError: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_SEGMENT_LIST:
            return {
                ...state,
                listsLoading: true
            }
        case FETCH_SEGMENT_LIST_SUCCESS:
            return {
                ...state,
                listsLoading: false,
                lists: action.payload
            }
        case FETCH_SEGMENT_LIST_FAILURE:
            return {
                ...state,
                listsLoading: false,
                listsError: action.payload
            }
        default: return state;
    }
}

export default reducer;