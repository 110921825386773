import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { utils, writeFileXLSX } from "xlsx";
import { dateDef } from "../../../../config/datetime";
import { setPageLoading, setPanelTitle } from "../../../../config/redux";
import {
  DeleteCallCenterData,
  GetCallCenters,
} from "../../../../config/services";
import Confirm from "../../../components/Confirm/Confirm";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import TableData from "../../../components/Panel/Table";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View";

const useStyles = makeStyles((theme) => ({
  grow: {
    flex: 1,
  },
  cardRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  ...theme.styles.panelCard,
}));

const columns = [
  {
    id: "id",
    label: "ID",
    minWidth: 170,
  },
  {
    id: "title",
    label: "Nama Call Center",
    minWidth: 170,
  },
  {
    id: "phone",
    label: "Nomor Handphone",
    minWidth: 225,
  },
  {
    id: "created_at",
    label: "Dibuat",
    minWidth: 200,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
  },
];

const moreItems = ["Edit", "View", "Delete"];

function RuasToll() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [callCenterDelete, setSegmentDelete] = useState(null);
  const [callCenterDeleteLoading, setSegmentDeleteLoading] = useState(false);
  const [editCallCenter, setCallCenter] = useState({});
  const [viewCallCenter, setViewCallCenter] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchCallCenter = async (params) => {
    setLoading(true);
    try {
      const response = await GetCallCenters(params);
      setData(response.data.call_centers);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (type) => {
    if (type) {
      setSegmentDeleteLoading(true);
      try {
        const response = await DeleteCallCenterData(callCenterDelete);
        fetchCallCenter();
        setSegmentDelete(null);
        setOpenConfirmDelete(false);
      } catch (error) {
        console.log(error);
      } finally {
        setSegmentDeleteLoading(false);
      }
    } else {
      setOpenConfirmDelete(false);
    }
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      fetchCallCenter({ q: search });
    }
  };

  const clearSearchInput = () => {
    if (search) {
      setSearch("");
      fetchCallCenter();
    }
  };

  const dialogCreateOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleChangePage = (page) => {
    fetchCallCenter({ page: page, limit: data.meta.per_page });
  };

  const handleChangeRowsPerPage = (limit) => {
    fetchCallCenter({ limit: limit });
  };

  const handleMoreOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMoreOpen(id);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setMoreOpen(false);
  };

  const clickMoreItem = (item, segment) => {
    if (item === "Edit") {
      setCallCenter(segment);
    } else if (item === "Delete") {
      setOpenConfirmDelete(true);
      setSegmentDelete(segment.id);
    } else {
      setViewCallCenter(segment);
    }
    handleCloseMore();
  };

  const handleCloseView = () => {
    setViewCallCenter({});
  };

  useEffect(() => {
    fetchCallCenter();
    dispatch(setPanelTitle("Call Center"));
  }, [dispatch]);

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetCallCenters({
        paginate: false,
      });
      const result = response.data.call_centers.map((callCenter) => ({
        ID: callCenter.id,
        "Nama Call Center": callCenter.title,
        "Nomor Handphone": callCenter.phone,
        Dibuat: moment(callCenter.created_at).format(
          "HH:mm:ss - dddd, DD-MM-YYYY"
        ),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-Call-Center.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Call Center</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item className={classes.grow}></Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={handleChangeSearch}
            onKeyPress={onSubmitSearch}
            clearSearchInput={clearSearchInput}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={dialogCreateOpen}
          >
            Buat Call Center
          </Button>
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <CardHeader
          title="Data Call Center"
          titleTypographyProps={{
            className: classes.cardTitle,
          }}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={loadingExport}
            >
              Export Data
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <TableData
            columns={columns}
            meta={{ ...data.meta, ...data.links }}
            loading={loading}
            onHandleChangePage={(page) => handleChangePage(page)}
            onHandleChangeRowsPerPage={(limit) =>
              handleChangeRowsPerPage(limit)
            }
          >
            {data.data?.map((callCenter) => (
              <TableRow key={callCenter.id} hover>
                <TableCell>{callCenter.id}</TableCell>
                <TableCell>{callCenter.title}</TableCell>
                <TableCell>{callCenter.phone}</TableCell>
                <TableCell>{dateDef(callCenter.created_at)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMoreOpen(event, callCenter.id)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={moreOpen === callCenter.id ? true : false}
                    onClose={handleCloseMore}
                  >
                    {moreItems.map((item) => (
                      <MenuItem
                        key={item}
                        onClick={() => clickMoreItem(item, callCenter)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableData>
        </CardContent>
      </Card>
      {createDialogOpen && (
        <Create
          fetchCallCenter={fetchCallCenter}
          createDialogOpen={createDialogOpen}
          setCreateDialogOpen={setCreateDialogOpen}
        />
      )}
      {!isEmpty(editCallCenter) && (
        <Edit
          fetchCallCenter={fetchCallCenter}
          open={!isEmpty(editCallCenter)}
          callCenter={editCallCenter}
          setCallCenter={setCallCenter}
        />
      )}

      {!isEmpty(viewCallCenter) && (
        <View
          open={!isEmpty(viewCallCenter)}
          viewCallCenter={viewCallCenter}
          setViewCallCenter={setViewCallCenter}
        />
      )}
      {openConfirmDelete && (
        <Confirm
          loading={callCenterDeleteLoading}
          open={openConfirmDelete}
          content="Anda yakin ingin menghapus data ?"
          onAction={(type) => handleDelete(type)}
        />
      )}
    </div>
  );
}

export default RuasToll;
