import React, { Component, Fragment } from 'react';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, withStyles } from '@material-ui/core';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import { fetchUserDetail } from '../../../../config/redux';
import { connect } from 'react-redux';
import { toUpperCase } from '../../../../utils/helper';
import { compose } from 'redux';

class View extends Component {

    componentDidMount() {
        this.props.fetchUser(this.props.id);
    }

    componentWillUnmount() {
        console.log('component user view Unmounted');
    }

    listItem = ({label, value}) => {
        console.log(this.props);
        // const {clases} = this.props;
        return (
            <ListItem 
                className={this.props.classes.listItem} 
                alignItems="flex"
            >
                <ListItemText className={this.props.classes.label}>{label}</ListItemText>
                {/* <ListItemText className={this.props.classes.doted}>:</ListItemText> */}
                <ListItemText className={this.props.classes.value}>{value}</ListItemText>
            </ListItem>
        )
    }

    render() {
        const {open, handleClose, loading, user, classes} = this.props;
        return (
            <Dialog
                open={open}
                scroll='body'
                fullWidth
                maxWidth='md'
            >
                <DialogTitle
                    onClose={true}
                    onHandleClose={handleClose}
                >
                    Detail Pengguna
                </DialogTitle>
                <DialogContent>
                    { loading ?
                        <CircularProgress />
                        :
                        <Fragment>
                            <List>
                                <ListItem className={classes.header} alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={toUpperCase(user.profile.full_name)} src={user.profile?.profile_picture} className={this.props.classes.avatar} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.profile.full_name}
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="textPrimary"
                                                >
                                                    {user.email}
                                                </Typography>
                                                {" — ROLE " + toUpperCase(user.role.name)}
                                            </Fragment>
                                        }
                                    />
                                </ListItem>
                                {/* <Divider variant="inset" component="li"/> */}
                            </List>
                            <List>
                                <this.listItem label="No Handphone" value={user.profile.mobile} />
                                <this.listItem label="Alamat" value={user.profile.address} />
                            </List>
                        </Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userData.user,
    loading: state.userData.fetchDetailLoading
});

const mapDispatchToProps = dispatch => ({
    fetchUser: (id) => dispatch(fetchUserDetail(id))
});

const useStyles = theme => ({
    header: {
        backgroundColor: '#f1f1f1',
        '& .MuiListItemText-multiline': {
            alignSelf: 'center'
        }
    },
    listItem: {
        display: 'flex',
        '&:nth-child(odd)': {
            backgroundColor: '#f1f1f1'
        },
        '&:nth-child(even)': {
            backgroundColor: '#f7f7f7'
        },
        '& .MuiListItemText-root': {
            flex: 'none'
        }
    },
    value: {
        flex: 1,
        fontSize: '15px',
        '& .MuiTypography-root': {
            fontSize: '.9em !important'
        }
    },
    label: {
        width: '29%',
        color: '#424242',
        '& .MuiTypography-root': {
            fontSize: '.9em !important'
        }
    },
    doted: {
        width: 10
    },
    listItemText: {
        flex: 0
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2)
    }
});

// const styles = useStyles();

export default compose(
    withStyles(useStyles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(View);