import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
  StoreFacilitiesData,
  UpdateFacilitiesData,
} from "../../../../config/services";
import DialogTitle from "../../../components/Dialog/DialogTitle";
import ChooseImage from "../../../components/Forms/ChooseImage";
import MySnackbar from "../../../components/Snackbar";

const initalState = {
  name: "",
  image: "",
};

const useStyles = makeStyles(() => ({
  chooseImage: {
    // maxWidth: '70%',
    margin: "0 auto",
  },
}));

function DialogForm({
  edit,
  setEdit,
  createDialog,
  setCreateDialog,
  fetchFacility,
}) {
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({});
  const [input, setInput] = useState(initalState);
  const [errors, setErrors] = useState({});
  const [errField, setErrField] = useState({});

  const classes = useStyles();

  const handleClose = () => {
    setCreateDialog(false);
    setEdit({});
  };

  const handleSubmit = async () => {
    setLoading(true);
    let formData = new FormData();
    Object.keys(input).forEach((item) => {
      formData.append(item, input[item]);
    });
    if (isEmpty(edit)) {
      try {
        const response = await StoreFacilitiesData(formData);
        setSnackBar({
          open: true,
          message: response.data.info,
          variant: "success",
        });
        fetchFacility();
        setErrField({});
        setInput(initalState);
      } catch (error) {
        console.log(error);
        setSnackBar({
          open: true,
          message: error.response.data.info,
          variant: "error",
        });
        setErrField(error.response.data);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await UpdateFacilitiesData(formData, edit.id);
        setSnackBar({
          open: true,
          message: response.data.info,
          variant: "success",
        });
        fetchFacility();
        setErrField({});
        handleClose();
      } catch (error) {
        console.log(error);
        setSnackBar({
          open: true,
          message: error.response.data.info,
          variant: "error",
        });
        setErrField(error.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(edit)) {
      setInput({
        name: edit.name,
      });
    }
  }, [edit]);

  const handleChange = (event) => {
    const newInput = { ...input };
    if (event.target.name === "image") {
      newInput[event.target.name] = event.target.files[0];
    } else {
      newInput[event.target.name] = event.target.value;
    }
    setInput(newInput);
  };

  return (
    <>
      <MySnackbar
        open={snackBar.open}
        variant={snackBar.variant}
        message={snackBar.message}
        onClose={() => setSnackBar({})}
      />
      <Dialog
        open={!isEmpty(edit) || createDialog}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle onClose={true} onHandleClose={handleClose}>
          {createDialog ? "Tambah Fasilitas" : "Edit Fasilitas"}
        </DialogTitle>
        <DialogContent>
          {(!isEmpty(edit) || createDialog) && (
            <>
              <div
                style={{
                  paddingTop: 20,
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <div className={classes.chooseImage}>
                      <ChooseImage
                        name="image"
                        label="Image Icon"
                        value=""
                        id="image-icon"
                        src={edit.image ?? ""}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Nama Fasilitas"
                      name="name"
                      type="text"
                      value={input.name ?? ""}
                      error={errors?.errors?.name}
                      helperText={errors?.errors?.name}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="small"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : createDialog ? (
              "Simpan"
            ) : (
              "Update"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogForm;
