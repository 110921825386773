import { Card, CardContent, Grid, Input, MenuItem } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import "../../../../config/app";
import { fileType } from "../../../../utils/Validation";
import TextField from "../../../components/TextField";
// import theme from '../../../../config/theme';
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { fetchGates } from "../../../../config/redux";
import SelectImage from "../../../components/Forms/SelectImage";
import MySnackbar from "../../../components/Snackbar";

const FormCreate = (props) => {
  const { error, onSubmit, onUpdate, registerBtnRef, lists, listsLoading } =
    props;
  const [gate, setGate] = useState(props.userEdit || {});
  const featuredImageEdit = props.userEdit
    ? props.userEdit?.image &&
      global.api_url + "storage/img/gates/" + props.userEdit.image
    : "";
  const [featuredImage, setFeaturedImage] = useState(
    props.userEdit ? featuredImageEdit : ""
  );
  const fImageCreate = featuredImage || global.no_image;

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    variant: "info",
  });

  const handleSubmit = () => {
    let formData = new FormData();
    Object.keys(gate).map((item) => {
      return formData.append(item, gate[item]);
    });
    onSubmit(formData).then(
      (res) => {
        setGate({});
        setFeaturedImage("");
        props.fetchGates();
        setSnackbar({
          open: true,
          message: res.data.message,
          variant: "success",
        });
      },
      (err) => {
        setSnackbar({
          open: true,
          message: err.response.data.message,
          variant: "error",
        });
      }
    );
  };

  const handleUpdate = () => {
    const newGate = { ...gate };
    if (typeof gate.image !== "object") {
      newGate["image"] = "";
    }
    let formData = new FormData();
    formData.append("_method", "put");
    Object.keys(newGate).map((item) => {
      return formData.append(item, gate[item]);
    });
    onUpdate(formData).then(
      (res) => {
        props.fetchGates();
        setSnackbar({
          open: true,
          message: res.data.message,
          variant: "success",
        });
      },
      (err) => {
        setSnackbar({
          open: true,
          message: err.message,
          variant: "error",
        });
      }
    );
  };

  const handleChange = (event) => {
    const newGate = { ...gate };
    newGate[event.target.name] = event.target.value;
    setGate(newGate);
  };

  const fileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const validateFile = fileType(event.target.value, [
        ".jpg",
        ".jpeg",
        ".png",
      ]);
      if (validateFile) {
        const file = event.target.files[0];
        const newGate = { ...gate };
        newGate["image"] = file;
        setGate(newGate);
        setFeaturedImage(URL.createObjectURL(file));
      } else {
        alert("file tidak valid");
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      variant: "info",
      message: "",
    });
  };

  return (
    <Fragment>
      <MySnackbar
        open={snackbar?.open}
        onClose={handleCloseSnackbar}
        message={snackbar?.message || ""}
        variant={snackbar.variant || "info"}
      />
      <form
        onSubmit={isEmpty(props.userEdit) ? handleSubmit : handleUpdate}
        method="post"
        encType="multipart/form-data"
        style={{
          paddingTop: 20,
        }}
      >
        <SelectImage
          src={fImageCreate}
          label="Featured Image"
          name="featured_image"
          onFileChange={fileChange}
        />
        <Card
          style={{
            marginTop: 25,
          }}
        >
          <CardContent>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  fullWidth
                  label="Nama Gerbang Tol"
                  name="name"
                  type="text"
                  value={gate.name ?? ""}
                  error={error?.errors?.name}
                  helperText={error?.errors?.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Ruas Tol"
                  name="segment_id"
                  type="text"
                  select
                  value={gate.segment_id ?? ""}
                  error={error?.errors?.segment_id}
                  helperText={error?.errors?.segment_id}
                  onChange={handleChange}
                >
                  {!listsLoading &&
                    lists.data.segments.map((list) => (
                      <MenuItem key={list.id} value={list.id}>
                        {list.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Latitude"
                  name="latitude"
                  type="text"
                  value={gate.latitude ?? ""}
                  error={error?.errors?.latitude}
                  helperText={error?.errors?.latitude}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Longitude"
                  name="longitude"
                  type="text"
                  value={gate.longitude ?? ""}
                  error={error?.errors?.longitude}
                  helperText={error?.errors?.longitude}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Input
              type="submit"
              style={{
                display: "none",
              }}
              onClick={isEmpty(props.userEdit) ? handleSubmit : handleUpdate}
              ref={registerBtnRef}
            />
          </CardContent>
        </Card>
      </form>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchGates: () => dispatch(fetchGates()),
});

export default connect(null, mapDispatchToProps)(FormCreate);
