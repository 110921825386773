import { FETCH_USER_DATA, FETCH_USER_DATA_SUCCESS, FETCH_USER_DATA_FAILURE, FETCH_USER_DETAIL, FETCH_USER_DETAIL_SUCCESS, FETCH_USER_DETAIL_FAILURE, FETCH_USER_EDIT, FETCH_USER_EDIT_FAILURE, FETCH_USER_EDIT_SUCCESS, FETCH_USER_DELETE, FETCH_USER_DELETE_SUCCESS, FETCH_USER_DELETE_FAILURE } from "./userDataType"
import { GetUsersData, GetUserDetail, UpdateUser, DeleteUser } from "../../services"
import { setPageLoading } from "../pageLoading/pageLoadingAction"

const fetchUserDataAction = () => {
    return {
        type: FETCH_USER_DATA
    }
}

const fetchUserDataSuccessAction = (data) => {
    return {
        type: FETCH_USER_DATA_SUCCESS,
        payload: data
    }
}

const fetchUserDataFailureAction = (error) => {
    return {
        type: FETCH_USER_DATA_FAILURE,
        payload: error
    }
}

const fetchUserDetailAction = () => {
    return {
        type: FETCH_USER_DETAIL
    }
}

const fetchUserDetailSuccessAction = user => {
    return {
        type: FETCH_USER_DETAIL_SUCCESS,
        payload: user
    }
}

const fetchUserDetailFailureAction = error => {
    return {
        type: FETCH_USER_DETAIL_FAILURE,
        payload: error
    }
}

const fetchUserEditAction = () => {
    return {
        type: FETCH_USER_EDIT
    }
}

const fetchUserEditSuccessAction = user => {
    return {
        type: FETCH_USER_EDIT_SUCCESS,
        payload: user
    }
}

const fetchUserEditFailureAction = error => {
    return {
        type: FETCH_USER_EDIT_FAILURE,
        payload: error
    }
}

const fetchUserDeleteAction = () => {
    return {
        type: FETCH_USER_DELETE
    }
}

const fetchUserDeleteSuccessAction = (message) => {
    return {
        type: FETCH_USER_DELETE_SUCCESS,
        payload: message
    }
}

const fetchUserDeleteFailureAction = (message) => {
    return {
        type: FETCH_USER_DELETE_FAILURE,
        payload: message
    }
}


export const fetchUserDelete = (params) => {
    return dispatch => {
        dispatch(fetchUserDeleteAction());
        const deleteAction = new Promise( (resolve, reject) => {
            DeleteUser(params).then( res => {
                const message = res.data;
                dispatch(fetchUserDeleteSuccessAction(message));
                resolve(message);
            }, err => {
                const message = err.response.data;
                dispatch(fetchUserDeleteFailureAction(message));
                reject(message);
            });
        });
        return deleteAction;
    }
}

export const fetchUserData = (params) => {
    return dispatch => {
        dispatch(fetchUserDataAction())
        dispatch(setPageLoading(true));
        GetUsersData(params).then(res => {
            const users = res.data;
            const meta = res.meta;
            dispatch(fetchUserDataSuccessAction({ users: users, meta: meta}));
            dispatch(setPageLoading(false));
        }, err => {
            const error = err.response.data;
            dispatch(fetchUserDataFailureAction(error));
        });
    }
}

export const fetchUserDetail = (id) => {
    return dispatch => {
        dispatch(fetchUserDetailAction());
        GetUserDetail(id).then( res => {
            const user = res.data;
            dispatch(fetchUserDetailSuccessAction(user));
        }, err => {
            const error = err.response.data;
            dispatch(fetchUserDetailFailureAction(error));
        });
    }
}

export const fetchUpdateUser = (userInfo) => {
    return dispatch => {
        dispatch(fetchUserEditAction());
        const updateUser = new Promise( (resolve, reject) => {
            UpdateUser(userInfo).then( res => {
                const user = res.data;
                dispatch(fetchUserEditSuccessAction(user));
                resolve(res);
            }, err => {
                const error = err.response.data;
                dispatch(fetchUserEditFailureAction(error));
                reject(error);
            });
        });
        return updateUser;
    }
}