import { FETCH_POSTS, FETCH_POSTS_SUCCESS, FETCH_POSTS_FAILURE, STORE_POSTS, STORE_POSTS_SUCCESS, STORE_POSTS_FAILURE, UPDATE_POSTS_SUCCESS, UPDATE_POSTS_FAILURE, UPDATE_POSTS } from "./postType"


const initialState = {
    posts: {
        meta: {},
        data: []
    },
    postsLoading: true,
    postsError: {},
    postStore: {},
    postStoreLoading: false,
    postStoreError: {}
}

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                postsLoading: true
            }
        case FETCH_POSTS_SUCCESS:
            return {
                ...state,
                postsLoading: false,
                posts: action.payload
            }
        case FETCH_POSTS_FAILURE:
            return {
                ...state,
                postsLoading: false,
                postsError: action.payload
            }
        case STORE_POSTS:
            return {
                ...state,
                postStoreLoading: true
            }
        case STORE_POSTS_SUCCESS:
            return {
                ...state,
                postStoreLoading: false,
                postStore: action.payload
            }
        case STORE_POSTS_FAILURE:
            return {
                ...state,
                postStoreLoading: false,
                postStoreError: action.payload
            }
        case UPDATE_POSTS:
            return {
                ...state,
                postStoreLoading: true
            }
        case UPDATE_POSTS_SUCCESS:
            return {
                ...state,
                postStoreLoading: false,
                postStore: action.payload
            }
        case UPDATE_POSTS_FAILURE:
            return {
                ...state,
                postStoreLoading: false,
                postStoreError: action.payload
            }
        default: return state;
    }
}

export default reducer;