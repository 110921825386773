import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { utils, writeFileXLSX } from "xlsx";
import { dateDef } from "../../../../config/datetime";
import {
  fetchRoleLists,
  fetchUserData,
  fetchUserDelete,
  resetCreateUser,
  setPageLoading,
} from "../../../../config/redux";
import { GetUsersData } from "../../../../config/services";
import theme from "../../../../config/theme";
import Confirm from "../../../components/Confirm/Confirm";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import TableData from "../../../components/Panel/Table";
import MySnackbar from "../../../components/Snackbar";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View";

const styles = () => ({
  grow: {
    flex: 1,
  },
  select: {
    minWidth: 250,
  },
  cardRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  ...theme.styles.panelCard,
});

function UserPage({ classes }) {
  const {
    loading,
    error,
    users,
    meta,
    fetchDeleteLoading: deleteLoading,
    userDelete: deleteMessage,
    userDeleteError: deleteMessageError,
  } = useSelector((state) => state.userData);
  const { roleLists: roles } = useSelector((state) => state.roleLists);
  const dispatch = useDispatch();
  const columns = [
    {
      id: "full_name",
      label: "Nama Lengkap",
      minWidth: 225,
    },
    {
      id: "username",
      label: "Username",
      minWidth: 170,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 170,
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "created_at",
      label: "Dibuat",
      minWidth: 200,
    },
  ];

  const [search, setSearch] = useState("");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [currentView, setCurrentView] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [moreItems, setMoreItems] = useState(["Edit", "View", "Delete"]);
  const [currentEditId, setCurrentEditId] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [userDelete, setUserDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({});
  const [filter, setFilter] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    dispatch(fetchUserData());
    dispatch(fetchRoleLists());
  }, [dispatch]);

  const handleChangePage = (page) => {
    dispatch(fetchUserData({ page: page, limit: meta.per_page, role: filter }));
  };

  const handleChangeRowsPerPage = (limit) => {
    dispatch(fetchUserData({ limit: limit, role: filter }));
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      dispatch(fetchUserData({ q: search, role: filter }));
    }
  };

  const clearSearchInput = () => {
    if (search) {
      setSearch("");
      dispatch(fetchUserData());
    }
  };

  const dialogCreateOpen = () => {
    setCreateDialogOpen(true);
  };

  const dialogCreateClose = () => {
    setCreateDialogOpen(false);

    dispatch(resetCreateUser());
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setMoreOpen(false);
  };

  const clickMoreItem = (item, id) => {
    if (item === "Edit") {
      handleEdit(id);
    } else if (item === "Delete") {
      setOpenConfirmDelete(true);
      setUserDelete(id);
    } else {
      setOpenView(true);
      setCurrentView(id);
    }
    handleCloseMore();
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  const handleEdit = (id) => {
    setEditDialogOpen(true);
    setCurrentEditId(id);
  };

  const handleDelete = (res) => {
    if (res) {
      dispatch(fetchUserDelete(userDelete)).then(
        (res) => {
          dispatch(
            fetchUserData({
              page: meta.current_page,
              limit: meta.per_page,
              role: filter,
            })
          );
          setOpenConfirmDelete(false);
          setSnackbar({
            open: true,
            variant: "success",
            message: deleteMessage.message,
          });
        },
        (err) => {
          setOpenConfirmDelete(false);
          setSnackbar({
            open: true,
            variant: "success",
            message: deleteMessageError.message,
          });
        }
      );
    } else {
      setOpenConfirmDelete(false);
    }
  };

  const handleMoreOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMoreOpen(id);
  };

  const dialogEditClose = () => {
    setEditDialogOpen(false);
    dispatch(
      fetchUserData({
        page: meta.current_page,
        limit: meta.per_page,
        role: filter,
      })
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbar({});
  };

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
    dispatch(
      fetchUserData({
        page: meta.current_page,
        limit: meta.per_page,
        role: event.target.value,
      })
    );
  };

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetUsersData({
        paginate: false,
      });
      const result = response.data.map((user) => ({
        ID: user.id,
        Username: user.username,
        Email: user.email,
        Nama: user.profile?.full_name,
        Handphone: user.profile?.mobile,
        Address: user.profile?.address,
        Dibuat: moment(user.created_at).format("HH:mm:ss - dddd, DD-MM-YYYY"),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-Pengunjung.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Pengguna</title>
      </Helmet>
      <MySnackbar
        open={snackbar?.open}
        onClose={handleCloseSnackbar}
        message={snackbar?.message || ""}
        variant={snackbar.variant || "info"}
      />
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item className={classes.grow}></Grid>
        <Grid item className={classes.select}>
          <FormControl fullWidth variant="standard">
            <InputLabel>Filter Roles</InputLabel>
            <Select
              label="Tipe"
              value={filter}
              onChange={handleChangeFilter}
              placeholder="Pilih Tipe"
            >
              <MenuItem value={null}>Semua Roles</MenuItem>
              {roles.map((role) => (
                <MenuItem value={role.value}>{role.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={handleChangeSearch}
            onKeyPress={onSubmitSearch}
            clearSearchInput={clearSearchInput}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={dialogCreateOpen}
          >
            Buat Pengguna Baru
          </Button>
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <CardHeader
          title="Data Pengguna"
          titleTypographyProps={{
            className: classes.cardTitle,
          }}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={loadingExport}
            >
              Export Data
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <TableData
            columns={columns}
            meta={meta}
            loading={loading}
            error={error}
            onHandleChangePage={(page) => handleChangePage(page)}
            onHandleChangeRowsPerPage={(limit) =>
              handleChangeRowsPerPage(limit)
            }
          >
            {users.map((user) => (
              <TableRow key={user.id} hover>
                <TableCell>{user.profile?.full_name}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role?.name}</TableCell>
                <TableCell>{dateDef(user.created_at)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMoreOpen(event, user.id)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={moreOpen === user.id ? true : false}
                    onClose={handleCloseMore}
                  >
                    {moreItems.map((item) => (
                      <MenuItem
                        key={item}
                        onClick={() => clickMoreItem(item, user.id)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableData>
        </CardContent>
      </Card>
      {createDialogOpen && (
        <Create
          fullWidth
          open={createDialogOpen}
          handleClose={() => dialogCreateClose()}
        />
      )}
      {editDialogOpen && (
        <Edit
          fullWidth
          id={currentEditId}
          open={editDialogOpen}
          handleClose={() => dialogEditClose()}
        />
      )}
      {openView && (
        <View
          handleClose={() => handleCloseView()}
          id={currentView}
          open={openView}
        />
      )}
      {openConfirmDelete && (
        <Confirm
          loading={deleteLoading}
          open={openConfirmDelete}
          content="Anda yakin ingin menghapus data ?"
          onAction={(res) => handleDelete(res)}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(UserPage);
