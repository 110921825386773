import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { dateDef } from "../../../../config/datetime";
import numeral from "../../../../utils/numeralLocale";
import DialogTitle from "../../../components/Dialog/DialogTitle";

const useStyles = makeStyles({
  header: {
    backgroundColor: "#f1f1f1",
    "& .MuiListItemText-multiline": {
      alignSelf: "center",
    },
  },
  listItem: {
    display: "flex",
    "&:nth-child(odd)": {
      backgroundColor: "#f1f1f1",
    },
    "&:nth-child(even)": {
      backgroundColor: "#f7f7f7",
    },
    "& .MuiListItemText-root": {
      flex: "none",
    },
  },
  value: {
    flex: 1,
    fontSize: "15px",
    "& .MuiTypography-root": {
      fontSize: ".9em !important",
    },
  },
  label: {
    width: "29%",
    color: "#424242",
    fontWeight: "bold",
    "& .MuiTypography-root": {
      fontSize: ".9em !important",
    },
  },
  doted: {
    width: 10,
  },
  listItemText: {
    flex: 0,
  },
});

function View({ open, viewTrack, setViewTrack }) {
  const classes = useStyles();
  const handleClose = () => {
    setViewTrack({});
  };
  return (
    <Dialog open={open} scroll="body" fullWidth maxWidth="md">
      <DialogTitle onHandleClose={handleClose}>Detail Ruas Toll</DialogTitle>
      <DialogContent>
        <List>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>ID</ListItemText>
            <ListItemText className={classes.value}>
              {viewTrack.id}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Gerbang Masuk</ListItemText>
            <ListItemText className={classes.value}>
              {viewTrack.gate_in.name}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>
              Gerbang Keluar
            </ListItemText>
            <ListItemText className={classes.value}>
              {viewTrack.gate_out.name}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>Dibuat Pada</ListItemText>
            <ListItemText className={classes.value}>
              {dateDef(viewTrack.created_at)}
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem} alignItems="flex">
            <ListItemText className={classes.label}>
              Perubahan Terakhir
            </ListItemText>
            <ListItemText className={classes.value}>
              {dateDef(viewTrack.updated_at)}
            </ListItemText>
          </ListItem>
        </List>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nama</TableCell>
              <TableCell>Harga</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {viewTrack.rates.map((rate) => (
              <TableRow key={rate.id}>
                <TableCell>{rate.name}</TableCell>
                <TableCell>Rp. {numeral(rate.price).format()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default View;
