export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';

export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const FETCH_USER_DETAIL_SUCCESS = 'FETCH_USER_DETAIL_SUCCESS';
export const FETCH_USER_DETAIL_FAILURE = 'FETCH_USER_DETAIL_FAILURE';

export const FETCH_USER_EDIT = 'FETCH_USER_EDIT';
export const FETCH_USER_EDIT_SUCCESS = 'FETCH_USER_EDIT_SUCCESS';
export const FETCH_USER_EDIT_FAILURE = 'FETCH_USER_EDIT_FAILURE';

export const FETCH_USER_DELETE = 'FETCH_USER_DELETE';
export const FETCH_USER_DELETE_SUCCESS = 'FETCH_USER_DELETE_SUCCESS';
export const FETCH_USER_DELETE_FAILURE = 'FETCH_USER_DELETE_FAILURE';