export const fileType = (file_name, valid_type) => {
    let valid = false;
    if(file_name.length > 0) {
        for(let i=0;i<valid_type.length;i++) {
            let curExt = valid_type[i];
            let file_ex = file_name.substr(file_name.length - curExt.length, curExt.length).toLowerCase();
            if( file_ex === curExt.toLowerCase() ) {
                valid = true;
            }
        }
    }
    if(!valid) {
        // file not valid
        alert('File type tidak valid');
    }
    return valid;
}