import React, { Fragment, useEffect } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import TextField from "../../../components/TextField";
import { useState } from "react";
import ChooseImage from "../../../components/Forms/ChooseImage";
import { StoreFeulssData, UpdateFeulssData } from "../../../../config/services";
import MySnackbar from "../../../components/Snackbar";

const FormContent = (props) => {
  const { isEdit, submitRef, onLoading, onSubmitSuccess } = props;
  const [data, setData] = useState({});
  const [frmErr, setFrmErr] = useState([]);
  const [snackBar, setSnackBar] = useState({});

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    console.log(props);
    if (isEdit) {
      setData({
        id: props.id,
        title: props.title,
        direction: props.direction,
        lat: props.lat,
        lng: props.lng,
        inside_toll: props.inside_toll,
      });
    }
  };

  const handleChange = (event) => {
    const newData = { ...data };
    if (event.target.name === "image") {
      newData[event.target.name] = event.target.files[0];
    } else {
      newData[event.target.name] = event.target.value;
    }
    setData(newData);
  };

  const handleCloseSnackBar = () => {
    setSnackBar({
      open: false,
      variant: "info",
      message: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    Object.keys(data).map((item) => {
      return formData.append(item, data[item]);
    });
    onLoading(true);
    if (!isEdit) {
      StoreFeulssData(formData)
        .then(
          (res) => {
            setSnackBar({
              open: true,
              variant: "success",
              message: res.data.message,
            });
            setData({});
            onSubmitSuccess();
            setFrmErr([]);
          },
          (err) => {
            setSnackBar({
              open: true,
              variant: "error",
              message: err.response.data.message,
            });
            setFrmErr(err.response.data);
          }
        )
        .finally(() => {
          onLoading(false);
        });
    } else {
      formData.append("_method", "put");
      UpdateFeulssData(formData)
        .then(
          (res) => {
            setSnackBar({
              open: true,
              variant: "success",
              message: res.data.message,
            });
            onSubmitSuccess();
            setFrmErr([]);
          },
          (err) => {
            setSnackBar({
              open: true,
              variant: "error",
              message: err.response.data.message,
            });
            setFrmErr(err.response.data);
          }
        )
        .finally(() => {
          onLoading(false);
        });
    }
  };

  return (
    <Fragment>
      <MySnackbar
        open={snackBar.open}
        variant={snackBar.variant}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid xs={12} item>
            <TextField
              fullWidth
              label="Nama SPBU"
              name="title"
              type="text"
              value={data?.title ?? ""}
              error={frmErr?.errors?.title}
              helperText={frmErr?.errors?.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              fullWidth
              label="Arah"
              name="direction"
              type="text"
              value={data?.direction ?? ""}
              error={frmErr?.errors?.direction}
              helperText={frmErr?.errors?.direction}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              fullWidth
              label="Latitude"
              name="lat"
              type="text"
              value={data?.lat ?? ""}
              error={frmErr?.errors?.lat}
              helperText={frmErr?.errors?.lat}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              fullWidth
              label="Longitude"
              name="lng"
              type="text"
              value={data?.lng ?? ""}
              error={frmErr?.errors?.lng}
              helperText={frmErr?.errors?.lng}
              onChange={handleChange}
            />
          </Grid>
          <Grid xs={12} item>
            <TextField
              fullWidth
              label="Status"
              name="inside_toll"
              select
              value={data?.inside_toll ?? false}
              error={frmErr?.errors?.inside_toll}
              helperText={frmErr?.errors?.inside_toll}
              onChange={handleChange}
            >
              <MenuItem key={"non-active"} value={false}>
                Tidak Aktif
              </MenuItem>
              <MenuItem key={"active"} value={true}>
                Aktif
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <input type="submit" ref={submitRef} style={{ display: "none" }} />
      </form>
    </Fragment>
  );
};

export default FormContent;
