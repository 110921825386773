import {
    FETCH_DO_RESET_PASSWORD,
    FETCH_DO_RESET_PASSWORD_SUCCESS,
    FETCH_DO_RESET_PASSWORD_FAILURE,
}  from './doResetPasswordType';
import { DoResetPassword } from '../../services';

const fetchDoResetPassword = () => {
    return {
        type: FETCH_DO_RESET_PASSWORD
    }
}

const fetchDoResetPasswordSuccess = (email) => {
    return {
        type: FETCH_DO_RESET_PASSWORD_SUCCESS,
        payload: email
    }
}

const fetchDoResetPasswordFailure = (error) => {
    return {
        type: FETCH_DO_RESET_PASSWORD_FAILURE,
        payload: error
    }
}

export const tryDoResetPassword = (email) => {
    return dispatch => {
        dispatch(fetchDoResetPassword());
        const doResetPassword = new Promise ( (resolve, rejcet) => {
            DoResetPassword(email).then( res => {
                const message = res.data.message;
                dispatch(fetchDoResetPasswordSuccess(message));
                resolve(message);
            }, err => {
                dispatch(fetchDoResetPasswordFailure(err.response.data));
                rejcet(err.response.data);
            })
        });
        return doResetPassword;
    }
}