import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPanelTitle } from '../../../../config/redux';
import { DeleteSlidersData, GetSlidersData } from '../../../../config/services';
import theme from '../../../../config/theme';
import Confirm from '../../../components/Confirm/Confirm';
import FormSearchPanel from '../../../components/Forms/FormSearchPanel';
import FormDialog from './FormDialog';
import Item from './Item';

const useStyles = makeStyles({
	cardContent: {
		maxHeight: 340,
		overflow: 'scroll',
	},
	cctvs: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	itemCctv: {
		marginRight: 10,
		marginBottom: 10,
		'&:last-child': {
			marginRight: 0,
		},
	},
	pagination: {
		margin: '2rem 0',
	},
	facilities: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	iconFacility: {
		'&:last-child': {
			marginRight: 0,
		},
		width: 25,
		height: 25,
		marginRight: 8,
		marginBottom: 8,
		backgroundSize: 'contain !important',
		backgroundRepeat: 'no-repeat !important',
		backgroundPosition: 'center !important',
	},
	cardRoot: {
		minHeight: 300,
		overflow: 'scroll',
	},
	cardTitleRoot: {
		fontSize: '1rem',
		fontWeight: 'bold',
		color: theme.palette.primary.main,
	},
	cardSubTitleRoot: {
		fontSize: '.9rem',
		color: theme.palette.secondary.main,
		marginTop: 10,
	},
	grow: {
		flex: 1,
	},
	root: {
		margin: '2rem 0',
	},
});

function Sliders() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [openFormDialog, setOpenFormDialog] = useState(false);
	const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
	const [sliders, setSliders] = useState([]);
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [currentEdit, setCurrentEdit] = useState({});
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [currentDelete, setCurrentDelete] = useState(null);

	useEffect(() => {
		fetchData();
		dispatch(setPanelTitle('Manage Sliders'));
	}, []);

	const fetchData = async (params = {}) => {
		setLoading(true);
		try {
			const response = await GetSlidersData(params);
			setSliders(response.data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const onSubmitSearch = event => {
		if (event.nativeEvent.key === 'Enter') {
			fetchData({ q: search });
		}
	};

	const handleDelete = async (res, id) => {
		if (res) {
			setDeleteLoading(true);
			try {
				const response = await DeleteSlidersData(currentDelete);
				fetchData();
			} catch (error) {
				console.log(error);
			} finally {
				setDeleteLoading(false);
				setOpenConfirmDelete(false);
				setCurrentDelete(null);
			}
		} else {
			setOpenConfirmDelete(false);
		}
	};
	return (
		<div>
			<Grid container spacing={2}>
				<Grid item className={classes.grow}></Grid>
				<Grid item>
					<FormSearchPanel
						value={search}
						onChange={event => {
							setSearch(event.target.value);
							fetchData();
						}}
						onKeyPress={onSubmitSearch}
						clearSearchInput={() => {
							setSearch('');
							fetchData();
						}}
					/>
				</Grid>
				<Grid item>
					<Button variant="outlined" onClick={() => setOpenFormDialog(true)}>
						Tambah Sliders
					</Button>
				</Grid>
			</Grid>
			{loading ? (
				<div
					style={{
						textAlign: 'center',
						minHight: 250,
						margin: '2rem 0',
						justifyContent: 'center',
					}}
				>
					<CircularProgress size={28} />
				</div>
			) : (
				<div className={classes.root}>
					<Grid container spacing={2}>
						{sliders.map(slider => (
							<Grid item xs={12} md={6}>
								<Item
									slider={slider}
									setOpenFormDialog={setOpenFormDialog}
									setCurrentEdit={setCurrentEdit}
									setOpenConfirmDelete={setOpenConfirmDelete}
									setCurrentDelete={setCurrentDelete}
									deleteLoading={deleteLoading}
								/>
							</Grid>
						))}
					</Grid>
				</div>
			)}
			{openFormDialog && (
				<FormDialog
					setOpenFormDialog={setOpenFormDialog}
					setCurrentEdit={setCurrentEdit}
					fetchData={fetchData}
					currentEdit={currentEdit}
					openFormDialog={openFormDialog}
					deleteLoading={deleteLoading}
				/>
			)}
			{openConfirmDelete && (
				<Confirm
					loading={deleteLoading}
					open={openConfirmDelete}
					content="Anda yakin ingin menghapus data ?"
					onAction={res => handleDelete(res)}
				/>
			)}
		</div>
	);
}

export default Sliders;
