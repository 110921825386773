import {
    FETCH_RESET_PASSWORD,
    FETCH_RESET_PASSWORD_SUCCESS,
    FETCH_RESET_PASSWORD_FAILURE,
}  from './resetPasswordType';

const initalState = {
    loading: false,
    currentUser: {},
    error: {}
}

const reducer = (state = initalState, action) => {
    switch(action.type) {
        case FETCH_RESET_PASSWORD:
            return {
                ...state,
                loading: true,
                error: {}
            }
        case FETCH_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: {},
                currentUser: action.payload
            }
        case FETCH_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                currentUser: {}
            }
        default: return state;
    }
}

export default reducer;