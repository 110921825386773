import { DRAWER_MOBILE_OPEN } from './drawerType';

const initialState = {
    drawerOpen: false
}

const reducer = (state = initialState, action) => {
    switch( action.type ) {
        case DRAWER_MOBILE_OPEN:
            const curStae = {...state};
            return {
                ...state,
                drawerOpen: !curStae.drawerOpen
            }
        default: return state;
    }
}

export default reducer;