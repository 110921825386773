import {
    FETCH_USER_AUTHORIZATION,
    FETCH_USER_AUTHORIZATION_SUCCESS,
    FETCH_USER_AUTHORIZATION_FAILURE,
}  from './userAuthorizationType';
import { AuthorizationCheck } from '../../services';

const fetchUserAuthorization = () => {
    return {
        type: FETCH_USER_AUTHORIZATION
    }
}

const fetchUserAuthorizationSuccess = () => {
    return {
        type: FETCH_USER_AUTHORIZATION_SUCCESS
    }
}

const fetchUserAuthorizationFailure = (error) => {
    return {
        type: FETCH_USER_AUTHORIZATION_FAILURE,
        payload: error
    }
}

export const checkAuthorization = (userInfo) => {
    return dispatch => {
        dispatch(fetchUserAuthorization());
        const authorziation = new Promise( (resolve, reject) => {
            AuthorizationCheck(userInfo).then( res => {
                dispatch(fetchUserAuthorizationSuccess());
                resolve(200);
            }, err => {
                dispatch(fetchUserAuthorizationFailure(err.response.status));
                reject(err.response.status);
            })
        });
        return authorziation;
    }
}