import React, { Component, Fragment } from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Icon, withStyles, Typography, Collapse } from '@material-ui/core';
import { compose } from 'redux';
import theme from '../../../../config/theme';
import history from '../../../../config/history';

class MenuList extends Component {

    state = {
        open: []
    }

    handleClick = (item) => {
        if( item?.children ) {
            const newOpen = {...this.state.open};
            newOpen[item.url] = !newOpen[item.url];
            const currentOpen = [item.url];
            Object.keys(newOpen).forEach(key => {
                currentOpen.includes(key) || delete newOpen[key];
            });
            this.setState({
                open: newOpen
            });
        }
        else {
            history.push(item.url);
        }
    }

    ItemList = ({item}) => {
        return (
            <ListItem 
                button 
                key={item.text}
                onClick={ () => this.handleClick(item) }
            >
                <ListItemIcon
                    classes={{
                        root: this.props.classes.iconContainer
                    }}
                >
                    <Icon>{ item.icon }</Icon>
                </ListItemIcon>
                <ListItemText primary={item.text} />
            </ListItem>
        )
    }

    ListLoop = ({lists}) => {
        const { open } = this.state;
        return(
            lists.map( (item) => {
                return (
                    <Fragment key={item.url}>
                        <this.ItemList item={item} />
                        { item?.children &&
                            <Collapse in={open[item.url]} timeout="auto" unmountOnExit>
                                <this.ListLoop lists={item.children} />
                            </Collapse>
                        }
                    </Fragment>
                )
            })
        )
    }

    render() {
        const { classes, lists } = this.props;
        return (
            <div>
                <div className={`${classes.toolbar} ${classes.toolbarContent}`} >
                    <img alt="main-logo" src={require('../../../../assets/img/logo/logo.png')} className={classes.logo} />
                </div>
                <Divider />
                <List>
                    <this.ListLoop lists={lists} />
                </List>
            </div>
        );
    }
}

const styles = ({
    toolbar: theme.mixins.toolbar,
    toolbarContent: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    logo: {
        height: 30,
        margin: '0 auto'
    },
    titleBar: {
        color: theme.palette.primary.main,
        marginLeft: 10
    },
    iconContainer: {
        minWidth: 40
    }
});

export default compose(
    withStyles(styles)
)(MenuList);