import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  List,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import theme from "../../../../../config/theme";
import ListItemCard from "../../../../components/Panel/ListItemCard";
import ItemCctv from "./ItemCctv";

const useStyles = makeStyles({
  cardContent: {
    maxHeight: 340,
    overflow: "scroll",
  },
  cardTitleRoot: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  cardSubTitleRoot: {
    fontSize: ".9rem",
    color: theme.palette.secondary.main,
    marginTop: 10,
  },
  media: {
    height: 170,
    width: "100%",
    borderTop: "1px solid " + theme.palette.primary.main,
    borderBottom: "2px solid red",
  },
  facilities: {
    display: "flex",
    flexWrap: "wrap",
  },
  iconFacility: {
    "&:last-child": {
      marginRight: 0,
    },
    width: 25,
    height: 25,
    marginRight: 8,
    marginBottom: 8,
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
});

function Item(props) {
  const classes = useStyles();
  const {
    restArea,
    setOpenDialogDetail,
    setCurrentDetails,
    setOpenDialogCreate,
    setCurrentEdit,
    deleteLoading,
    setCurrentDelete,
    setCurrentCctv,
    setOpenCctvDialog,
  } = props;

  const featured_image = restArea.image ? restArea.image : global.no_image;

  return (
    <Card>
      <CardHeader
        title={restArea.title}
        titleTypographyProps={{
          classes: {
            root: classes.cardTitleRoot,
          },
        }}
        subheaderTypographyProps={{
          classes: {
            root: classes.cardSubTitleRoot,
          },
        }}
      />
      <CardMedia className={classes.media} image={featured_image} />
      <CardContent
        classes={{
          root: classes.cardContent,
        }}
      >
        <List>
          <ListItemCard text="KM" value={restArea.km} />
          <ListItemCard text="Latitude" value={restArea.lat} />
          <ListItemCard text="Longitude" value={restArea.lng} />
          <ListItemCard
            text="Facilities"
            value={
              <div className={classes.facilities}>
                {restArea.facilities.map((facility, index) => (
                  <div
                    key={index}
                    style={{
                      background:
                        "url('" + global.api_url_i + facility.icon + "')",
                    }}
                    className={classes.iconFacility}
                  />
                ))}
              </div>
            }
          />
          <ListItemCard
            text="CCTV"
            value={
              <ItemCctv
                setOpenCctvDialog={setOpenCctvDialog}
                setCurrentCctv={setCurrentCctv}
                cctvs={restArea.cctvs}
              />
            }
          />
        </List>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpenDialogDetail(true);
            setCurrentDetails(restArea.details);
          }}
        >
          Detail
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpenDialogCreate(true);
            setCurrentEdit(restArea);
          }}
        >
          Edit
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          disabled={deleteLoading}
          onClick={() => {
            setCurrentDelete(restArea.id);
          }}
        >
          {deleteLoading ? <CircularProgress size="20" /> : "Delete"}
        </Button>
      </CardActions>
    </Card>
  );
}

export default Item;
