import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { Player } from 'video-react';
import HLSSource from '../../../components/HLSSource';

class Stream extends Component {
  state = {
    streamUrl: '',
    useVideo: false,
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(document.location.search);
    const url = searchParams.get('url');

    if ((url != null && !url.includes('rtsp.me')) || url.includes('sn.rtsp.me')) {
      this.setState({ useVideo: true });
    }
    this.setState({
      streamUrl: url ?? 'https://rtsp.me/embed/bQ3Ye2T2/',
    });
  }
  render() {
    return (
      <div>
        {this.state.useVideo ? (
          <Player autoPlay>
            <HLSSource isVideoChild src={this.state.streamUrl} />
          </Player>
        ) : (
          <iframe width='100%' title='video' src={this.state.streamUrl} frameborder='0' allow='autoplay; encrypted-media' allowfullscreen style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}></iframe>
        )}
      </div>
    );
  }
}

export default Stream;
