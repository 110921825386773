import React, { Fragment } from 'react';
import TextField from '../../../components/TextField';
import { fetchCreateUser, fetchRoleLists, fetchUserData } from '../../../../config/redux'
import { connect } from 'react-redux';
import Snackbar from '../../../components/Snackbar';
import { withStyles, Grid, InputBase, Card, CardHeader, CardContent, Input, MenuItem } from '@material-ui/core';
import { compose } from 'redux';
import { fileType } from '../../../../utils/Validation';
import ImageProcessing from '../../Images/ImageProcessing';
import CropingImage from '../../Images/CropingImage';
import theme from '../../../../config/theme';

class FormCreateUser extends React.Component {
    
    constructor(props) {
        super(props);
        this.imagePreviewCanvasRef = React.createRef()
        this.state = {
            createUser: {},
            snackbar: {},
            profilePicture: null,
            cropingImageOpen: false,
            imagePreview: null
        }
    }

    handleChange = (event) => {
        const newUser = {...this.state.createUser};
        newUser[event.target.name] = event.target.value;
        this.setState({
            createUser: newUser
        });
    }

    componentDidMount() {
        this.props.getRoleLists();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        const {createUser} = this.state;
        Object.keys(createUser).map( item => {
            return formData.append(item, createUser[item]);
        });
        this.props.registerUser(formData).then( res => {
            this.setState({
                createUser: {},
                snackbar: {
                    open: true,
                    message: res.message,
                    variant: 'success'
                }
            }, () => {
                this.props.getUsersData();
            });
        }, err => {
            this.setState({
                snackbar: {
                    open: true,
                    variant: 'error',
                    message: err.message
                }
            });
        });
    }

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {}
        });
    }

    selectProfilePicture = () => {
        document.getElementById('file_profile_picture').click()
    }

    fileChange = (event) => {
        if(event.target.files && event.target.files.length > 0) {
            const validateFile = fileType(event.target.value, ['.jpg', '.jpeg', '.png']);
            if(validateFile) {
                const file = event.target.files[0];
                const newUser = {...this.state.createUser};
                newUser['profile_picture'] = event.target.files[0];
                this.setState({
                    profilePicture: file,
                    createUser: newUser,
                    cropingImageOpen: true
                });
            }
            else {
                alert('file tidak valid');
            }
        }
    }

    closeCropingImage = (cropedFile) => {
        const newUser = {...this.state.createUser};
        newUser['profile_picture'] = cropedFile;
        this.setState({
            cropingImageOpen: false,
            profilePicture: cropedFile,
            createUser: newUser
        });
    }

    render () {

        const { error, classes, registerBtnRef, roleLists } = this.props;
        const { createUser, snackbar, profilePicture, cropingImageOpen } = this.state;
        const imgProfileUrl = profilePicture ? URL.createObjectURL(profilePicture) : null;

        return (
            <Fragment>
                <Snackbar 
                    open={ snackbar?.open }
                    onClose={this.handleCloseSnackbar}
                    message={snackbar?.message|| ''}
                    variant={snackbar?.variant ?? 'info'}
                />
                <form onSubmit={this.handleSubmit} method="post" encType='multipart/form-data'>
                    <Grid 
                        justify="center"
                        container
                    >
                        <Grid item
                            style={{
                                padding: theme.spacing(2, 0, 2, 0)
                            }}
                        >
                            <ImageProcessing 
                                onClick={ this.selectProfilePicture }
                                src={imgProfileUrl}
                            />
                            <CropingImage 
                                open={cropingImageOpen}
                                src={imgProfileUrl}
                                file={profilePicture}
                                onClose={ (croped) => this.closeCropingImage(croped)}
                                canvasRef={this.imagePreviewCanvasRef}
                            />
                        </Grid>
                    </Grid>
                    
                        <Card>
                            <CardHeader
                                title="Info Akun"
                                classes={{
                                    root: classes.cardHeader
                                }}
                                titleTypographyProps={{
                                    variant: 'h6',
                                    style: {
                                        color: '#444'
                                    }
                                }}
                            />
                            <CardContent
                                classes={{
                                    root: classes.cardContent
                                }}
                            >
                                <Grid 
                                    container
                                    direction="column"
                                >
                                    <Grid item>
                                        <InputBase 
                                            multiple={false}
                                            name="profile_picture"
                                            onChange={this.fileChange}
                                            type="file"
                                            id="file_profile_picture"
                                            style={{ display: 'none' }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            name="username"
                                            type="text"
                                            value={createUser.username ?? ''}
                                            error={ error.errors?.username }
                                            helperText={ error.errors?.username }
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            type="email"
                                            value={createUser.email ?? ''}
                                            error={ error.errors?.email }
                                            helperText={ error.errors?.email }
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            type="password"
                                            name="password"
                                            autoComplete={false}
                                            value={createUser.password ?? ''}
                                            error={ error.errors?.password }
                                            helperText={ error.errors?.password }
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Konfirmasi Password"
                                            type="password"
                                            autoComplete={false}
                                            name="password_confirmation"
                                            value={createUser.password_confirmation ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Role"
                                            autoComplete={false}
                                            value={createUser.role ?? null}
                                            name="role"
                                            onChange={this.handleChange}
                                        >
                                            { roleLists.map( role => (
                                                <MenuItem key={role.value} value={role.value}>
                                                    {role.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        
                        <Card
                            style={{
                                marginTop: theme.spacing(2)
                            }}
                        >
                            <CardHeader 
                                title="Personal Info"
                                classes={{
                                    root: classes.cardHeader
                                }}
                                titleTypographyProps={{
                                    variant: 'h6',
                                    style: {
                                        color: '#444'
                                    }
                                }}
                            />
                            <CardContent
                                classes={{
                                    root: classes.cardContent
                                }}
                            >
                                <Grid 
                                    container
                                    className={classes.formSection}
                                    direction="column"
                                >
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Nama Lengkap"
                                            type="text"
                                            name="full_name"
                                            error={ error.errors?.full_name }
                                            helperText={ error.errors?.full_name }
                                            value={createUser.full_name ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="No Handphone"
                                            type="text"
                                            name="mobile"
                                            error={ error.errors?.mobile }
                                            helperText={ error.errors?.mobile }
                                            value={createUser.mobile ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            label="Alamat"
                                            multiline={true}
                                            rows={2}
                                            type="text"
                                            name="address"
                                            value={createUser.address ?? ''}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        <Input
                            type="submit"
                            style={{
                                display: 'none'
                            }}
                            onClick={this.handleSubmit}
                            ref={registerBtnRef}
                        />

                </form>
            </Fragment>
        );
    }

};

const mapStateToProps = state => ({
    loading: state.createUser.loading,
    error: state.createUser.error,
    registerSuccess: state.createUser.registerSuccess,
    roleLists: state.roleLists.roleLists
});
const mapDispatchToProps = dispatch => ({
    registerUser: (createUser) => dispatch(fetchCreateUser(createUser)),
    getRoleLists: () => dispatch(fetchRoleLists()),
    getUsersData: (params) => dispatch(fetchUserData(params))
});

const styles = () => ({
    ...theme.styles.cardForm,
    formTitle: {
        backgroundColor: '#ddd',
        padding: theme.spacing(1),
        fontWeight: 'normal',
        fontSize: 14
    }
})


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(FormCreateUser);
