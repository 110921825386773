import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { setPageLoading } from "../../../config/redux";
import { GetLogData } from "../../../config/services";
import withPanelInfo from "../../../utils/PanelInfo";
import { saveAs } from "file-saver";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const columns = [
  {
    id: "id",
    label: "User ID",
    minWidth: 225,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "username",
    label: "Username",
    minWidth: 170,
  },
  {
    id: "created_at",
    label: "Dibuat",
    minWidth: 200,
  },
];

const initialPagination = {
  current: 1,
  pageSize: 10,
};

const initialSort = {
  field: null,
  sort: null,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <Grid spacing={6} container>
      <Grid item xs={2}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
      </Grid>
      <Grid item xs={2}>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
}

function Dashboard() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState({});
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });
  const [dataPie, setDataPie] = useState({
    labels: [],
    datasets: [],
  });
  const [filterBy, setFilterBy] = useState("month");

  const options = {
    responsive: true,
    scales: {},
    interaction: {
      intersect: false,
    },
  };

  useEffect(() => {
    document.title = "Dashboard";
    fetchData();
  }, [filterBy]);

  useEffect(() => {
    if (!isEmpty(data)) {
      handleChangeDataChart();
      handleChangeDataPie();
    }
  }, [data, filterBy]);

  const handleChangeDataChart = () => {
    let labels = data.log_chart_user.map((item) => item.date); //mengambil urutan date nya
    if (filterBy === "month") {
      labels = labels.map((label) => moment(label, "YYYY-MM").format("MMM"));
    } else if (filterBy === "week") {
      labels = labels.map((label) => {
        let dateArr = label.split("-");
        return `${moment()
          .year(dateArr[0])
          .isoWeek(dateArr[1])
          .startOf("week")
          .format("DD MMM")} - ${moment()
          .year(dateArr[0])
          .isoWeek(dateArr[1])
          .endOf("week")
          .format("DD MMM")}`;
      });
    } else if (filterBy === "day") {
      labels = labels.map((label) =>
        moment(label, "YYYY-MM-DD").format("DD MMM")
      );
    }
    const datasets = [
      {
        label: "Total Pengguna yang Berkunjung",
        data: data.log_chart_user.map((item) => item.value),
        backgroundColor: "red",
        borderColor: "red",
      },
      {
        label: "Total Tamu yang Berkunjung",
        data: data.log_chart_guest.map((item) => item.value),
        backgroundColor: "blue",
        borderColor: "blue",
      },
    ];
    setDataChart({
      labels,
      datasets,
      options,
    });
  };

  const handleChangeDataPie = () => {
    const dataPie = {
      labels: ["User", "Guest"],
      datasets: [
        {
          data: [data.user_log_count, data.guest_log_count],
          backgroundColor: ["red", "blue"],
        },
      ],
      options,
    };
    setDataPie(dataPie);
  };

  const fetchData = async () => {
    dispatch(setPageLoading(true));

    try {
      const response = await GetLogData({ groupBy: filterBy });
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
    }
  };

  const handleChange = (event) => {
    setFilterBy(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDownloadChart = (id) => {
    const canvas = document.getElementById(id);
    canvas.toBlob((blob) => {
      saveAs(blob, `${id}.jpg`);
    }, "image/jpeg");
  };

  return (
    <div>
      <div className="">
        <Grid style={{ marginTop: "20px" }} container spacing={2}>
          <Grid item xs={4} md={8} lg={10}></Grid>
          <Grid item xs={8} md={4} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
              <Select
                defaultValue="month"
                value={filterBy}
                onChange={handleChange}
                label="Filter By"
              >
                <MenuItem value={"month"}>Bulan</MenuItem>
                <MenuItem value={"week"}>Pekan</MenuItem>
                <MenuItem value={"day"}>Harian</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12" lg="6">
            <Grid container alignItems="center">
              <Grid item xs={6} md={9}>
                <Typography
                  variant="h6"
                  style={{ color: "#707070", margin: "20px 0px" }}
                >
                  <b>Progress Chart Kunjungan Pengguna</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} justify="flex-end">
                <Button
                  onClick={() => onDownloadChart("line-user-chart")}
                  variant="contained"
                  color="primary"
                >
                  Download
                </Button>
              </Grid>
            </Grid>

            <div style={{ maxWidth: "800px", margin: "auto" }}>
              <Line
                id="line-user-chart"
                style={{ marginTop: "10px" }}
                data={dataChart}
                options={dataChart.options}
              />
            </div>
          </Grid>
          <Grid item xs="12" lg="6">
            <Grid container alignItems="center">
              <Grid item xs={6} md={9}>
                <Typography
                  variant="h6"
                  style={{ color: "#707070", margin: "20px 0px" }}
                >
                  <b>Bar Chart Kunjungan Pengguna</b>
                </Typography>
              </Grid>
              <Grid item xs={6} md={3} justify="flex-end">
                <Button
                  onClick={() => onDownloadChart("bar-user-chart")}
                  variant="contained"
                  color="primary"
                >
                  Download
                </Button>
              </Grid>
            </Grid>
            <Bar
              id="bar-user-chart"
              style={{ marginTop: "10px" }}
              data={dataChart}
              options={dataChart.options}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6} style={{ marginTop: "30px" }}>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              style={{ color: "#707070", margin: "20px 0px" }}
            >
              <b>Persentase pengunjung</b>
            </Typography>
            <Button
              onClick={() => onDownloadChart("pie-user-chart-1")}
              variant="contained"
              color="primary"
            >
              Download
            </Button>
            <div style={{ maxWidth: "300px", margin: "auto" }}>
              <Pie id="pie-user-chart-1" data={dataPie} />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              style={{ color: "#707070", margin: "20px 0px" }}
            >
              <b>Persentase pengunjung</b>
            </Typography>
            <Button
              onClick={() => onDownloadChart("bar-user-chart-2")}
              variant="contained"
              color="primary"
            >
              Download
            </Button>
            <div style={{ maxWidth: "300px", margin: "auto" }}>
              <Pie id="pie-user-chart-2" data={dataPie} />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h6"
              style={{ color: "#707070", margin: "20px 0px" }}
            >
              <b>Persentase pengunjung</b>
            </Typography>
            <Button
              onClick={() => onDownloadChart("bar-user-chart-3")}
              variant="contained"
              color="primary"
            >
              Download
            </Button>
            <div style={{ maxWidth: "300px", margin: "auto" }}>
              <Pie id="pie-user-chart-3" data={dataPie} />
            </div>
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          style={{ color: "#707070", margin: "20px 0px", marginTop: "50px" }}
        >
          <b>100 Pengguna terbaru</b>
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} r aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Dibuat</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.users?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data.users
              )?.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {moment(user.created_at, "YYYY-MM-DD HH:mm:ss").format(
                      "HH:mm:ss - dddd, DD-MM-YYYY"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.users?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

const panelInfo = {
  title: "Dashboard",
};

export default compose(withPanelInfo(panelInfo))(Dashboard);
