import { FETCH_USER_DATA, FETCH_USER_DATA_SUCCESS, FETCH_USER_DATA_FAILURE, FETCH_USER_DETAIL, FETCH_USER_DETAIL_SUCCESS, FETCH_USER_DETAIL_FAILURE, FETCH_USER_EDIT, FETCH_USER_EDIT_SUCCESS, FETCH_USER_EDIT_FAILURE, FETCH_USER_DELETE, FETCH_USER_DELETE_SUCCESS,FETCH_USER_DELETE_FAILURE } from "./userDataType"

const initialState = {
    loading: true,
    fetchDetailLoading: true,
    user: {},
    users: [],
    meta: {},
    error: {},
    fetchUserEditLoading: false,
    userEdit: {},
    userEditError: {},
    fetchDeleteLoading: false,
    userDelete: {},
    userDeleteError: {}
}

const reducer = (state = initialState, action) => {
    switch( action.type ) {
        case FETCH_USER_DATA:
            return {
                ...state,
                loading: true,
            }
        case FETCH_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users,
                meta: action.payload.meta,
                error: {}
            }
        case FETCH_USER_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case FETCH_USER_DETAIL:
            return {
                ...state,
                fetchDetailLoading: true
            }
        case FETCH_USER_DETAIL_SUCCESS:
            return {
                ...state,
                fetchDetailLoading: false,
                user: action.payload
            }
        case FETCH_USER_DETAIL_FAILURE:
            return {
                ...state,
                fetchDetailLoading: false,
                error: action.payload
            }
        case FETCH_USER_EDIT:
            return {
                ...state,
                fetchUserEditLoading: true
            }
        case FETCH_USER_EDIT_SUCCESS:
            return {
                ...state,
                fetchUserEditLoading: false,
                editUser: action.payload
            }
        case FETCH_USER_EDIT_FAILURE:
            return {
                ...state,
                fetchUserEditLoading: false,
                editError: action.payload
            }
        case FETCH_USER_DELETE:
            return {
                ...state,
                fetchDeleteLoading: true
            }
        case FETCH_USER_DELETE_SUCCESS:
            return {
                ...state,
                userDelete: action.payload,
                fetchDeleteLoading: false
            }
        case FETCH_USER_DELETE_FAILURE:
            return {
                ...state,
                userDeleteError: action.payload,
                fetchDeleteLoading: false
            }
        default: return state
    }
}

export default reducer;