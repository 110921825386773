export const FETCH_GATES = 'FETCH_GATES';
export const FETCH_GATES_SUCCESS = 'FETCH_GATES_SUCCESS';
export const FETCH_GATES_FAILURE = 'FETCH_GATES_FAILURE';

export const STORE_GATE = 'STORE_GATE';
export const STORE_GATE_SUCCESS = 'STORE_GATE_SUCCESS';
export const STORE_GATE_FAILURE = 'STORE_GATE_FAILURE';

export const UNSET_ERROR = 'UNSET_ERROR';

export const UPDATE_GATE = 'UPDATE_GATE';
export const UPDATE_GATE_SUCCESS = 'UPDATE_GATE_SUCCESS';
export const UPDATE_GATE_FAILURE = 'UPDATE_GATE_FAILURE';