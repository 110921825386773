import { FETCH_GATES, FETCH_GATES_SUCCESS, FETCH_GATES_FAILURE, STORE_GATE, STORE_GATE_SUCCESS, STORE_GATE_FAILURE, UNSET_ERROR, UPDATE_GATE, UPDATE_GATE_SUCCESS, UPDATE_GATE_FAILURE } from "./gateType"
import { GetGatesData, StoreGateData, UpdateGateData } from "../../services"
import { setPageLoading } from "../pageLoading/pageLoadingAction"


const fetchGatesAction = () => {
    return {
        type: FETCH_GATES
    }
}

const fetchGatesSuccessAction = (gates) => {
    return {
        type: FETCH_GATES_SUCCESS,
        payload: gates
    }
}

const fetchGatesFailureAction = (error) => {
    return {
        type: FETCH_GATES_FAILURE,
        payload: error
    }
}

export const fetchGates = (params) => {
    return dispatch => {
        dispatch(fetchGatesAction());
        dispatch(setPageLoading(true));
        GetGatesData(params).then( res => {
            const gates = res;
            return dispatch(fetchGatesSuccessAction(gates));
        }, err => {
            const error = err.response.data;
            return dispatch(fetchGatesFailureAction(error));
        }).finally( () => {
            dispatch(setPageLoading(false));
        });
    }
}


const storeGateAction = () => {
    return {
        type: STORE_GATE
    }
}

const storeGateSuccessAction = (gate) => {
    return {
        type: STORE_GATE_SUCCESS,
        payload: gate
    }
}

const storeGateFailureAction = (error) => {
    return {
        type: STORE_GATE_FAILURE,
        payload: error
    }
}

const unsetErrorAction = () => {
    return {
        type: UNSET_ERROR
    }
}

export const storeGate = (params) => {
    return dispatch => {
        dispatch(storeGateAction());
        const gate = new Promise( (resolve, reject) => {
            StoreGateData(params).then(res => {
                dispatch(storeGateSuccessAction(res));
                dispatch(unsetErrorAction());
                resolve(res);
            }, err => {
                dispatch(storeGateFailureAction(err.response.data));
                reject(err);
            });
        });
        return gate;
    }
}

export const unsetError = () => {
    return dispatch => {
        dispatch(unsetErrorAction());
    }
}

const updateGateAction = () => {
    return {
        type: UPDATE_GATE
    }
}

const updateGateSuccessAction = (gate) => {
    return {
        type: UPDATE_GATE_SUCCESS,
        payload: gate
    }
}

const updateGateFailureAction = (error) => {
    return {
        type: UPDATE_GATE_FAILURE,
        payload: error
    }
}

export const updateGate = (params) => {
    return dispatch => {
        dispatch(updateGateAction());
        const gate = new Promise( (resolve, reject) => {
            UpdateGateData(params).then(res => {
                dispatch(updateGateSuccessAction(res));
                resolve(res);
            }, err => {
                dispatch(updateGateFailureAction(err.response.data))
                reject(err.response.data);
            });
        });
        return gate;
    }
}