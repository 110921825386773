import React, { Component, createRef } from 'react';
import { GetHotelsData, DeleteHotelsData } from '../../../../config/services';
import { Grid, Button, withStyles, CircularProgress, Card, CardHeader, CardContent, CardMedia, List, CardActions, Divider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import FormSearchPanel from '../../../components/Forms/FormSearchPanel';
import { compose } from 'redux';
import withPanelInfo from '../../../../utils/PanelInfo';
import theme from '../../../../config/theme';
import ListItemCard from '../../../components/Panel/ListItemCard';
import DialogTitle from '../../../components/Dialog/DialogTitle';
import FormContent from './FormContent';
import Confirm from '../../../components/Confirm/Confirm';
import Pagination from '../../../components/Pagination';


class index extends Component {

    submitButtonRef = createRef();

    state = {
        openFormDialog: false,
        openConfirmDelete: false,
        datas: [],
        meta: {},
        search: '',
        fetchLoading: false,
        currentEdit: {},
        deleteLoading: false,
        currentDelete: null
    }

    componentDidMount()
    {
        this.fetchData({});
    }

    fetchData = (params = {}) => {
        this.setState({
            fetchLoading: true
        })
        GetHotelsData(params).then( res => {
            this.setState({
                datas: res.data,
                meta: res.meta
            });
        }, err => {
            console.log(err.response);
        }).finally( () => {
            this.setState({
                fetchLoading: false
            })
        })
    }

    Item = (props) => {
        const { id, name, image, lat, lng, address } = props.data;
        return(
            <Card>
                <CardMedia
                    className={this.props.classes.media}
                    image={ image || global.no_image }
                />
                <CardContent>
                    <List>
                        <ListItemCard text="Nama Hotel" value={name} />
                        <ListItemCard text="Alamat" value={address} />
                        <ListItemCard text="Latitude" value={lat} />
                        <ListItemCard text="Longitude" value={lng} />
                    </List>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button 
                        size="small" 
                        variant="outlined" 
                        color="primary"
                        onClick={ () => {
                            this.setState({
                                openFormDialog: true,
                                currentEdit: props.data
                            })
                        } }
                    >
                        Edit
                    </Button>
                    <Button 
                        size="small" 
                        variant="outlined" 
                        color="secondary"
                        disabled={this.state.deleteLoading}
                        onClick={ () => {
                            this.setState({
                                openConfirmDelete: true,
                                currentDelete: id
                            });
                        }}
                    >
                        { this.state.deleteLoading ?
                            <CircularProgress size="20" />
                            :
                            'Delete'
                        }
                    </Button>
                </CardActions>
            </Card>
        )
    }

    onSubmitSearch = (event) => {
        if(event.nativeEvent.key ==='Enter') {
            this.fetchData({q: this.state.search});
        }
    }

    handleDelete = (res, id) => {
        if(res) {
            this.setState({
                deleteLoading: true
            })
            DeleteHotelsData(this.state.currentDelete).then(res => {
                this.fetchData();
            }, err => {
    
            }).finally(()=>{
                this.setState({
                    deleteLoading: false,
                    openConfirmDelete: false,
                    currentDelete: null
                });
            });
        }
        else {
            this.setState({
                openConfirmDelete: false
            });
        }
    }

    formDialog = () => {
        const { currentEdit } = this.state;
        const isEdit = Object.keys(currentEdit).length ? true : false;
        return(
            <Dialog
                open={this.state.openFormDialog}
                fullWidth={true}
                maxWidth = {'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    onClose={true}
                    onHandleClose={ () => {
                        this.setState({
                            openFormDialog: false,
                            currentEdit: {}
                        })
                    }}
                >
                    { isEdit? 'Edit Sliders' : 'Tambah Hotels' } 
                </DialogTitle>
                <DialogContent>
                    <FormContent 
                        {...this.state.currentEdit}
                        isEdit={isEdit}
                        submitRef={this.submitButtonRef}
                        onLoading={ (loading) => {
                            this.setState({
                                submitLoading: loading
                            })
                        }}
                        onSubmitSuccess={ () => {
                            this.fetchData();
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={ () => {
                            this.submitButtonRef.current.click()
                        }}
                        disabled={this.state.submitLoading}
                    >
                        { this.state.submitLoading ?
                            <CircularProgress size={25} />
                            :
                            isEdit ? 'Update' : 'Simpan'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    handleChangePage = (event, value) => {
        this.fetchData({
            q: this.state.search,
            page: value
        })
    }

    handleChangeLimit = (event) => {
        this.fetchData({
            q: this.state.search,
            limit: event.target.value
        })
    }

    render() {
        const { classes } = this.props;
        const { search, fetchLoading, datas } = this.state;
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item className={classes.grow}>

                    </Grid>
                    <Grid item>
                        <FormSearchPanel
                            value={search}
                            onChange={(event) => this.setState({search: event.target.value}, () => {
                                if(this.state.search === '') {
                                    this.fetchData()
                                }
                            })}
                            onKeyPress={ this.onSubmitSearch }
                            clearSearchInput={ () => {
                                this.setState({search: ''}, () => this.fetchData())
                            } }
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={ () => this.setState({openFormDialog: true})}
                        >
                            Tambah Hotels
                        </Button>
                    </Grid>
                </Grid>
                { fetchLoading ?
                    <div
                        style={{
                            textAlign: 'center',
                            minHight: 250,
                            margin: '2rem 0',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress size={28} />
                    </div>
                    :
                    <div className={classes.root}>
                        <Grid container spacing={2}>
                            { datas.map( data => (
                                <Grid item xs={12} md={4} >
                                    <this.Item data={data} />
                                </Grid>
                            ))}
                        </Grid>
                        <Pagination
                            meta={this.state.meta}
                            onChangePage={this.handleChangePage}
                            onChangeLimit={this.handleChangeLimit}
                        />
                    </div>
                }
                { this.state.openFormDialog &&
                    <this.formDialog />
                }
                { this.state.openConfirmDelete &&
                    <Confirm
                        loading={this.state.deleteLoading} 
                        open={this.state.openConfirmDelete} 
                        content="Anda yakin ingin menghapus data ?" 
                        onAction={ (res) => this.handleDelete(res) } 
                    />
                }
            </div>
        );
    }
}

const useStyles = () => ({
    cardContent: {
        maxHeight: 340,
        overflow: 'scroll'
    },
    cctvs: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    itemCctv: {
        marginRight: 10,
        marginBottom: 10,
        '&:last-child': {
            marginRight: 0
        }
    },
    pagination: {
        margin: '2rem 0'
    },
    facilities: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    iconFacility: {
        '&:last-child': {
            marginRight: 0
        },
        width: 25,
        height: 25,
        marginRight: 8,
        marginBottom: 8,
        backgroundSize: 'contain !important',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center !important'
    },
    cardRoot: {
        minHeight: 300,
        overflow: 'scroll'
    },
    cardTitleRoot: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    cardSubTitleRoot: {
        fontSize: '.9rem',
        color: theme.palette.secondary.main,
        marginTop: 10
    },
    grow: {
        flex: 1
    },
    media: {
        height: 170,
        width: '100%',
        borderTop: '1px solid ' + theme.palette.primary.main,
        borderBottom: '2px solid red'
    },
    root: {
        margin: '2rem 0'
    }
});

const panelInfo = {
    title: 'Manage Hotels'
}

export default compose(
    withPanelInfo(panelInfo),
    withStyles(useStyles()),
)(index);