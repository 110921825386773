export const FETCH_STREAM = 'FETCH_STREAM';
export const FETCH_STREAM_SUCCESS = 'FETCH_STREAM_SUCCESS';
export const FETCH_STREAM_FAILURE = 'FETCH_STREAM_FAILURE';

export const STORE_STREAM = 'STORE_STREAM';
export const STORE_STREAM_SUCCESS = 'STORE_STREAM_SUCCESS';
export const STORE_STREAM_FAILURE = 'STORE_STREAM_FAILURE';

export const UPDATE_STREAM = 'UPDATE_STREAM';
export const UPDATE_STREAM_SUCCESS = 'UPDATE_STREAM_SUCCESS';
export const UPDATE_STREAM_FAILURE = 'UPDATE_STREAM_FAILURE';
