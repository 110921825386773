import React from 'react';
import { Snackbar, SnackbarContent, makeStyles, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { green, amber } from '@material-ui/core/colors';
import theme from '../../../config/theme';
import PropTypes from 'prop-types';


const stylesContentWrapper = makeStyles({
    success: {
        backgroundColor: green[400],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

const SnackbarContentWrapper = (props) => {
    const { message, variant, onClose } = props;
    const classes = stylesContentWrapper();
    const Icon = variantIcon[variant];

    return(
        <SnackbarContent 
            className={clsx(classes[variant])}
            aria-describedby="snackbar-content"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    { message }
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
        />
    )
}

SnackbarContentWrapper.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}


const MySnackbar = (props) => {

    const { open, onClose, variant, message } = props
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            autoHideDuration={6000}
            open={open}
            onClose={onClose}
        >
            <SnackbarContentWrapper 
                onClose={onClose}
                variant={variant}
                message={message}
            />
        </Snackbar>
    );

};

export default MySnackbar;