import React from 'react';
import { makeStyles, Typography, Icon, InputBase } from '@material-ui/core';
import theme from '../../../config/theme';

const styles = makeStyles( () => ({
    imgBody: {
        // width: 280,
        margin: '0 auto',
        border: '1px solid #ddd',
    },
    imgTitle: {
        background: theme.palette.primary.main,
        color: '#fff',
        padding: '5px 10px',
    },
    containerImg: {
        textAlign: 'center',
        '& .img': {
            // maxWidth: '280px',
            margin: '0 auto',
            position: 'relative',
            cursor: 'pointer',
            '& img': {
                width: '100%',
                // maxHeight: '185px',
                overflow: 'hidden'
            },
            '& .onHover': {
                position: 'absolute',
                display: 'none',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, .5)'
            },
            '&:hover': {
                '& .onHover': {
                    display: 'flex',
                    justifyContent: 'center',
                    '& p': {
                        color: 'white',
                        alignSelf: 'center'
                    }
                },
            }
        },
    }
}));

const SelectImage = (props) => {
    const classes = styles();
    const {label, src, onFileChange, name} = props;
    const id = 'file_' + name;

    const onSelect = () => {
        document.getElementById(id).click()
    }

    return (
        <div className={classes.imgBody}>
            <Typography variant="body1" className={classes.imgTitle}>{label || 'Image'}</Typography>
            <div className={classes.containerImg}>
                <div className="img">
                    <img
                        src={src}
                    />
                    <div 
                        className="onHover"
                        onClick={onSelect}
                    >
                        <p>            
                            <Icon>
                                camera
                            </Icon><br/>
                            Pilih Photo
                        </p>
                    </div>
                </div>
                <InputBase
                    type="file"
                    name={name}
                    onChange={onFileChange}
                    id={id}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    );
};

export default SelectImage;