import React, { Component } from 'react';
import { CssBaseline, withStyles, ThemeProvider } from '@material-ui/core';
import { compose } from 'redux';
import MyAppBar from '../../components/Panel/AppBar';
import MyNav from '../../components/Panel/MyNav';
import MenuLists from '../../components/Panel/MenuLists';
import { fetchPanelMenuData } from '../../../config/redux/panelMenu/panelMenuAction';
import { connect } from 'react-redux';
import PageLoading from '../../components/PageLoading';
import theme from '../../../config/theme';

class Dashboard extends Component {

    componentDidMount() {
        this.props.getPanelMenus();
    }

    render() {
        const {classes} = this.props;
        return (
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline/>
                    <PageLoading />
                    <MyAppBar />
                    <MyNav>
                        <MenuLists 
                            lists={this.props.panelMenus}
                            loading={this.props.panelMenuLoading}
                        />
                    </MyNav>
                    <main className={classes.content}>
                        <div className={classes.toolbar}/>
                        { this.props.children }
                    </main>
                </div>
            </ThemeProvider>
        );
    }
}

const styles = theme => ({
    root: {
        display: "flex"
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflowX: 'hidden'
    },
});

const mapStateToProps = state => {
    return {
        panelMenuLoading: state.panelMenu.loading,
        panelMenus: state.panelMenu.menus,
        panelMenuError: state.panelMenu.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPanelMenus: () => dispatch(fetchPanelMenuData())
    }
}

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Dashboard);