import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
	withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchGates } from '../../../../config/redux';
import withPanelInfo from '../../../../utils/PanelInfo';
// import PageLoading from '../../../components/PageLoading';
import '../../../../config/app';
import { dateDef } from '../../../../config/datetime';
import { DeleteGateData } from '../../../../config/services';
import theme from '../../../../config/theme';
import Confirm from '../../../components/Confirm/Confirm';
import FormSearchPanel from '../../../components/Forms/FormSearchPanel';
import Pagination from '../../../components/Pagination';
import Create from './Create';

class index extends Component {
	state = {
		search: '',
		openDialogCreate: false,
		openConfirmDelete: false,
		deleteLoading: false,
		userDelete: null,
		userEdit: null,
		openDialogEdit: false,
	};

	componentDidMount() {
		this.props.fetchData({});
	}

	ItemText = ({ text, value }) => (
		<ListItem className={this.props.classes.listRoot}>
			<ListItemText
				primary={text}
				className={this.props.classes.itemText}
				primaryTypographyProps={{
					className: 'text',
					component: 'div',
				}}
				secondary={
					<Typography component="div" className="value">
						{value}
					</Typography>
				}
			/>
		</ListItem>
	);

	handleDelete = res => {
		if (res) {
			DeleteGateData(this.state.userDelete)
				.then(
					res => {
						this.props.fetchData();
					},
					err => {}
				)
				.finally(() => {
					this.closeDeleteConfirm();
				});
		} else {
			this.closeDeleteConfirm();
		}
	};

	closeDeleteConfirm = () => {
		this.setState({
			openConfirmDelete: false,
			userDelete: null,
		});
	};

	handleOpenDialogEdit = props => {
		console.log(props);
		this.setState({
			openDialogEdit: true,
			userEdit: props,
		});
	};

	Item = props => {
		const { image, name, id, created_at, updated_at, segment, latitude, longitude } = props;
		const featured_image = image ? global.api_url + 'storage/img/gates/' + image : global.no_image;
		return (
			<Card>
				<CardHeader
					title={name}
					titleTypographyProps={{
						classes: {
							root: this.props.classes.cardTitleRoot,
						},
					}}
					subheaderTypographyProps={{
						classes: {
							root: this.props.classes.cardSubTitleRoot,
						},
					}}
					subheader={`Ruas : ${segment}`}
				/>
				<CardMedia className={this.props.classes.media} image={featured_image} />
				<CardContent className={this.props.classes.cardRoot}>
					<List>
						<this.ItemText text="Latitude" value={latitude} />
						<Divider component="li" />
						<this.ItemText text="Longitude" value={longitude} />
						<Divider component="li" />
						<this.ItemText text="Dibuat" value={dateDef(created_at)} />
						<Divider component="li" />
						<this.ItemText text="Diupdate" value={dateDef(updated_at)} />
					</List>
				</CardContent>
				<CardActions>
					<Button size="small" variant="outlined" color="primary" onClick={() => this.handleOpenDialogEdit(props)}>
						Edit
					</Button>
					<Button
						size="small"
						variant="outlined"
						color="secondary"
						onClick={() => {
							this.setState({
								openConfirmDelete: true,
								userDelete: id,
							});
						}}
					>
						Delete
					</Button>
				</CardActions>
			</Card>
		);
	};

	onSubmitSearch = event => {
		if (event.nativeEvent.key === 'Enter') {
			this.props.fetchData({ q: this.state.search });
		}
	};

	handleChangePage = (event, value) => {
		this.props.fetchData({
			q: this.state.search,
			page: value,
		});
	};

	handleChangeLimit = event => {
		this.props.fetchData({
			q: this.state.search,
			limit: event.target.value,
		});
	};

	render() {
		const { classes, loading, gates } = this.props;
		const { search } = this.state;
		return (
			<div>
				<Grid container spacing={2}>
					<Grid item className={classes.grow}></Grid>
					<Grid item>
						<FormSearchPanel
							value={search}
							onChange={event =>
								this.setState({ search: event.target.value }, () => {
									if (this.state.search === '') {
										this.props.fetchData();
									}
								})
							}
							onKeyPress={this.onSubmitSearch}
							clearSearchInput={() => {
								this.setState({ search: '' }, () => this.props.fetchData());
							}}
						/>
					</Grid>
					<Grid item>
						<Button variant="outlined" onClick={() => this.setState({ openDialogCreate: true })}>
							Tambah Gerbang Tol
						</Button>
					</Grid>
				</Grid>
				{loading ? (
					<div
						style={{
							textAlign: 'center',
							minHight: 250,
							margin: '2rem 0',
							justifyContent: 'center',
						}}
					>
						<CircularProgress size={38} />
					</div>
				) : (
					<div className={classes.root}>
						<Grid container spacing={2}>
							{gates.data.map(gate => {
								return (
									<Grid xs={12} md={4} item key={gate.id}>
										<this.Item
											image={gate.image}
											latitude={gate.latitude}
											longitude={gate.longitude}
											segment={gate.segment?.name}
											segment_id={gate.segment?.id}
											id={gate.id}
											name={gate.name}
											created_at={gate.created_at}
										/>
									</Grid>
								);
							})}
						</Grid>
						<Pagination meta={gates.meta} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} />
					</div>
				)}
				{this.state.openDialogCreate && (
					<Create
						open={this.state.openDialogCreate}
						handleClose={() => this.setState({ openDialogCreate: false })}
						loading={this.props.loading}
					/>
				)}
				{this.state.openDialogEdit && (
					<Create
						open={this.state.openDialogEdit}
						userEdit={this.state.userEdit}
						handleClose={() =>
							this.setState({
								openDialogEdit: false,
								userEdit: null,
							})
						}
						loading={false}
					/>
				)}
				{this.state.openConfirmDelete && (
					<Confirm
						loading={this.state.deleteLoading}
						open={this.state.openConfirmDelete}
						content="Anda yakin ingin menghapus data ?"
						onAction={res => this.handleDelete(res)}
					/>
				)}
			</div>
		);
	}
}

const panelInfo = {
	title: 'Manage Gerbang Tol',
};

const useStyles = () => ({
	pagination: {
		margin: '2rem 0',
	},
	cardRoot: {
		minHeight: 255,
		overflow: 'scroll',
	},
	cardTitleRoot: {
		fontSize: '1rem',
		fontWeight: 'bold',
		color: theme.palette.primary.main,
	},
	cardSubTitleRoot: {
		fontSize: '.9rem',
		color: theme.palette.secondary.main,
		marginTop: 10,
	},
	grow: {
		flex: 1,
	},
	media: {
		height: 170,
		width: '100%',
		borderTop: '1px solid ' + theme.palette.primary.main,
		borderBottom: '2px solid red',
	},
	listRoot: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	itemText: {
		display: 'flex',
		fontSize: '.7rem !important',
		'& .text': {
			minWidth: 105,
			fontSize: '.9rem !important',
			fontWeight: 'bold',
		},
		'& .value': {
			flex: 1,
			fontSize: '.9rem !important',
			color: theme.palette.primary.main,
		},
	},
	root: {
		margin: '2rem 0',
	},
});

const mapStateTopProps = state => ({
	loading: state.gates.gatesLoading,
	error: state.gates.gatesError,
	gates: state.gates.gates,
});

const mapDispatchToProps = dispatch => ({
	fetchData: params => dispatch(fetchGates(params)),
});

export default compose(
	withPanelInfo(panelInfo),
	withStyles(useStyles()),
	connect(mapStateTopProps, mapDispatchToProps)
)(index);
