import { FETCH_SEGMENT_LIST, FETCH_SEGMENT_LIST_SUCCESS, FETCH_SEGMENT_LIST_FAILURE } from "./segmentTypes"
import { GetSegmentLists } from "../../services"

const fetchSegmentListAction = () => {
    return {
        type: FETCH_SEGMENT_LIST
    }
}

const fetchSegmentListSuccessAction = (segments) => {
    return {
        type: FETCH_SEGMENT_LIST_SUCCESS,
        payload: segments
    }
}

const fetchSegmentListFailureAction = (error) => {
    return {
        type: FETCH_SEGMENT_LIST_FAILURE,
        payload: error
    }
}

export const fetchSegmentLists = () => {
    return dispatch => {
        dispatch(fetchSegmentListAction());
        GetSegmentLists().then(res => {
            dispatch(fetchSegmentListSuccessAction(res));
        }, err => {
            dispatch(fetchSegmentListFailureAction(err));
        });
    }
}