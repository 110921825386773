import React, { Component } from 'react';
import {attempUserLogout} from '../../../../config/redux';
import { connect } from 'react-redux';

class index extends Component {

    componentDidMount() {
        this.props.attempUserLogout();
    }

    render() {
        return (
            <div>
                Logout user...
            </div>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.userLogout.loading,
    message: state.userLogout.message
});

const mapDispatchToProps = dispatch => ({
    attempUserLogout: () => dispatch(attempUserLogout())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(index);