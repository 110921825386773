import { FETCH_POSTS, FETCH_POSTS_SUCCESS, FETCH_POSTS_FAILURE, STORE_POSTS, STORE_POSTS_SUCCESS, STORE_POSTS_FAILURE, UPDATE_POSTS, UPDATE_POSTS_SUCCESS, UPDATE_POSTS_FAILURE } from "./postType";
import { GetPostsData, StorePostsData, UpdatePostsData } from '../../services'



const fetchPostsAction = () => {
    return {
        type: FETCH_POSTS
    }
}

const fetchPostsSuccessAction = (posts) => {
    return {
        type: FETCH_POSTS_SUCCESS,
        payload: posts
    }
}

const fetchPostsFailureAction = (error) => {
    return {
        type: FETCH_POSTS_FAILURE,
        payload: error
    }
}

export const fetchPosts = (params) => {
    return dispatch => {
        dispatch(fetchPostsAction());
        GetPostsData(params).then(res => {
            dispatch(fetchPostsSuccessAction(res));
        }, err => {
            dispatch(fetchPostsFailureAction(err.response.data));
        })
    }
}

// Store

const storePostsAction = () => {
    return {
        type: STORE_POSTS
    }
}

const storePostsSuccessAction = (posts) => {
    return {
        type: STORE_POSTS_SUCCESS,
        payload: posts
    }
}

const storePostsFailureAction = (error) => {
    return {
        type: STORE_POSTS_FAILURE,
        payload: error
    }
}

export const storePosts = (params) => {
    return dispatch => {
        dispatch(storePostsAction());
        const post = new Promise( (resolve, reject) => {
            StorePostsData(params).then(res => {
                dispatch(storePostsSuccessAction(res));
                resolve(res);
            }, err => {
                dispatch(storePostsFailureAction(err.response.data));
                reject(err.response.data);
            })
        });
        return post;
    }
}

// Update

const updatePostsAction = () => {
    return {
        type: UPDATE_POSTS
    }
}

const updatePostsSuccessAction = (posts) => {
    return {
        type: UPDATE_POSTS_SUCCESS,
        payload: posts
    }
}

const updatePostsFailureAction = (error) => {
    return {
        type: UPDATE_POSTS_FAILURE,
        payload: error
    }
}

export const updatePosts = (params) => {
    return dispatch => {
        dispatch(updatePostsAction());
        const post = new Promise( (resolve, reject) => {
            UpdatePostsData(params).then(res => {
                dispatch(updatePostsSuccessAction(res));
                resolve(res);
            }, err => {
                dispatch(updatePostsFailureAction(err.response.data));
                reject(err.response.data);
            })
        });
        return post;
    }
}