import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CircularProgress,
	Divider,
	List,
	makeStyles,
} from '@material-ui/core';
import React from 'react';
import theme from '../../../../config/theme';
import ListItemCard from '../../../components/Panel/ListItemCard';

const useStyles = makeStyles({
	media: {
		height: 170,
		width: '100%',
		borderTop: '1px solid ' + theme.palette.primary.main,
		borderBottom: '2px solid red',
	},
});

function Item({ slider, setOpenFormDialog, setCurrentEdit, setOpenConfirmDelete, setCurrentDelete, deleteLoading }) {
	const classes = useStyles();
	return (
		<Card>
			<CardMedia className={classes.media} image={slider.img} />
			<CardContent>
				<List>
					<ListItemCard text="Judul" value={slider.title} />
					<ListItemCard text="Widget Cuaca" value={slider.weather ? 'Ya' : 'Tidak'} />
				</List>
			</CardContent>
			<Divider />
			<CardActions>
				<Button
					size="small"
					variant="outlined"
					color="primary"
					onClick={() => {
						setOpenFormDialog(true);
						setCurrentEdit(slider);
					}}
				>
					Edit
				</Button>
				<Button
					size="small"
					variant="outlined"
					color="secondary"
					disabled={deleteLoading}
					onClick={() => {
						setOpenConfirmDelete(true);
						setCurrentDelete(slider.id);
					}}
				>
					{deleteLoading ? <CircularProgress size="20" /> : 'Delete'}
				</Button>
			</CardActions>
		</Card>
	);
}

export default Item;
