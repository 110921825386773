import {
    FETCH_RESET_PASSWORD,
    FETCH_RESET_PASSWORD_SUCCESS,
    FETCH_RESET_PASSWORD_FAILURE,
}  from './resetPasswordType';
import { ResetPassword } from '../../services';

const fetchResetPassword = () => {
    return {
        type: FETCH_RESET_PASSWORD
    }
}

const fetchResetPasswordSuccess = (email) => {
    return {
        type: FETCH_RESET_PASSWORD_SUCCESS,
        payload: email
    }
}

const fetchResetPasswordFailure = (error) => {
    return {
        type: FETCH_RESET_PASSWORD_FAILURE,
        payload: error
    }
}

export const tryResetPassword = (email) => {
    return dispatch => {
        dispatch(fetchResetPassword());
        const resetPassword = new Promise ( (resolve, rejcet) => {
            ResetPassword(email).then( res => {
                const currentUser = res.data;
                dispatch(fetchResetPasswordSuccess(currentUser));
                resolve(currentUser);
            }, err => {
                dispatch(fetchResetPasswordFailure(err.response.data));
                rejcet(err.response.data);
            })
        });
        return resetPassword;
    }
}