import React, { Component } from 'react';
import { Hidden, Drawer, withStyles } from '@material-ui/core';
import theme from '../../../../config/theme';
import { onDrawerMobileOpen } from '../../../../config/redux';
import { compose } from 'redux';
import { connect } from 'react-redux';



class MyNav extends Component {

    handleDrawerToggle = () => {
        this.props.onDrawerMobileOpen();
    }

    render() {
        const { classes } = this.props;
        return (
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={this.props.drawerOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        { this.props.children }
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        { this.props.children }
                    </Drawer>
                </Hidden>
            </nav>
        );
    }
}

const styles = () => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.custom.drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: theme.custom.drawerWidth,
    },
});

const mapStateToProps = state => ({
    drawerOpen: state.drawer.drawerOpen
});

const mapDispatchToProps = dispatch => ({
    onDrawerMobileOpen: () => dispatch(onDrawerMobileOpen())
});

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MyNav);