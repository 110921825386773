import React, { Fragment } from 'react';
import { ListItemText, makeStyles, Typography, ListItem, Divider } from '@material-ui/core';
import theme from '../../../config/theme';

const useStyles = makeStyles(() => ({
    listRoot: {
        paddingLeft: 0,
        paddingRight: 0
    },
    itemText: {
        display: 'flex',
        fontSize: '.7rem !important',
        '& .text': {
            minWidth: '35%',
            fontSize: '.9rem !important',
            fontWeight: "bold",
        },
        '& .value': {
            flex: 1,
            fontSize: '.9rem !important',
            color: theme.palette.primary.main
        },
    },
}));
const ListItemCard = (props) => {
    const classes = useStyles();
    const {text, value} = props;
    return (
        <Fragment>
            <ListItem
                className={classes.listRoot}
            >
                <ListItemText
                    primary={text}
                    className={classes.itemText}
                    primaryTypographyProps={{
                        className: 'text',
                        component: 'div'
                    }}
                    secondary={
                        <Typography 
                            component="div"
                            className='value'
                        >{value}</Typography>
                    }
                />
            </ListItem>
            <Divider />
        </Fragment>
    );
};

export default ListItemCard;