import {
    PAGE_LOADING
} from './pageLoadingType';

const initialState = {
    loading: false
}

const reducer = ( state = initialState, action ) => {
    switch( action.type ) {
        case PAGE_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default: return state;
    }
}

export default reducer;