import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { utils, writeFileXLSX } from "xlsx";
import { dateDef } from "../../../../config/datetime";
import { setPageLoading, setPanelTitle } from "../../../../config/redux";
import { DeleteSegmentData, GetSegments } from "../../../../config/services";
import Confirm from "../../../components/Confirm/Confirm";
import FormSearchPanel from "../../../components/Forms/FormSearchPanel";
import TableData from "../../../components/Panel/Table";
import Create from "./Create";
import Edit from "./Edit";
import View from "./View";

const useStyles = makeStyles((theme) => ({
  grow: {
    flex: 1,
  },
  cardRoot: {
    marginTop: 25,
    marginBottom: 25,
  },
  ...theme.styles.panelCard,
}));

const columns = [
  {
    id: "id",
    label: "ID",
    minWidth: 170,
  },
  {
    id: "comp",
    label: "Kode Perusahaan",
    minWidth: 170,
  },
  {
    id: "name",
    label: "Nama Ruas",
    minWidth: 225,
  },
  {
    id: "created_at",
    label: "Dibuat",
    minWidth: 200,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
  },
];

const moreItems = ["Edit", "View", "Delete"];

function RuasToll() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOpen, setMoreOpen] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [data, setData] = useState({});
  const [segmentDelete, setSegmentDelete] = useState(null);
  const [segmentDeleteLoading, setSegmentDeleteLoading] = useState(false);
  const [editSegment, setEditSegment] = useState({});
  const [viewSegment, setViewSegment] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const fetchRuasToll = async (params) => {
    setLoading(true);
    try {
      const response = await GetSegments(params);
      setData(response.data.segments);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (type) => {
    if (type) {
      setSegmentDeleteLoading(true);
      try {
        const response = await DeleteSegmentData(segmentDelete);
        fetchRuasToll();
        setSegmentDelete(null);
        setOpenConfirmDelete(false);
      } catch (error) {
        console.log(error);
      } finally {
        setSegmentDeleteLoading(false);
      }
    } else {
      setOpenConfirmDelete(false);
    }
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSubmitSearch = (event) => {
    if (event.nativeEvent.key === "Enter") {
      fetchRuasToll({ q: search });
    }
  };

  const clearSearchInput = () => {
    if (search) {
      setSearch("");
      fetchRuasToll();
    }
  };

  const dialogCreateOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleChangePage = (page) => {
    fetchRuasToll({ page: page, limit: data.meta.per_page });
  };

  const handleChangeRowsPerPage = (limit) => {
    fetchRuasToll({ limit: limit });
  };

  const handleMoreOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMoreOpen(id);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setMoreOpen(false);
  };

  const clickMoreItem = (item, segment) => {
    if (item === "Edit") {
      setEditSegment(segment);
    } else if (item === "Delete") {
      setOpenConfirmDelete(true);
      setSegmentDelete(segment.id);
    } else {
      setViewSegment(segment);
    }
    handleCloseMore();
  };

  const handleCloseView = () => {
    setViewSegment({});
  };

  useEffect(() => {
    fetchRuasToll();
    dispatch(setPanelTitle("Ruas Toll"));
  }, [dispatch]);

  const handleExport = async () => {
    try {
      setLoadingExport(true);
      dispatch(setPageLoading(true));

      const response = await GetSegments({
        paginate: false,
      });
      const result = response.data.segments.map((segment) => ({
        ID: segment.id,
        Nama: segment.name,
        Perusahaan: segment.comp,
        Dibuat: moment(segment.created_at).format(
          "HH:mm:ss - dddd, DD-MM-YYYY"
        ),
      }));
      const ws = utils.json_to_sheet(result);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Data-Ruas-Toll.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setPageLoading(false));
      setLoadingExport(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Ruas Toll</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item className={classes.grow}></Grid>
        <Grid item>
          <FormSearchPanel
            value={search}
            onChange={handleChangeSearch}
            onKeyPress={onSubmitSearch}
            clearSearchInput={clearSearchInput}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="default"
            onClick={dialogCreateOpen}
          >
            Buat Ruas Toll
          </Button>
        </Grid>
      </Grid>
      <Card className={classes.cardRoot}>
        <CardHeader
          title="Data Ruas Toll"
          titleTypographyProps={{
            className: classes.cardTitle,
          }}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleExport}
              disabled={loadingExport}
            >
              Export Data
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <TableData
            columns={columns}
            meta={{ ...data.meta, ...data.links }}
            loading={loading}
            onHandleChangePage={(page) => handleChangePage(page)}
            onHandleChangeRowsPerPage={(limit) =>
              handleChangeRowsPerPage(limit)
            }
          >
            {data.data?.map((segment) => (
              <TableRow key={segment.id} hover>
                <TableCell>{segment.id}</TableCell>
                <TableCell>{segment.comp}</TableCell>
                <TableCell>{segment.name}</TableCell>
                <TableCell>{dateDef(segment.created_at)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={(event) => handleMoreOpen(event, segment.id)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={moreOpen === segment.id ? true : false}
                    onClose={handleCloseMore}
                  >
                    {moreItems.map((item) => (
                      <MenuItem
                        key={item}
                        onClick={() => clickMoreItem(item, segment)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableData>
        </CardContent>
      </Card>
      {createDialogOpen && (
        <Create
          fetchRuasToll={fetchRuasToll}
          createDialogOpen={createDialogOpen}
          setCreateDialogOpen={setCreateDialogOpen}
        />
      )}
      {!isEmpty(editSegment) && (
        <Edit
          fetchRuasToll={fetchRuasToll}
          open={!isEmpty(editSegment)}
          segment={editSegment}
          setEditSegment={setEditSegment}
        />
      )}

      {!isEmpty(viewSegment) && (
        <View
          open={!isEmpty(viewSegment)}
          viewSegment={viewSegment}
          setViewSegment={setViewSegment}
        />
      )}
      {openConfirmDelete && (
        <Confirm
          loading={segmentDeleteLoading}
          open={openConfirmDelete}
          content="Anda yakin ingin menghapus data ?"
          onAction={(type) => handleDelete(type)}
        />
      )}
    </div>
  );
}

export default RuasToll;
