import React, { Component, Fragment } from 'react';
import { checkAuthorization } from '../../config/redux';
import {Typography} from '@material-ui/core';
import { connect } from 'react-redux';
import history from '../../config/history';

const WithAuthorization = (WrappedComponent) => {
    class Authorization extends Component {

        componentDidMount() {
            this.props.checkAuthorization().then( res => {
                // nothing todo
            }, err => {
                console.log('result code error', err)
                if( err === 401) {
                    history.push('/logout');
                }
            });
        }

        Forbidden = () => {
            return (
                <Typography variant="body2">
                    Anda tidak berhak mengakses halaman ini
                </Typography>
            )
        }

        render() {
            return (
                <Fragment>
                    { 
                        this.props.loading ? '' : 
                            this.props.status === 403 ? 
                            <this.Forbidden /> :
                            <WrappedComponent  />
                    }
                </Fragment>
            );
        }
    }

    const mapDispatchToProps = dispatch => ({
        checkAuthorization: () => dispatch(checkAuthorization())
    });
    
    const mapStateToProps = state => ({
        loading: state.userAuthorization.loading,
        status: state.userAuthorization.status,
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(Authorization)
}

export default WithAuthorization;